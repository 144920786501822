import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { LicenseManager } from '@ag-grid-enterprise/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import '@mantine/core/styles/global.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import './App.theme.css';
import './App.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import './data/atom.subscriptions';
import { router, dtiOpuRouter, useAppRouter } from './router';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import LogoLoader from './components/Loading/LogoLoader';
import { useGlobalState } from './data/GlobalState';

ModuleRegistry.registerModules([ExcelExportModule]);

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-057849}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Thirtytech_Inc}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_June_2025}____[v3]_[01]_MTc0OTI1MDgwMDAwMA==73ba741f47e5fe068d14defd31f34af3',
);

export function App() {
  const { isDtiOpu } = useGlobalState('isDtiOpu');
  const newRouterFlag = useFlag('new-router');
  const routes = useAppRouter();
  const { flagsReady, flagsError } = useFlagsStatus();
  if (!flagsError && !flagsReady) {
    return <LogoLoader />;
  }

  return !newRouterFlag ? <BrowserRouter>{routes}</BrowserRouter> : <RouterProvider router={isDtiOpu ? dtiOpuRouter : router} />;
}
