import { Box, Divider, Space, Text } from '@mantine/core';
import { BNButton } from '../components/Button/Button';
import { AdminContentHeader } from '../hoc/Admin/AdminContentHeader';
import { BNTextInput } from '../components/TextInput/TextInput';
import { useParams } from 'react-router-dom';
import { useGlobalState } from '../data/GlobalState';
import { useForm } from '@mantine/form';
import {
  SystemTextJsonPatchOperationsOperation1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull,
  usePatchApiTenantsTenantId,
} from '../api';
import { useState } from 'react';
import { supportEmail } from '../utils/whitelabel-consts';

export default function CompanyProfile() {
  // TODO: Eventually we should have a "useAdmin" hook that contains all of the logic and state for managing a single tenant in these administrative pages such as settings
  const { tenantId } = useParams();
  const { tenants } = useGlobalState('tenants');
  const [selectedTenant, setSelectedTenant] = useState(tenants?.find((tenant) => tenant.tenantId === tenantId));
  const form = useForm<{ contactEmail: string; billingEmail: string }>({
    initialValues: {
      contactEmail: selectedTenant?.contactEmail || '',
      billingEmail: selectedTenant?.billingEmail || '',
    },
    validate: {
      contactEmail: (value) => {
        if (!value) {
          return 'Contact email is required';
        }
        if (!value.includes('@')) {
          return 'Contact email must be a valid email address';
        }
        return null;
      },
      billingEmail: (value) => {
        if (!value) {
          return 'Billing email is required';
        }
        if (!value.includes('@')) {
          return 'Billing email must be a valid email address';
        }
        return null;
      },
    },
  });
  const { mutateAsync: updateTenant, isPending: isLoading } = usePatchApiTenantsTenantId();

  if (!selectedTenant) {
    return null;
  }

  return (
    <>
      <form
        onReset={() => form.setValues({ contactEmail: selectedTenant.contactEmail, billingEmail: selectedTenant.billingEmail })}
        onSubmit={form.onSubmit((values) => {
          const ops = [] as SystemTextJsonPatchOperationsOperation1BarkerCoreModelsAdministrativeTenantBarkerCoreVersion1000CultureNeutralPublicKeyTokenNull[];
          if (form.isDirty('contactEmail')) {
            ops.push({ op: 'Replace', path: '/contactEmail', value: values.contactEmail });
          }
          if (form.isDirty('billingEmail')) {
            ops.push({ op: 'Replace', path: '/billingEmail', value: values.billingEmail });
          }

          updateTenant(
            {
              tenantId: selectedTenant?.tenantId || '',
              data: ops,
            },
            {
              onSuccess: (data) => {
                selectedTenant.billingEmail = data.data.billingEmail;
                selectedTenant.contactEmail = data.data.contactEmail;
                setSelectedTenant({ ...selectedTenant });
              },
            },
          ).then((data) => {
            form.resetDirty({
              contactEmail: data.data.contactEmail,
              billingEmail: data.data.billingEmail,
            });
          });
        })}
      >
        <AdminContentHeader
          title="Profile"
          sticky
          rightSection={
            <>
              <BNButton size="xs" variant="default" disabled={!form.isDirty()} loading={isLoading} type="reset">
                Cancel
              </BNButton>
              <BNButton size="xs" variant="filled" color="green" disabled={!form.isDirty()} loading={isLoading} type="submit">
                Save
              </BNButton>
            </>
          }
        />
        <Box pt="xl" pb="lg">
          <BNTextInput size="xs" maw={280} label="Company Name" defaultValue={selectedTenant.name} disabled />
          <Text size="xs" c="var(--colors-gray-5)" maw={280} mt="xs">
            Company name can only be modified by contacting{' '}
            <Text component="a" href={`mailto:${supportEmail}`} td="underline" mr={4} c="var(--colors-brandcolor-5)">
              {supportEmail}
            </Text>
          </Text>
          <Space h="md" />
          <Space h="md" />
          <Divider color="var(--colors-gray-1)" variant="dotted" />
          <Space h="xl" />
          <BNTextInput size="xs" maw={280} label="Contact Email" {...form.getInputProps('contactEmail')} />
          <Space h="md" />
          <BNTextInput size="xs" maw={280} label="Billing Email" {...form.getInputProps('billingEmail')} />
        </Box>
      </form>
    </>
  );
}
