import { Box, Group, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BarkerCoreEnumsAutomator, getGetApiTenantsTenantIdAutomatorQueryKey, useGetApiTenantsTenantIdAutomator, usePutApiTenantsTenantIdAutomator } from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useGlobalState } from '../data/GlobalState';
import { AdminContentHeader } from '../hoc/Admin/AdminContentHeader';
import { BNButton } from '../components/Button/Button';
import { BNTextInput } from '../components/TextInput/TextInput';
import { BNSelect } from '../components/Select/Select';
import { useFlag } from '@unleash/proxy-client-react';
import { useEffect } from 'react';

export type AutomatorSettingsConfig = {
  automatorId?: BarkerCoreEnumsAutomator;
  companyId?: string;
  apiToken?: string;
};

export default function AutomatorSettings() {
  const { tenantId } = useParams();
  const { currentUser } = useGlobalState('currentUser');
  const queryClient = useQueryClient();
  const hasAutomatorSettingsFlag = useFlag('automator-settings');

  const { data: automator } = useGetApiTenantsTenantIdAutomator(tenantId ?? '', {
    query: {
      enabled: !!tenantId,
      select: (data) => data.data,
    },
    axios: {
      headers: {
        'x-tenant-id': tenantId,
      },
    },
  });

  const { mutateAsync: updateAutomator } = usePutApiTenantsTenantIdAutomator();

  const form = useForm<AutomatorSettingsConfig>({
    initialValues: {
      automatorId: automator?.automatorId ?? 'Unknown',
      companyId: (JSON.parse(automator?.connectionJson ?? '{}') as AutomatorSettingsConfig).companyId ?? '',
      apiToken: (JSON.parse(automator?.connectionJson ?? '{}') as AutomatorSettingsConfig).apiToken ?? '',
    },
  });

  useEffect(() => {
    if (automator) {
      const formattedData = JSON.parse(automator.connectionJson ?? '{}') as AutomatorSettingsConfig;
      form.setValues({
        automatorId: automator.automatorId,
        companyId: formattedData.companyId ?? '',
        apiToken: formattedData.apiToken ?? '',
      });
    }
  }, [automator, form]);

  if (!hasAutomatorSettingsFlag) {
    return null;
  }

  if (!tenantId || !currentUser || (currentUser.roleId !== 'TenantAdmin' && currentUser.roleId !== 'GlobalAdmin')) {
    return null;
  }

  return (
    <>
      <AdminContentHeader
        title="Automator Settings"
        sticky
        rightSection={
          <>
            <BNButton
              size="xs"
              variant="default"
              disabled={!form.isDirty()}
              onClick={() => {
                const formattedData = JSON.parse(automator?.connectionJson ?? '{}') as AutomatorSettingsConfig;
                form.setValues({
                  automatorId: automator?.automatorId ?? 'Unknown',
                  companyId: formattedData.companyId ?? '',
                  apiToken: formattedData.apiToken ?? '',
                });
              }}
            >
              Cancel
            </BNButton>
            <BNButton
              size="xs"
              variant="filled"
              color="green"
              disabled={!form.isDirty()}
              onClick={() =>
                form.onSubmit((values) => {
                  updateAutomator(
                    {
                      tenantId,
                      data: {
                        automatorId: values?.automatorId ?? 'Unknown',
                        connectionJson:
                          values?.automatorId === 'Unknown'
                            ? '{}'
                            : JSON.stringify({
                                companyId: values.companyId,
                                apiToken: values.apiToken,
                              }),
                      },
                    },
                    {
                      onSuccess: (data) => {
                        queryClient.setQueryData(getGetApiTenantsTenantIdAutomatorQueryKey(tenantId), () => data);
                      },
                    },
                  ).then((data) => {
                    const response = data.data;
                    const formattedData = JSON.parse(response?.connectionJson ?? '{}') as AutomatorSettingsConfig;
                    form.resetDirty({
                      automatorId: response.automatorId,
                      companyId: formattedData.companyId ?? '',
                      apiToken: formattedData.apiToken ?? '',
                    });
                  });
                })()
              }
            >
              Save
            </BNButton>
          </>
        }
      />
      <Box pt="xl" pb="lg">
        <Box>
          <BNSelect data={['Unknown', 'AutomatiqSync']} maw={280} size="xs" label="Automator" {...form.getInputProps('automatorId')} />
          <Space h="md" />
          {form.values.automatorId === 'AutomatiqSync' && (
            <Group wrap="nowrap" gap="xl">
              <BNTextInput maw={80} size="xs" label="Company ID" {...form.getInputProps('companyId')} />
              <BNTextInput maw={480} size="xs" label="API Token" {...form.getInputProps('apiToken')} />
            </Group>
          )}
        </Box>
      </Box>
    </>
  );
}
