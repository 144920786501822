import { ActionIcon, Flex, Group, Menu, Popover, Text, Tooltip, UnstyledButton, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { useState } from 'react';
import AccountIcon from '../../components/icons/Account';
import CorporateIcon from '../../components/icons/Corporate';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import LogoutIcon from '../../components/icons/Logout';
import { useGlobalState } from '../../data/GlobalState';
import RoutineIcon from '../../components/icons/Routine';
import pluralize from 'pluralize';
import classes from './NavBar.styles.tsx.module.css';
import RequestQuoteIcon from '../../components/icons/RequestQuote';
import TuneIcon from '../../components/icons/Tune';
import { NotificationMenu } from '../Notifications/NotificationMenu';
import { NotificationsProvider } from '../Notifications/Notifications.hooks';
import { SupportForm } from '../Support/SupportForm';
import CompanyPicker from './CompanyPicker';
import { useFlag } from '@unleash/proxy-client-react';
import CompanyModal from './CompanyModal';
import SyncAltIcon from '../../components/icons/SyncAlt';
import { GiSettingsKnobs } from 'react-icons/gi';
import { BNAvatar } from '../../components/Avatar/Avatar';
import dayjs from 'dayjs';
import SportsBaseballIcon from '../../components/icons/SportsBaseball';
import SportsFootballIcon from '../../components/icons/SportsFootball';
import SportsHockeyIcon from '../../components/icons/SportsHockey';
import SportsBasketballIcon from '../../components/icons/SportsBasketball';
import MenuOpenIcon from '../../components/icons/MenuOpen';
import { useLocalStorage } from '@mantine/hooks';
import SelectArrowsVariableIcon from '../../components/icons/SelectArrows';
import PriceChangeIcon from '../../components/icons/PriceChange';
import PaidIcon from '../../components/icons/Paid';
import { useAuth0 } from '@auth0/auth0-react';
import GroupIcon from '../../components/icons/Group';
import { useNavigate } from 'react-router-dom';

export function NavBar({ setIsMouseoutSuppressed, collapseNavBar }: { setIsMouseoutSuppressed: (value: boolean) => void; collapseNavBar: () => void }) {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme();
  const dark = computedColorScheme === 'dark';
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };
  const { logout } = useAuth0();
  const isDev = useFlag('dev');
  const { principal, tenants, isDtiOpu, dtiAccounts, currentUser } = useGlobalState();

  // const { ruleNavigationValidation } = useRuleState();
  // const navigate = useNavigateWithValidation(() => ruleNavigationValidation());
  const navigate = useNavigate();
  const [isCompanyPickerOpened, setIsCompanyPickerOpened] = useState(false);
  const [isSupportPopoverOpened, setIsSupportPopoverOpened] = useState(false);

  const isCompanyPickerEnabled = useFlag('company-picker');
  const hideSalesFlag = useFlag('hide-sales');

  const canViewSales = !hideSalesFlag && (dtiAccounts.length === 0 || dtiAccounts.some((a) => a.perms.invoice.enabled === '1'));

  const seasonPricerIcon = () => {
    const today = dayjs();

    if (today.month() >= 4 && today.month() < 7) {
      return <SportsFootballIcon />;
    }

    if (today.month() >= 7 && today.month() < 8) {
      return <SportsHockeyIcon />;
    }

    if (today.month() >= 8 && today.month() < 11) {
      return <SportsBasketballIcon />;
    }

    return <SportsBaseballIcon />;
  };

  const [showSalesSidebar, setShowSalesSidebar] = useLocalStorage({
    key: 'setting-sidebar-sales',
    defaultValue: true,
    getInitialValueInEffect: false,
  });

  const [showSeasonSidebar, setShowSeasonSidebar] = useLocalStorage({
    key: 'setting-sidebar-season',
    defaultValue: true,
    getInitialValueInEffect: false,
  });

  const [showSidebar, setShowSidebar] = useLocalStorage({
    key: 'setting-sidebar',
    defaultValue: true,
    getInitialValueInEffect: false,
  });

  const handleSidebarToggle = () => {
    if (location.pathname === '/sales') {
      if (showSalesSidebar) {
        collapseNavBar();
      }
      return setShowSalesSidebar(!showSalesSidebar);
    }
    if (location.pathname === '/seasons') {
      if (showSeasonSidebar) {
        collapseNavBar();
      }
      return setShowSeasonSidebar(!showSeasonSidebar);
    }
    if (showSidebar) {
      collapseNavBar();
    }
    return setShowSidebar(!showSidebar);
  };

  const toggleSidebarTooltip =
    location.pathname === '/sales' && showSalesSidebar
      ? 'Collapse Sidebar'
      : location.pathname === '/sales' && !showSalesSidebar
        ? 'Pin Sidebar'
        : location.pathname === '/seasons' && showSeasonSidebar
          ? 'Collapse Sidebar'
          : location.pathname === '/seasons' && !showSeasonSidebar
            ? 'Pin Sidebar'
            : showSidebar
              ? 'Collapse Sidebar'
              : 'Pin Sidebar';

  return (
    <Flex pl={7} pr="xs" h={52} bg="var(--colors-paper)" align="center" direction="row" justify="space-between" style={{ borderBottom: '1px solid var(--colors-divider)' }}>
      <Group gap="sm" align="center">
        <Menu
          withArrow
          width={200}
          onOpen={() => {
            setIsMouseoutSuppressed(true);
          }}
          onClose={() => setIsMouseoutSuppressed(false)}
        >
          <Menu.Target>
            <UnstyledButton data-pendo-name="Account Menu">
              <BNAvatar principal={principal} isClickable />
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            {tenants && tenants?.length > 1 && (
              <Menu.Item data-pendo-name="Company Picker" leftSection={<SyncAltIcon />} onClick={() => setIsCompanyPickerOpened(true)}>
                Company Picker
              </Menu.Item>
            )}
            {!isDtiOpu && (
              <>
                <Menu.Item leftSection={<AccountIcon />} pos="relative" onClick={() => navigate('/settings/profile')} data-pendo-name="Profile MenuItem">
                  Profile
                </Menu.Item>
                <Menu.Item leftSection={<TuneIcon />} pos="relative" onClick={() => navigate('/settings/pricing')} data-pendo-name="Settings MenuItem">
                  Settings
                </Menu.Item>
                <Menu.Item leftSection={<CorporateIcon />} onClick={() => navigate('/companies')} data-pendo-name="Company MenuItem">
                  {pluralize('Company', tenants?.length || 0)}
                </Menu.Item>
              </>
            )}
            {tenants && tenants?.length === 1 && currentUser?.roleId === 'TenantAdmin' && (
              <Menu.Item leftSection={<GroupIcon />} pos="relative" onClick={() => navigate(`/companies/${tenants[0].tenantId}/users`)}>
                Users
              </Menu.Item>
            )}
            {!isDtiOpu && tenants && tenants?.length === 1 && (
              <Menu.Item leftSection={<RequestQuoteIcon />} onClick={() => navigate(`/companies/${tenants[0].tenantId}/billing`)}>
                Billing
              </Menu.Item>
            )}
            {/*{tenants && tenants?.length === 1 && (*/}
            {/*  <Menu.Item disabled leftSection={<SettingsIcon />} pos="relative" onClick={() => navigate(`/companies/${tenants[0].tenantId}/integrations`)}>*/}
            {/*    Integrations*/}
            {/*    <Badge size="xs" variant="transparent" color="var(--colors-gray-5)" className={classes.comingSoonBadge}>*/}
            {/*      Coming Soon*/}
            {/*    </Badge>*/}
            {/*  </Menu.Item>*/}
            {/*)}*/}
            <Menu.Item
              data-pendo-name="Color Scheme Toggle"
              onClick={() => {
                toggleColorScheme();
              }}
              leftSection={<RoutineIcon />}
            >
              {dark ? 'Light Mode' : 'Dark Mode'}
            </Menu.Item>
            {isDev && (
              <Menu.Item component="a" href="http://172.29.190.95:32000/" target="_blank" leftSection={<GiSettingsKnobs size={20} />}>
                Control Panel
              </Menu.Item>
            )}
            <Menu.Item style={{ borderBottom: 'none' }} leftSection={<LogoutIcon />} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        {!isDtiOpu && (
          <Menu
            withArrow
            width={200}
            onOpen={() => {
              setIsMouseoutSuppressed(true);
            }}
            onClose={() => setIsMouseoutSuppressed(false)}
          >
            <Menu.Target>
              <UnstyledButton data-pendo-name="Page Menu" className={classes.pageMenuTarget} pl={6} pr={4} py={2} ml={-6}>
                <Flex align="center" gap={4}>
                  <Text fz="sm" fw={600} truncate maw={80}>
                    {location.pathname === '/sales' && 'Sales'}
                    {location.pathname === '/seasons' && 'Seasons'}
                    {location.pathname === '/' && 'Inventory'}
                  </Text>
                  <SelectArrowsVariableIcon />
                </Flex>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item leftSection={<PriceChangeIcon />} pos="relative" component="a" href="/">
                Inventory
              </Menu.Item>
              <Menu.Item leftSection={seasonPricerIcon()} pos="relative" component="a" href="/seasons" data-pendo-name="Season Pricer MenuItem">
                Seasons
              </Menu.Item>
              {canViewSales && (
                <Menu.Item leftSection={<PaidIcon />} pos="relative" component="a" href="/sales" style={{ borderBottom: 0 }} data-pendo-name="Sales MenuItem">
                  Sales
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        )}
        {isDtiOpu && (
          <Text fz="sm" fw={600} truncate maw={80}>
            Sales
          </Text>
        )}
      </Group>
      <Group className={classes.navLinks} gap="0.75rem" justify="center" wrap="wrap">
        <Popover
          width={320}
          withArrow
          opened={isSupportPopoverOpened}
          onChange={setIsSupportPopoverOpened}
          closeOnClickOutside={false}
          onOpen={() => {
            setIsMouseoutSuppressed(true);
          }}
          onClose={() => setIsMouseoutSuppressed(false)}
        >
          <Popover.Target>
            <Tooltip label="Support" position="left" withArrow>
              <ActionIcon onClick={() => setIsSupportPopoverOpened(!isSupportPopoverOpened)}>
                <LiveHelpIcon size={24} />
              </ActionIcon>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown pb="md">
            <SupportForm type="other" onClose={() => setIsSupportPopoverOpened(false)} />
          </Popover.Dropdown>
        </Popover>
        {!isDtiOpu && (
          <NotificationsProvider>
            <NotificationMenu
              onOpen={() => {
                setIsMouseoutSuppressed(true);
              }}
              onClose={() => setIsMouseoutSuppressed(false)}
            />
          </NotificationsProvider>
        )}
        <Tooltip label={toggleSidebarTooltip} position="right" withArrow>
          <ActionIcon
            onClick={handleSidebarToggle}
            className={classes.sidebarToggle}
            style={{
              transform:
                (!showSalesSidebar && location.pathname === '/sales') ||
                (!showSeasonSidebar && location.pathname === '/seasons') ||
                (!showSidebar && location.pathname !== '/sales' && location.pathname !== '/seasons')
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
            }}
          >
            <MenuOpenIcon size={24} />
          </ActionIcon>
        </Tooltip>
      </Group>
      {isCompanyPickerEnabled ? (
        <CompanyPicker opened={isCompanyPickerOpened} setOpened={setIsCompanyPickerOpened} />
      ) : (
        <CompanyModal opened={isCompanyPickerOpened} setOpened={setIsCompanyPickerOpened} />
      )}
    </Flex>
  );
}
