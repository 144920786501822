/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classes from './Inventory.InlineSplits.tsx.module.css';
import { Box, Group, Loader } from '@mantine/core';
import { useDidUpdate, useDisclosure, useHover } from '@mantine/hooks';
import { useEffect, useMemo, useRef } from 'react';
import { BNSelect } from '../../components/Select/Select';
import { useForm } from '@mantine/form';
import { getPointOfSaleSplitOptions, getPointOfSaleSplitType, setSplitsModelForUpdate } from '../../components/Splits/Splits.utils';
import { useGlobalState } from '../../data/GlobalState';
import { BarkerEventListing } from '../../types';
import { useSetAtom } from 'jotai';
import { updateListingsAtom } from '../../data/atoms';
import { BNButton } from '../../components/Button/Button';
import CheckIcon from '../../components/icons/Check';
import CloseIcon from '../../components/icons/Close';
import { useListingState } from '../../data/ListingState';
import { SplitsButtonGroupVariable } from '../SeatingChart/Filters/Filters.SplitsButtonGroupVariable';

type InlineSplitsToggleProps = {
  listing: BarkerEventListing;
};

type InlineSplitsProps = {
  closeToggle: () => void;
} & InlineSplitsToggleProps;

export function InlineSplitsToggle({ listing }: InlineSplitsToggleProps) {
  const [toggled, { open: openToggle, close: closeToggle }] = useDisclosure(false);
  const { tenants } = useGlobalState('tenants');
  const tenantPointOfSale = useMemo(() => tenants?.find((t) => t.tenantId === listing.event?.tenantId)?.pointOfSaleId || 'Unknown', [listing.event?.tenantId, tenants]);
  const { hovered, ref: hoverRef } = useHover();

  useDidUpdate(() => {
    if (!toggled) {
      // HACK: Manually trigger mouseleave event to reset cell. Hover not changing state when absolute positioned elements disappear.
      hoverRef.current?.dispatchEvent(new Event('mouseleave'));
    }
  }, [toggled]);

  useEffect(() => {
    // HACK: Handles z-index issue with inline splits when floating buttons are present.
    const ref = hoverRef.current?.closest('[col-id]') as HTMLElement;
    if (toggled || hovered) {
      ref.style.zIndex = '999';
    }
    return () => {
      ref.style.zIndex = '0';
    };
  }, [hoverRef, hovered, toggled]);

  return (
    <Box onClick={() => !toggled && openToggle()} ref={hoverRef} style={{ overflow: 'hidden' }}>
      {!hovered && !toggled ? (
        getPointOfSaleSplitOptions(tenantPointOfSale).find((x) => x.value === getPointOfSaleSplitType(tenantPointOfSale, listing.splits).splitType)?.label
      ) : (
        <Box pos="absolute" inset={0}>
          <InlineSplits listing={listing} closeToggle={closeToggle} />
        </Box>
      )}
    </Box>
  );
}

export const InlineSplits = ({ listing, closeToggle }: InlineSplitsProps) => {
  const [isInlineLoading, { open: inlineSavingStart, close: inlineSavingDone }] = useDisclosure();
  const updateListing = useSetAtom(updateListingsAtom);
  const { tenants } = useGlobalState('tenants');
  const { patchListing } = useListingState('patchListing');
  const tenantPointOfSale = useMemo(() => tenants?.find((t) => t.tenantId === listing.event?.tenantId)?.pointOfSaleId || 'Unknown', [listing.event?.tenantId, tenants]);
  const initialSplitValues = getPointOfSaleSplitType(tenantPointOfSale, listing.splits);
  const splitOptions = useMemo(() => getPointOfSaleSplitOptions(tenantPointOfSale), [tenantPointOfSale]);
  const hasCustom = !!splitOptions.find((x) => x.value === 'CUSTOM');
  const { values, getInputProps, isDirty, resetDirty, setFieldValue } = useForm({
    initialValues: {
      ...initialSplitValues,
    },
  });

  const updateSplits = async () => {
    if (isDirty()) {
      const update = setSplitsModelForUpdate(tenantPointOfSale, values);
      if (update) {
        inlineSavingStart();
        const { data: listingResult } = await patchListing({
          tenantId: listing.tenantId,
          listingId: listing.listingId,
          data: [update],
        });
        inlineSavingDone();
        updateListing(listingResult);
        resetDirty();
        closeToggle();
      }
    }
  };

  const cancel = () => {
    resetDirty();
    closeToggle();
  };

  const isChanging = useRef(false);
  // Calculate optimal dropdown width for possible custom values -- each button is 27px wide, subtract the negative margins, add the padding
  const customDropdownWidth = listing.quantityRemaining > 3 ? listing.quantityRemaining * 24 - (listing.quantityRemaining - 1) + 28 : 127;
  return (
    <Group h="100%" justify="center" gap={0} pos="relative">
      <Box className={classes.cellContent}>
        {isDirty() && (
          <>
            <BNButton variant="default" size="xs" px={3} className={classes.floatingCancelButton} onClick={() => cancel()}>
              <CloseIcon size={22} />
            </BNButton>
            <BNButton variant="filled" color="green" disabled={!isDirty()} size="xs" px={3} className={classes.floatingCheckButton} onClick={() => updateSplits()}>
              <CheckIcon size={22} />
            </BNButton>
          </>
        )}
        <BNSelect
          {...getInputProps('splitType')}
          onChange={(value) => {
            if (value !== values.splitType) {
              isChanging.current = true;
              getInputProps('splitType').onChange(value);
            }
            if (value !== 'CUSTOM') {
              getInputProps('customSplits').onChange([]);
            }
          }}
          withinPortal
          m={0}
          p={0}
          pr={1}
          min={250}
          size="xs"
          data-select="splits"
          renderOption={({ option: { label, value } }) => {
            if (value === 'CUSTOM' && label === 'Custom') {
              return (
                <div
                  data-bncustom="custom"
                  data-click-ignore="true"
                  style={{ cursor: 'default' }}
                  onClick={(event) => {
                    if (event.target instanceof HTMLDivElement && event.target.getAttribute('data-click-ignore') === 'true') {
                      event.stopPropagation();
                    }
                  }}
                >
                  <div data-click-ignore="true" className="bn-custom-label">
                    {label || value}
                  </div>
                  <div style={{ marginTop: 10, width: '100%' }}>
                    <SplitsButtonGroupVariable
                      count={listing.quantityRemaining}
                      size="xs"
                      splits={getInputProps('customSplits').value}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={(_value) => {
                        if (_value.length > 0) {
                          isChanging.current = true;
                          setFieldValue('splitType', value);
                          getInputProps('customSplits').onChange(_value);
                        } else {
                          getInputProps('customSplits').onChange(_value);
                          setFieldValue('splitType', splitOptions[0].value);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            }
            return <div>{label || value}</div>;
          }}
          aria-label="Split Type"
          labelProps={{ style: { whiteSpace: 'nowrap' } }}
          className={isDirty() ? `${classes.hoverSelect} dirty` : `${classes.hoverSelect}`}
          data={splitOptions}
          rightSection={isInlineLoading && <Loader size="xs" />}
          readOnly={isInlineLoading}
          onDropdownClose={() => {
            if (!isChanging.current) {
              setTimeout(() => {
                closeToggle();
              }, 250);
            }
          }}
          dropdownWidth={hasCustom ? customDropdownWidth : 127}
          maxDropdownHeight={250}
        />
      </Box>
    </Group>
  );
};
