import { ActionIcon, Autocomplete, Container, Grid, Group, Indicator, SimpleGrid, Space, Stack, Text, Tooltip } from '@mantine/core';
import { useMemo, useState } from 'react';
import { BNButton } from '../../components/Button/Button';
import { BNDatePicker } from '../../components/DatePicker/DatePicker';
import DateRangeIcon from '../../components/icons/DateRange';
import SearchIcon from '../../components/icons/Search';
import { BNTextInput, Clearable } from '../../components/TextInput/TextInput';
import { useSearchResults } from './SearchResults.hooks';
import { BNSelect } from '../../components/Select/Select';
import dayjs from 'dayjs';
import { useDidUpdate, useDisclosure, useFocusTrap } from '@mantine/hooks';
import { TagsFilter } from './Search.TagsFilter';
import { AntiTagsFilter } from './Search.AntiTagsFilter';
import { useFlag } from '@unleash/proxy-client-react';
import { useGlobalState } from '../../data/GlobalState';
import FiltersIcon from '../../components/icons/Filters';
import { BNSwitch } from '../../components/Switch/Switch';
import { InventorySearchParams, PricerView } from '../../types';
import classes from './Search.styles.tsx.module.css';
import { PricerStatusMultiSelect } from './Search.PricerStatusMultiSelect';

export function Search() {
  const { form, search, reset, isLoading, queryAutoCompleteOptions } = useSearchResults('form', 'search', 'reset', 'isLoading', 'queryAutoCompleteOptions');

  const { pricerView } = useGlobalState('pricerView');
  const [advancedFilters, setAdvancedFilters] = useState(
    form.values.listingId !== '' ||
      form.values.eventId !== '' ||
      (form.values.pricerStatuses && form.values.pricerStatuses.length > 0) ||
      form.values.daysSinceRepriced !== undefined ||
      form.values.daysSinceViewed !== undefined ||
      form.values.daysSinceLastSale !== undefined ||
      form.values.onlyNotBroadcasting !== undefined ||
      form.values.purchaseDateFrom !== undefined ||
      form.values.purchaseDateTo !== undefined ||
      form.values.tags?.length > 0 ||
      form.values.antiTags?.length > 0 ||
      pricerView === PricerView.Split,
  );

  useDidUpdate(() => {
    if (pricerView === PricerView.Split) {
      setAdvancedFilters(true);
    }
  }, [pricerView]);

  const [showCustomPurchasedFrom, setShowCustomPurchasedFrom] = useState(
    form.values.purchaseDateFrom !== undefined &&
      form.values.purchaseDateTo !== undefined &&
      form.values.purchaseDateFrom.getTime() !== dayjs().startOf('day').toDate().getTime() &&
      form.values.purchaseDateFrom.getTime() !== dayjs().subtract(1, 'day').startOf('day').toDate().getTime() &&
      form.values.purchaseDateFrom.getTime() !== dayjs().subtract(7, 'day').startOf('day').toDate().getTime(),
  );

  const [showCustomPricedSince, setShowCustomPricedSince] = useState(
    form.values.daysSinceRepriced !== undefined &&
      form.values.daysSinceRepriced !== 1 &&
      form.values.daysSinceRepriced !== 7 &&
      form.values.daysSinceRepriced !== 30 &&
      form.values.daysSinceRepriced !== 9999,
  );

  const [showCustomSoldSince, setShowCustomSoldSince] = useState(
    form.values.daysSinceLastSale !== undefined &&
      form.values.daysSinceLastSale !== 1 &&
      form.values.daysSinceLastSale !== 7 &&
      form.values.daysSinceLastSale !== 30 &&
      form.values.daysSinceLastSale !== 9999,
  );

  const [showCustomFirstSeen, setShowCustomFirstSeen] = useState(
    form.values.daysSinceFirstSeen !== undefined &&
      form.values.daysSinceFirstSeen !== 1 &&
      form.values.daysSinceFirstSeen !== 7 &&
      form.values.daysSinceFirstSeen !== 30 &&
      form.values.daysSinceFirstSeen !== 9999,
  );

  const focusTrapRef = useFocusTrap();

  const { tenants, selectedTenantIds } = useGlobalState('tenants', 'selectedTenantIds');
  const singleSkyBoxTenantSelected = useMemo(
    () => selectedTenantIds?.length === 1 && tenants?.find((t) => t.tenantId === selectedTenantIds[0] && t.pointOfSaleId === 'SkyBox'),
    [selectedTenantIds, tenants],
  );

  const [autoCompleteOpened, { toggle: toggleAutoComplete }] = useDisclosure();

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      search(form.values);
      if (autoCompleteOpened) {
        toggleAutoComplete();
      }
    }
  }

  const hasFirstSeenFilterFlag =
    useFlag('filter-by-first-seen') || selectedTenantIds?.some((id) => id === 'deac90fd-337e-4ef5-9f11-954437fd9298' || id === '0e1461d2-6e23-4f29-8ebc-e100cdacb2ae');

  return (
    <Group p={16} gap={12} className="searchArea" wrap="wrap">
      <SimpleGrid cols={2} spacing={12} style={{ flexGrow: 1 }}>
        <BNDatePicker
          aria-label="From Date"
          size="xs"
          variant="default"
          {...form.getInputProps('fromDate')}
          clearable
          valueFormat="MM/DD/YY"
          leftSection={<DateRangeIcon size={20} />}
          selectOnFocus
          onKeyUp={handleKeyUp}
          placeholder="From Date"
          onChange={(dateValue) => {
            if (dateValue === null) {
              form.setFieldValue('fromDate', dateValue);
              return;
            }

            form.setFieldValue('fromDate', dateValue);

            if (form.values.toDate && form.values.toDate < dateValue) {
              form.setFieldValue('toDate', dateValue);
            }
          }}
        />
        <BNDatePicker
          aria-label="To Date"
          size="xs"
          variant="default"
          {...form.getInputProps('toDate')}
          clearable
          valueFormat="MM/DD/YY"
          leftSection={<DateRangeIcon size={20} />}
          selectOnFocus
          onKeyUp={handleKeyUp}
          placeholder="To Date"
          onChange={(dateValue) => {
            if (dateValue === null) {
              form.setFieldValue('toDate', dateValue);
              return;
            }

            form.setFieldValue('toDate', dateValue);

            if (form.values.fromDate && form.values.fromDate > dateValue) {
              form.setFieldValue('fromDate', dateValue);
            }
          }}
        />
      </SimpleGrid>
      <Grid columns={24} gutter={0} w="100%" h={30}>
        <Grid.Col span="content" style={{ flex: 1 }}>
          <Autocomplete
            size="xs"
            data={queryAutoCompleteOptions}
            placeholder="Search Events"
            value={form.values.query}
            onChange={(query) => {
              form.setFieldValue('query', query);
            }}
            onFocus={() => !autoCompleteOpened && toggleAutoComplete()}
            onKeyUp={handleKeyUp}
            limit={5}
            type="search"
            leftSection={<SearchIcon size={20} />}
            className={classes.searchBox}
            dropdownOpened={autoCompleteOpened}
            onDropdownClose={() => toggleAutoComplete()}
            rightSection={form.values.query && <Clearable onChange={() => {}} clearableOnClick={() => form.setValues({ query: '' })} />}
          />
        </Grid.Col>
        <Grid.Col span="content" style={{ justifyContent: 'end', display: 'flex' }}>
          <Tooltip label="Advanced Filters" withArrow>
            <Indicator color="var(--colors-selectedBorder)" size={4} offset={6} disabled>
              <ActionIcon
                c={advancedFilters ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)'}
                lightHidden={pricerView === PricerView.Split}
                darkHidden={pricerView === PricerView.Split}
                onClick={() => {
                  setAdvancedFilters(!advancedFilters);
                }}
                className={advancedFilters ? classes.advancedFiltersButtonSelected : classes.advancedFiltersButton}
              >
                <FiltersIcon />
              </ActionIcon>
            </Indicator>
          </Tooltip>
        </Grid.Col>
      </Grid>
      <Container p="xs" className={classes.advFilterWrapper} style={{ display: advancedFilters ? 'flex' : 'none' }}>
        <Group justify="space-between">
          <Text fz={11} c="var(--colors-gray-4)" tt="uppercase" lts="0.025em">
            Advanced Filters
          </Text>
        </Group>
        <Space h="sm" />
        <Stack gap={8}>
          <PricerStatusMultiSelect value={form.values.pricerStatuses ?? []} onChange={(value) => form.setFieldValue('pricerStatuses', value)} placeholder="" />
          <SimpleGrid cols={2} spacing={12} style={{ flexGrow: 1 }}>
            <BNTextInput
              size="xs"
              variant="default"
              label="Listing ID"
              {...form.getInputProps('listingId')}
              clearable
              clearableOnClick={() => form.setFieldValue('listingId', '')}
              onKeyUp={handleKeyUp}
              data-pendo-name="Listing ID Filter Input"
            />
            <BNTextInput
              size="xs"
              variant="default"
              label="Event ID"
              {...form.getInputProps('eventId')}
              clearable
              clearableOnClick={() => form.setFieldValue('eventId', '')}
              onKeyUp={handleKeyUp}
              data-pendo-name="Event ID Filter Input"
            />
          </SimpleGrid>
          <SimpleGrid cols={2} spacing={12} style={{ flexGrow: 1 }}>
            {showCustomPricedSince ? (
              <div ref={focusTrapRef}>
                <BNDatePicker
                  size="xs"
                  variant="default"
                  valueFormat="MM/DD/YY"
                  label="Not Priced Since"
                  clearable
                  maxDate={dayjs().startOf('day').toDate()}
                  value={form.values.daysSinceRepriced === undefined ? null : dayjs().subtract(form.values.daysSinceRepriced, 'day').toDate()}
                  onChange={(e) => {
                    const days = Math.abs(dayjs(e).diff(dayjs(), 'day'));
                    form.setFieldValue('daysSinceRepriced', days);
                  }}
                  clearButtonProps={{
                    onClick: () => {
                      form.setFieldValue('daysSinceRepriced', undefined);
                      setShowCustomPricedSince(false);
                    },
                  }}
                  leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                  onKeyUp={handleKeyUp}
                  className={classes.dateField}
                />
              </div>
            ) : (
              <BNSelect
                label="Not Priced In"
                size="xs"
                clearable
                value={form.values.daysSinceRepriced === undefined ? null : form.values.daysSinceRepriced.toString()}
                onChange={(e) => {
                  if (e === 'custom') {
                    setShowCustomPricedSince(true);
                  } else if (e === null) {
                    form.setFieldValue('daysSinceRepriced', undefined);
                  } else {
                    form.setFieldValue('daysSinceRepriced', parseInt(e));
                  }
                }}
                data={[
                  { value: '1', label: '1 Day' },
                  { value: '7', label: '7 Days' },
                  { value: '30', label: '30 Days' },
                  { value: 'custom', label: 'Custom Date' },
                  { value: '9999', label: 'Ever' },
                ]}
                data-pendo-name="Not Priced In Filter Select"
              />
            )}
            {showCustomPurchasedFrom ? (
              <div ref={focusTrapRef}>
                <BNDatePicker
                  leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                  valueFormat="MM/DD/YY"
                  size="xs"
                  variant="default"
                  label="Purchased Since"
                  clearable
                  maxDate={dayjs().startOf('day').toDate()}
                  clearButtonProps={{
                    onClick: () => {
                      form.setFieldValue('purchaseDateFrom', undefined);
                      form.setFieldValue('purchaseDateTo', undefined);
                      setShowCustomPurchasedFrom(false);
                    },
                  }}
                  value={form.values.purchaseDateFrom}
                  onChange={(e) => {
                    if (e === null) {
                      form.setFieldValue('purchaseDateFrom', undefined);
                      form.setFieldValue('purchaseDateTo', undefined);
                    } else {
                      form.setFieldValue('purchaseDateFrom', e);
                      form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                    }
                  }}
                  className={classes.dateField}
                />
              </div>
            ) : (
              <BNSelect
                label="Purchased"
                size="xs"
                clearable
                value={
                  !form.values.purchaseDateFrom || !form.values.purchaseDateTo
                    ? ''
                    : form.values.purchaseDateFrom.getTime() === dayjs().startOf('day').toDate().getTime() &&
                        form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                      ? '0'
                      : form.values.purchaseDateFrom.getTime() === dayjs().subtract(1, 'day').startOf('day').toDate().getTime() &&
                          form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                        ? '1'
                        : form.values.purchaseDateFrom.getTime() === dayjs().startOf('week').toDate().getTime() &&
                            form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                          ? 'week'
                          : form.values.purchaseDateFrom && form.values.purchaseDateTo
                            ? 'custom'
                            : undefined
                }
                onChange={(e) => {
                  if (e === 'custom') {
                    setShowCustomPurchasedFrom(true);
                  } else if (e === null) {
                    form.setFieldValue('purchaseDateFrom', undefined);
                    form.setFieldValue('purchaseDateTo', undefined);
                  } else if (e === 'week') {
                    form.setFieldValue('purchaseDateFrom', dayjs().startOf('week').startOf('day').toDate());
                    form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                  } else {
                    form.setFieldValue('purchaseDateFrom', dayjs().subtract(parseInt(e), 'day').startOf('day').toDate());
                    form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                  }
                }}
                data={[
                  { value: '0', label: 'Today' },
                  { value: '1', label: 'Yesterday' },
                  { value: 'week', label: 'This Week' },
                  { value: 'custom', label: 'Custom Date' },
                ]}
                data-pendo-name="Purchased Filter Select"
              />
            )}
            {showCustomSoldSince ? (
              <div ref={focusTrapRef}>
                <BNDatePicker
                  size="xs"
                  variant="default"
                  valueFormat="MM/DD/YY"
                  label="No Sales Since"
                  clearable
                  maxDate={dayjs().startOf('day').toDate()}
                  value={form.values.daysSinceLastSale === undefined ? null : dayjs().subtract(form.values.daysSinceLastSale, 'day').toDate()}
                  onChange={(e) => {
                    const days = Math.abs(dayjs(e).diff(dayjs(), 'day'));
                    form.setFieldValue('daysSinceLastSale', days);
                  }}
                  clearButtonProps={{
                    onClick: () => {
                      form.setFieldValue('daysSinceLastSale', undefined);
                      setShowCustomSoldSince(false);
                    },
                  }}
                  leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                  onKeyUp={handleKeyUp}
                  className={classes.dateField}
                />
              </div>
            ) : (
              <BNSelect
                label="No Sales In"
                size="xs"
                clearable
                value={form.values.daysSinceLastSale === undefined ? null : form.values.daysSinceLastSale.toString()}
                onChange={(e) => {
                  if (e === 'custom') {
                    setShowCustomSoldSince(true);
                  } else if (e === null) {
                    form.setFieldValue('daysSinceLastSale', undefined);
                  } else {
                    form.setFieldValue('daysSinceLastSale', parseInt(e));
                  }
                }}
                data={[
                  { value: '1', label: '1 Day' },
                  { value: '7', label: '7 Days' },
                  { value: '30', label: '30 Days' },
                  { value: 'custom', label: 'Custom Date' },
                  { value: '9999', label: 'Ever' },
                ]}
                data-pendo-name="No Sales In Filter Select"
              />
            )}
            {hasFirstSeenFilterFlag ? (
              <>
                {showCustomFirstSeen ? (
                  <div ref={focusTrapRef}>
                    <BNDatePicker
                      size="xs"
                      variant="default"
                      valueFormat="MM/DD/YY"
                      label="First Seen"
                      clearable
                      maxDate={dayjs().startOf('day').toDate()}
                      value={form.values.daysSinceFirstSeen === undefined ? null : dayjs().subtract(form.values.daysSinceFirstSeen, 'day').toDate()}
                      onChange={(e) => {
                        const days = Math.abs(dayjs(e).diff(dayjs(), 'day'));
                        form.setFieldValue('daysSinceFirstSeen', days);
                      }}
                      clearButtonProps={{
                        onClick: () => {
                          form.setFieldValue('daysSinceFirstSeen', undefined);
                          setShowCustomFirstSeen(false);
                        },
                      }}
                      leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                      onKeyUp={handleKeyUp}
                      className={classes.dateField}
                    />
                  </div>
                ) : (
                  <BNSelect
                    label="First Seen"
                    size="xs"
                    clearable
                    value={form.values.daysSinceFirstSeen === undefined ? null : form.values.daysSinceFirstSeen.toString()}
                    onChange={(e) => {
                      if (e === 'custom') {
                        setShowCustomFirstSeen(true);
                      } else if (e === null) {
                        form.setFieldValue('daysSinceFirstSeen', undefined);
                      } else {
                        form.setFieldValue('daysSinceFirstSeen', parseInt(e));
                      }
                    }}
                    data={[
                      { value: '1', label: '1 Day' },
                      { value: '7', label: '7 Days' },
                      { value: '30', label: '30 Days' },
                      { value: 'custom', label: 'Custom Date' },
                      { value: '9999', label: 'Ever' },
                    ]}
                    data-pendo-name="First Seen Filter Select"
                  />
                )}
              </>
            ) : (
              <BNSwitch
                label="Not Broadcasting"
                size="md"
                checked={form.values.onlyNotBroadcasting === true}
                onChange={(event) => form.setFieldValue('onlyNotBroadcasting', event.currentTarget.checked)}
                className={classes.switchLabelTop}
                data-pendo-name="Not Broadcasting Filter Switch"
              />
            )}
          </SimpleGrid>
          {hasFirstSeenFilterFlag && (
            <BNSwitch
              label="Not Broadcasting"
              size="md"
              checked={form.values.onlyNotBroadcasting === true}
              onChange={(event) => form.setFieldValue('onlyNotBroadcasting', event.currentTarget.checked)}
              className={classes.switchLabelTop}
              data-pendo-name="Not Broadcasting Filter Switch"
            />
          )}
          <BNSelect
            data={[
              { value: 'open', label: 'Only With Inventory' },
              { value: 'mine', label: 'Including Sold Out' },
              { value: 'all', label: 'All Events' },
            ]}
            size="xs"
            label="Show Events"
            value={form.values.showEvents ?? 'open'}
            onChange={(e) => form.setFieldValue('showEvents', (e as InventorySearchParams['showEvents']) ?? 'open')}
            data-pendo-name="Show Events Filter Select"
          />
          <TagsFilter />
          {singleSkyBoxTenantSelected && <AntiTagsFilter />}
        </Stack>
      </Container>
      <SimpleGrid cols={2} className={classes.btnWrapper} spacing={12}>
        <BNButton
          size="xs"
          fullWidth
          variant="default"
          onClick={() => {
            reset();
            setShowCustomPricedSince(false);
            setShowCustomPurchasedFrom(false);
            //setShowCustomViewedSince(false);
          }}
        >
          Reset
        </BNButton>
        <BNButton disabled={!form.isValid()} loading={isLoading} size="xs" variant="filled" color="gray" fullWidth onClick={() => search(form.values)}>
          Search
        </BNButton>
      </SimpleGrid>
    </Group>
  );
}
