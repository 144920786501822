import { useEffect, useRef } from 'react';
import { getDtiItemsAccountId, getApiInventory, BarkerCoreEnumsPricerStatus } from '../api';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';

export function useMessageHandlers({ isLoading }: { isLoading: boolean }) {
  const isRuleRoute = location.pathname.includes('/rule');
  const { getAccessTokenSilently } = useAuth0();
  const { isAuthenticated } = useAuth0();
  const readySent = useRef(false);
  const queue = useRef<MessageEvent[]>([]);

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      if (event.data?.type === 'bn.requestPrice') {
        const { dti_id, eventId } = event.data;
        if (!isLoading && isAuthenticated) {
          // HACK: Hard coded account ID for now for winventory
          const overrideSelectedAccountId = 89717;

          try {
            getAccessTokenSilently();
            const items = await getDtiItemsAccountId(overrideSelectedAccountId, {
              event_id: eventId,
            });

            // @ts-ignore - There is an alt model returned when not authorized
            if (items.data?.message === 'not authorized') {
              console.warn('Not authorized');
              queue.current.push(event);
              return;
            }

            if (items.data?.items?.length > 0) {
              const item = items.data.items.find((x) => x.item_id === dti_id);

              const inventory = await getApiInventory(
                {
                  // eventIds: [_event.id.toString()],
                  listingIds: [item?.listing_id!],
                  fromDate: dayjs().startOf('day').toDate(),
                  toDate: dayjs().add(2, 'years').startOf('day').toDate(),
                },
              );

              const listing = inventory.data?.listings.find((x) => x.listingId === item?.listing_id);
              if (listing) {
                window.parent.postMessage({ type: 'bn.price', data: { price: listing.unitPrice, dtiId: dti_id, pricerStatusId: listing.pricerStatusId } }, '*');
              }
            } else {
              window.parent.postMessage({ type: 'bn.price', data: { price: '', dtiId: dti_id, pricerStatusId: BarkerCoreEnumsPricerStatus.None } }, '*');
            }
          } catch (error) {
            window.parent.postMessage({ type: 'bn.price.error', data: { dtiId: dti_id, message: 'Error' } }, '*');
          }
        } else {
          queue.current.push(event);
          window.parent.postMessage({ type: 'bn.price', data: { price: '', dtiId: dti_id, pricerStatusId: BarkerCoreEnumsPricerStatus.None } }, '*');
        }
      }
    };

    let interval: NodeJS.Timeout;
    function processQueue() {
      interval = setTimeout(() => {
        if (isAuthenticated && !isLoading && queue.current.length > 0) {
          console.log('Processing queued messages');
          queue.current.forEach((event) => handleMessage(event));
          queue.current = [];
        }
        processQueue();
      }, 1000);
    }

    if (isRuleRoute) {
      window.addEventListener('message', handleMessage);
      if (window.self !== window.top && !isLoading && isAuthenticated && !readySent.current) {
        console.log('Sending ready message');
        readySent.current = true;
        window.parent.postMessage({ type: 'bn.ready' }, '*');
      }
      processQueue();
    }
    return () => {
      window.removeEventListener('message', handleMessage);
      clearTimeout(interval);
    };
  }, [getAccessTokenSilently, isAuthenticated, isLoading, isRuleRoute]);
}
