import { createBrowserRouter, createRoutesFromElements, Navigate, Route, Routes } from 'react-router-dom';
import ErrorPage, { ErrorPageProviderWrapper } from './components/ErrorPage/ErrorPage';
import { OAuthPopup } from './components/react-use-oauth2';
import AdminFrame from './hoc/Admin/AdminFrame';
import CompanyFrame from './hoc/Admin/CompanyFrame';
import SettingsFrame from './hoc/Admin/SettingsFrame';
import { AuthGuard } from './hoc/Auth/AuthGuard';
import { AccountDisabled } from './pages/AccountDisabled';
import AuthCallback from './pages/AuthCallback';
import Billing, { BillingFrame } from './pages/Billing';
import CurrentUsage from './pages/Billing/CurrentUsage';
import Invoice from './pages/Billing/Invoice';
import Payment from './pages/Billing/Payment';
import Plan from './pages/Billing/Plan';
import Companies from './pages/Companies';
import CompanyProfile from './pages/CompanyProfile';
import CompanySettings from './pages/CompanySettings';
import { QuickBooksAuth } from './pages/QuickBooksAuth';
import { PricingSettings } from './pages/Settings/PricingSettings';
import ProfileSettings from './pages/Settings/ProfileSettings';
import { WaitingRoom } from './pages/WaitingRoom';
import Home from './pages/Home';
import { Auth0ProviderWithNavigate } from './components/Auth0ProviderWithNavigate';
import { TenantGuard } from './hoc/Auth/TenantGuard';
import { Onboarding } from './pages/Onboarding';
import RuleIsolation from './pages/RuleIsolation';
import SeasonPricer from './pages/SeasonPricer';
import { Sales } from './pages/Sales';
import AutomatorSettings from './pages/AutomatorSettings';
import Users from './pages/Users';
import NotificationSettings from './pages/Settings/NotificationSettings';
import AppearanceSettings from './pages/Settings/AppearanceSettings';
import { Mobile, MobileWrapper } from './pages/Mobile';
import CompanyPickerMobile from './pages/CompanyPicker.Mobile';
import { MarketFiltersMobile } from './pages/MarketFilters.Mobile';
import { ListingDetailsMobile } from './pages/ListingDetails.Mobile';
import { EventListingsMobile } from './pages/EventListings.Mobile';
import { WrapperProviders } from './WrapperProviders';
import { useMemo } from 'react';
import { useGlobalState } from './data/GlobalState';
import { DtiOpuGuard } from './hoc/Auth/OpuGuard';
import { BrowserExtensionPopup } from './pages/BrowserExtensionPopup';

export const useAppRouter = () => {
  const { isDtiOpu } = useGlobalState('isDtiOpu');
  const dtiOpuRoutes = useMemo(
    () => (
      <Routes>
        <Route element={<Auth0ProviderWithNavigate />}>
          <Route element={<WrapperProviders />}>
            <Route element={<AuthGuard />}>
              <Route element={<TenantGuard />}>
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/waiting-room" element={<WaitingRoom />} />
                <Route path="/account-disabled" element={<AccountDisabled />} />
                <Route
                  path="*"
                  element={
                    <ErrorPage title="Nothing here" content="You navigated somewhere unexpected. If you believe this to be an error with the pricer, please contact support." />
                  }
                />
                <Route element={<DtiOpuGuard />}>
                  <Route path="/" element={<Sales />} />
                </Route>
              </Route>
            </Route>
            <Route path="/auth/callback" element={<AuthCallback />} />
          </Route>
        </Route>
      </Routes>
    ),
    [],
  );

  const routes = useMemo(
    () => (
      <Routes>
        <Route element={<Auth0ProviderWithNavigate />}>
          <Route element={<WrapperProviders />}>
            <Route element={<AuthGuard />}>
              <Route element={<TenantGuard />}>
                <Route path="/" element={<Home />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/waiting-room" element={<WaitingRoom />} />
                <Route path="/rule" element={<RuleIsolation />} />
                <Route path="/seasons" element={<SeasonPricer />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="companies/:tenantId?" element={<AdminFrame />}>
                  <Route element={<CompanyFrame />}>
                    <Route index element={<Companies />} />
                    <Route element={<BillingFrame />}>
                      <Route path="billing" element={<Billing />} />
                      <Route path="billing/invoices/current" element={<CurrentUsage />} />
                      <Route path="billing/invoices/:invoiceId" element={<Invoice />} />
                      <Route path="billing/plan" element={<Plan />} />
                      <Route path="billing/payment" element={<Payment />} />
                    </Route>
                    <Route path="profile" element={<CompanyProfile />} />
                    <Route path="automator" element={<AutomatorSettings />} />
                    <Route path="settings/pricing" element={<CompanySettings />} />
                    <Route path="users" element={<Users />} />
                  </Route>
                </Route>
                <Route path="/browser-extension" element={<BrowserExtensionPopup />} />
                <Route path="/mobile" element={<MobileWrapper />}>
                  <Route index element={<Mobile />} />
                  <Route path="companypicker" element={<CompanyPickerMobile />} />
                  <Route path="events/:eventId/listings" element={<EventListingsMobile />} />
                  <Route path="events/:eventId/listings/:listingId/" element={<ListingDetailsMobile />} />
                  <Route path="events/:eventId/listings/:listingId/filters" element={<MarketFiltersMobile />} />
                </Route>
                <Route element={<AdminFrame />}>
                  <Route element={<SettingsFrame />}>
                    <Route path="/settings" element={<Navigate to="/settings/pricing" />} />
                    <Route path="/settings/pricing" element={<PricingSettings />} />
                    <Route path="/settings/profile" element={<ProfileSettings />} />
                    <Route path="/settings/notifications" element={<NotificationSettings />} />
                    <Route path="/settings/appearance" element={<AppearanceSettings />} />
                  </Route>
                </Route>
              </Route>
              <Route path="/qbo/auth" element={<QuickBooksAuth />} />
              <Route path="/account-disabled" element={<AccountDisabled />} />
            </Route>
          </Route>
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/qbo/callback" element={<OAuthPopup />} />
          <Route
            path="*"
            element={<ErrorPage title="Nothing here" content="You navigated somewhere unexpected. If you believe this to be an error with the pricer, please contact support." />}
          />
        </Route>
      </Routes>
    ),
    [],
  );

  return isDtiOpu ? dtiOpuRoutes : routes;
};

const routes = (
  <>
    <Route
      element={<Auth0ProviderWithNavigate />}
      errorElement={
        <ErrorPageProviderWrapper
          title="Something went wrong"
          content="The pricer encountered an unexpected error. Information regarding this error was logged. If you experience this regularly, we encourage you to contact support."
          report
        />
      }
    >
      <Route element={<WrapperProviders />}>
        <Route element={<AuthGuard />}>
          <Route element={<TenantGuard />}>
            <Route path="/" element={<Home />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/waiting-room" element={<WaitingRoom />} />
            <Route path="/rule" element={<RuleIsolation />} />
            <Route path="/seasons" element={<SeasonPricer />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="companies/:tenantId?" element={<AdminFrame />}>
              <Route element={<CompanyFrame />}>
                <Route index element={<Companies />} />
                <Route element={<BillingFrame />}>
                  <Route path="billing" element={<Billing />} />
                  <Route path="billing/invoices/current" element={<CurrentUsage />} />
                  <Route path="billing/invoices/:invoiceId" element={<Invoice />} />
                  <Route path="billing/plan" element={<Plan />} />
                  <Route path="billing/payment" element={<Payment />} />
                </Route>
                <Route path="profile" element={<CompanyProfile />} />
                <Route path="automator" element={<AutomatorSettings />} />
                <Route path="settings/pricing" element={<CompanySettings />} />
                <Route path="users" element={<Users />} />
              </Route>
            </Route>
            <Route path="/browser-extension" element={<BrowserExtensionPopup />} />
            <Route path="/mobile" element={<MobileWrapper />}>
              <Route index element={<Mobile />} />
              <Route path="companypicker" element={<CompanyPickerMobile />} />
              <Route path="events/:eventId/listings" element={<EventListingsMobile />} />
              <Route path="events/:eventId/listings/:listingId/" element={<ListingDetailsMobile />} />
              <Route path="events/:eventId/listings/:listingId/filters" element={<MarketFiltersMobile />} />
            </Route>
            <Route element={<AdminFrame />}>
              <Route element={<SettingsFrame />}>
                <Route path="/settings" element={<Navigate to="/settings/pricing" />} />
                <Route path="/settings/pricing" element={<PricingSettings />} />
                <Route path="/settings/profile" element={<ProfileSettings />} />
                <Route path="/settings/notifications" element={<NotificationSettings />} />
                <Route path="/settings/appearance" element={<AppearanceSettings />} />
              </Route>
            </Route>
          </Route>
          <Route path="/qbo/auth" element={<QuickBooksAuth />} />
          <Route path="/account-disabled" element={<AccountDisabled />} />
        </Route>
      </Route>
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/qbo/callback" element={<OAuthPopup />} />
      <Route
        path="*"
        element={<ErrorPage title="Nothing here" content="You navigated somewhere unexpected. If you believe this to be an error with the pricer, please contact support." />}
      />
    </Route>
  </>
);

const dtiOpuRoutes = (
  <>
    <Route element={<Auth0ProviderWithNavigate />}>
      <Route element={<WrapperProviders />}>
        <Route element={<AuthGuard />}>
          <Route element={<TenantGuard />}>
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/waiting-room" element={<WaitingRoom />} />
            <Route path="/account-disabled" element={<AccountDisabled />} />
            <Route
              path="*"
              element={<ErrorPage title="Nothing here" content="You navigated somewhere unexpected. If you believe this to be an error with the pricer, please contact support." />}
            />
            <Route element={<DtiOpuGuard />}>
              <Route path="/" element={<Sales />} />
            </Route>
          </Route>
        </Route>
        <Route path="/auth/callback" element={<AuthCallback />} />
      </Route>
    </Route>
  </>
);

export const dtiOpuRouter = createBrowserRouter(createRoutesFromElements(dtiOpuRoutes));
export const router = createBrowserRouter(createRoutesFromElements(routes));
