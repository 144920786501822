import { Box, Center, Divider, Flex, Group, Skeleton, Text, Tooltip } from '@mantine/core';
import { MutableRefObject, useMemo } from 'react';
import classes from './SeasonEvents.EventCard.module.css';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import ChevronForwardIcon from '../../components/icons/ChevronForward';
import { BNLabel } from '../../components/Label/Label';
import ListIcon from '../../components/icons/List';
import ConfirmationNumberIcon from '../../components/icons/ConfirmationNumber';
import InfoIcon from '../../components/icons/Info';
import ErrorIcon from '../../components/icons/Error';
import { BNButton } from '../../components/Button/Button';
import OpenNewIcon from '../../components/icons/OpenNew';
import TrackChangesIcon from '../../components/icons/TrackChanges';
import { MarketListingProvider, useMarketListings } from '../MarketListings/MarketListings.hooks';
import { useDisclosure } from '@mantine/hooks';
import { BNLoadingState } from '../../components/Loading/LoadingState';
import { BarkerCoreModelsInventoryEvent, BarkerCoreModelsInventoryListing } from '../../api';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { marketplaceListingsAtom, seatingChartFiltersAtom, showEventMappingAtom } from '../../data/atoms';
import { formatCurrency, formatDate } from '../../utils/formatters';
import { DateFormats } from '../../utils/globals';
import millify from 'millify';
import { useSeasonPricer } from '../../data/SeasonsPricerState';
import { focusAtom } from 'jotai-optics';
import { ScopeProvider } from 'jotai-scope';
import { EventCardForm } from './SeasonEvents.EventCard.Form';
import { SeasonEventCardProvider, useSeasonEventCard } from './SeasonEvents.EventCard.hooks';
import ArrowBackIcon from '../../components/icons/ArrowBack';
import { MarketListingsList } from '../MarketListings/MarketListings.List';

type EventCardProps = {
  event: BarkerCoreModelsInventoryEvent;
  listingId: string;
  variant: string;
  uniqueQtyInfo?: { qty: string; seats: string } | null;
  isLoading: boolean;
  targetRef?: MutableRefObject<HTMLDivElement | null>;
};

export function EventCard({ variant = 'default', uniqueQtyInfo, isLoading, event, listingId, targetRef }: EventCardProps) {
  const { rulesAtom, listingsAtom, eventMappings, marketplaceId, errorEventId } = useSeasonPricer('rulesAtom', 'listingsAtom', 'eventMappings', 'marketplaceId', 'errorEventId');
  const rules = useAtomValue(rulesAtom);
  const rule = useAtomValue(useMemo(() => focusAtom(rulesAtom, (x) => x.prop(event?.eventId)), [event, rulesAtom]));
  const [listing, setListing] = useAtom<BarkerCoreModelsInventoryListing | null>(useMemo(() => focusAtom(listingsAtom, (x) => x.prop(listingId)), [listingId, listingsAtom]));
  const mapping = eventMappings.find((x) => x.eventId === event?.eventId && x.marketplaceId === marketplaceId) ?? null;
  const hideDefault = variant === 'nomapping' || variant === 'autopriced';

  if (!event) {
    return null;
  }

  // Null check to stops hot reloading from breaking site due to downstream record indexing.
  if (Object.keys(rules).length === 0 || !rule) {
    return null;
  }

  return (
    <ScopeProvider atoms={[marketplaceListingsAtom, seatingChartFiltersAtom]}>
      <MarketListingProvider overrideEvent={event} overrideMarketplaceEvent={mapping} disabled={variant !== 'default' || isLoading}>
        <SeasonEventCardProvider eventId={event.eventId} listing={listing} setListing={setListing}>
          <Box className={classes.listingCardWrapper} fz="xs" ref={event.eventId === errorEventId ? (targetRef as MutableRefObject<HTMLDivElement>) : undefined}>
            <Flex direction="column" gap={0}>
              <Box>
                <TitleBar
                  unitPrice={listing?.unitPrice}
                  isAutoPriced={rule?.isAutoPriced && listing?.pricerStatusId === 'None'}
                  date={formatDate(event.localDateTime, DateFormats.Extended)}
                  opponent={event.enrichmentData?.seasonDetails?.awayTeam || ''}
                  isLoading={isLoading}
                />
                <Divider color="var(--colors-divider)" ml={12} />
              </Box>
              {/* When loading, use dots loader to fill main area of the card */}
              {isLoading && <BNLoadingState />}

              {/* When there is a note/asterisk, display that content here */}
              {uniqueQtyInfo && !isLoading && <AsteriskNote qty={uniqueQtyInfo.qty} seats={uniqueQtyInfo.seats} />}

              {/* If the event is already autopriced, display that card state */}
              {variant === 'autopriced' && !isLoading && <AutoPricedContentNote listingId={listingId} />}

              {/* If the event is not mapped, display that card state */}
              {variant === 'nomapping' && !isLoading && <NoMappingCardContent event={event} />}

              {/* If the event is mapped, and not already autopriced, display the default state */}
              {!hideDefault && !isLoading && <DefaultCardContent event={event} listingId={listingId} />}
            </Flex>
          </Box>
        </SeasonEventCardProvider>
      </MarketListingProvider>
    </ScopeProvider>
  );
}

type DefaultCardContentProps = {
  event: BarkerCoreModelsInventoryEvent;
  listingId: string;
};
const DefaultCardContent = ({ event, listingId }: DefaultCardContentProps) => {
  const [showMarketListings, { toggle: toggleMarketListings }] = useDisclosure();

  return (
    <>
      <EventCardForm eventId={event.eventId} listingId={listingId} />
      <Divider color="var(--colors-divider)" />
      <Flex direction="column" className={showMarketListings ? 'season-market-open' : ''}>
        <Flex onClick={toggleMarketListings} className={classes.cardBottom} align="center" justify="space-between" px="xs" h={36}>
          <MarketResultsSummary showMarketListings={showMarketListings} />
        </Flex>
        <Flex className="reveal">{showMarketListings && <MarketListingsList />}</Flex>
      </Flex>
    </>
  );
};

type TitleBarProps = {
  date: string;
  opponent: string;
  isLoading: boolean;
  isAutoPriced: boolean;
  unitPrice?: number;
};
const TitleBar = ({ date, opponent, isLoading, isAutoPriced, unitPrice }: TitleBarProps) => {
  const { calculatedTargetPrice } = useSeasonEventCard('calculatedTargetPrice');
  return (
    <Flex align="center" justify="space-between" px="xs" h={54} pos="relative">
      {!isLoading ? (
        <Flex direction="column">
          <Text fw="600" c="var(--colors-brandcolor-6)">
            {date}
          </Text>
          <Text fw="600">{opponent}</Text>
        </Flex>
      ) : (
        <Flex direction="column" gap="xs">
          <Skeleton height={8} width={160} />
          <Skeleton height={8} width={120} />
        </Flex>
      )}
      {!isLoading ? (
        <Flex pos="absolute" right={12} w="auto" h="100%" align="center" gap={12}>
          <Box pos="relative" style={{ zIndex: 100 }}>
            {/* Pending Price Display - normal */}
            {isAutoPriced && (
              <Tooltip withArrow withinPortal label="Pending Price Change">
                <Flex className={classes.pendingDisplay} align="center" justify="end" h={46} top={-5} left={0} w="auto" p={6} pl={10} pr={25} bg="var(--colors-paper)">
                  <Flex direction="column" align="start">
                    <Text c="var(--colors-gray-5)">Pending</Text>
                    {formatCurrency(calculatedTargetPrice)}
                    <Flex pos="absolute" right={0} top={0} bottom={0} h="100%" align="end" pb={6} pr={6} c="var(--colors-gray-5)">
                      <ArrowBackIcon size={16} />
                    </Flex>
                  </Flex>
                </Flex>
              </Tooltip>
            )}
            {/* Pending Price Display - drastic change */}
            {/* <Tooltip withArrow withinPortal label="Pending Drastic Price Change">
              <Flex className={classes.pendingWarning} align="center" justify="end" h={46} top={-5} left={0} w="auto" p={6} pl={10} pr={25} bg="var(--colors-paper)">
                <Center p={0} m={0} pos="absolute" left={1} top={1} bottom={1} h={44} w={28} bg="var(--colors-yellow-warning)" c="var(--colors-paper)">
                  <WarningIcon />
                </Center>
                <Flex direction="column" align="start">
                  <Text c="var(--colors-gray-5)">Pending</Text>
                  {formatCurrency(999.99)}
                  <Flex pos="absolute" right={0} top={0} bottom={0} h="100%" align="end" pb={6} pr={6} c="var(--colors-gray-5)">
                    <ArrowBackIcon size={16} />
                  </Flex>
                </Flex>
              </Flex>
            </Tooltip> */}
          </Box>
          <Flex direction="column">
            <Text c="var(--colors-gray-5)" ta="right">
              List Price
            </Text>
            <Text fw={600} ta="right">
              {formatCurrency(unitPrice || 0)}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex direction="column" gap="xs" align="end">
          <Skeleton height={8} width={60} />
          <Skeleton height={8} width={52} />
        </Flex>
      )}
    </Flex>
  );
};

type AsteriskNoteProps = {
  qty: string;
  seats: string;
};
const AsteriskNote = ({ qty, seats }: AsteriskNoteProps) => (
  <Flex mt="xs" mx="xs" px={4} py={2} align="center" gap={8} bg="var(--colors-yellow-0)" style={{ borderRadius: 3 }}>
    <Tooltip label="This event has a unique seat quantity." withArrow>
      <Center style={{ borderRadius: 100 }} h={24} w={24}>
        <InfoIcon color="var(--colors-yellow-warning)" />
      </Center>
    </Tooltip>
    <Group gap={4}>
      <Text c="var(--colors-gray-5)">Qty</Text> {qty}
    </Group>
    <Divider color="var(--colors-divider)" orientation="vertical" my={6} />
    <Group gap={4}>
      <Text c="var(--colors-gray-5)">Seats</Text> {seats}
    </Group>
  </Flex>
);

type AutoPricedContentProps = {
  listingId: string;
};
const AutoPricedContentNote = ({ listingId }: AutoPricedContentProps) => {
  const { updateRule, setListing } = useSeasonEventCard('updateRule', 'setListing');

  return (
    <>
      <Group justify="space-between" px="xs">
        <Flex py="xs" align="center" gap={8} c="var(--colors-gray-5)">
          <Center bg="var(--colors-gray-1)" style={{ borderRadius: 100 }} h={24} w={24}>
            <AutoPriceIcon size={18} color="var(--colors-gray-5)" />
          </Center>
          Auto-Priced
        </Flex>
        <BNButton
          size="compact-xs"
          variant="default"
          onClick={() => {
            setListing((x) => ({ ...x, pricerStatusId: 'None' }));
            updateRule((x) => ({ ...x, isAutoPriced: true }));
          }}
        >
          Override Auto-Pricing
        </BNButton>
      </Group>
      <Divider color="var(--colors-divider)" />
      <BNButton
        onClick={() => {
          window.open(`/?listingId=${listingId}`, '_blank');
        }}
        className={classes.bottomButton}
        fullWidth
        fz="xs"
        size="sm"
        variant="subtle"
        rightSection={<OpenNewIcon />}
      >
        Open Listing in a New Tab
      </BNButton>
    </>
  );
};

const NoMappingCardContent = ({ event }: { event?: BarkerCoreModelsInventoryEvent }) => {
  const { setEventMappingEvent } = useSeasonPricer('setEventMappingEvent');
  const setShowEventMapping = useSetAtom(showEventMappingAtom);
  return (
    <Group justify="space-between" px="xs">
      <Flex py="xs" align="center" gap={8}>
        <Center bg="var(--colors-gray-1)" style={{ borderRadius: 100 }} h={24} w={24}>
          <ErrorIcon color="var(--colors-red-error)" />
        </Center>
        No Event Mapping Found
      </Flex>
      <BNButton
        size="compact-xs"
        variant="default"
        onClick={() => {
          setEventMappingEvent(event);
          setShowEventMapping(true);
        }}
      >
        Update Mapping
      </BNButton>
    </Group>
  );
};

type MarketResultsSummaryProps = {
  showMarketListings: boolean;
};

const MarketResultsSummary = ({ showMarketListings }: MarketResultsSummaryProps) => {
  const { summaryTotals, isLoading } = useMarketListings('summaryTotals', 'isLoading');
  const { targetPrice } = useSeasonEventCard('targetPrice');

  return (
    <>
      <Flex w="33.3%" style={{ flex: 1 }} align="center" justify="flex-start">
        <Tooltip label="Events tooltip" withArrow>
          <Box>
            <BNLabel
              className={classes.label}
              leftIcon={<ListIcon color="var(--colors-gray-5)" size={20} />}
              text={
                isLoading ? (
                  <Skeleton height={8} width={40} />
                ) : (
                  <span>
                    {summaryTotals?.totalFilteredListings !== summaryTotals.totalListings ? (
                      <span style={{ color: 'var(--colors-gray-5)', whiteSpace: 'nowrap' }}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            color: 'var(--colors-paperReverse)',
                          }}
                        >
                          {millify(summaryTotals?.totalFilteredListings)}
                        </span>
                        {' / '}
                        {millify(summaryTotals?.totalListings)}
                      </span>
                    ) : (
                      <span>{millify(summaryTotals.totalListings)}</span>
                    )}
                  </span>
                )
              }
            />
          </Box>
        </Tooltip>
      </Flex>
      <Flex w="33.3%" style={{ flex: 1 }} align="center" justify="center">
        <Tooltip label="Events tooltip" withArrow>
          <Box>
            <BNLabel
              className={classes.label}
              leftIcon={<ConfirmationNumberIcon color="var(--colors-gray-5)" size={20} />}
              text={
                isLoading ? (
                  <Skeleton height={8} width={40} />
                ) : (
                  <span>
                    {summaryTotals?.totalTickets !== summaryTotals.totalFilteredTickets ? (
                      <span style={{ color: 'var(--colors-gray-5)', whiteSpace: 'nowrap' }}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            color: 'var(--colors-paperReverse)',
                          }}
                        >
                          {millify(summaryTotals?.totalFilteredTickets)}
                        </span>
                        {' / '}
                        {millify(summaryTotals?.totalTickets)}
                      </span>
                    ) : (
                      <span>{millify(summaryTotals?.totalTickets)}</span>
                    )}
                  </span>
                )
              }
            />
          </Box>
        </Tooltip>
      </Flex>
      <Flex gap="xs" w="33.3%" align="center" justify="flex-end" style={{ flex: 1 }}>
        <Tooltip label="Target comparable tooltip" withArrow>
          <Box>
            <BNLabel
              className={classes.label}
              leftIcon={<TrackChangesIcon color="var(--colors-gray-5)" size={20} />}
              text={
                isLoading ? (
                  <Skeleton height={8} width={40} />
                ) : (
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: 'var(--colors-paperReverse)',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {formatCurrency(targetPrice)}
                  </span>
                )
              }
            />
          </Box>
        </Tooltip>
        <Box c="var(--colors-gray-5)" style={{ transform: showMarketListings ? 'rotate(270deg) translateY(6px) translateX(1px)' : 'rotate(90deg)' }}>
          <ChevronForwardIcon size={14} />
        </Box>
      </Flex>
    </>
  );
};
