import { AdminContentHeader } from '../hoc/Admin/AdminContentHeader';
import { useParams } from 'react-router-dom';
import {
  BarkerCoreEnumsUserRole,
  getGetApiTenantsTenantIdInvitesQueryKey,
  getGetApiTenantsTenantIdUsersQueryKey,
  useDeleteApiTenantsTenantIdInvitesInviteId,
  useDeleteApiTenantsTenantIdUsersUserId,
  useGetApiTenantsTenantIdInvites,
  useGetApiTenantsTenantIdUsers,
  usePostApiTenantsTenantIdInvites,
} from '../api';
import { BNButton } from '../components/Button/Button';
import { Badge, Group, Modal, Stack, Table, Text, Tooltip } from '@mantine/core';
import classes from './Companies.tsx.module.css';
import { useMemo } from 'react';
import { openConfirmModal } from '@mantine/modals';
import { useGlobalState } from '../data/GlobalState';
import { useQueryClient } from '@tanstack/react-query';
import { useDisclosure } from '@mantine/hooks';
import { BNTextInput } from '../components/TextInput/TextInput';
import { BNSelect } from '../components/Select/Select';
import { useForm } from '@mantine/form';

export function IsUserHidden(email: string, roleId: BarkerCoreEnumsUserRole) {
  return email.includes('brokernerds.com') || roleId === 'GlobalAdmin';
}

export default function Users() {
  const { tenantId } = useParams();
  const queryClient = useQueryClient();
  const { currentUser } = useGlobalState('currentUser');
  const { data: users } = useGetApiTenantsTenantIdUsers(tenantId ?? '', {
    query: {
      enabled: !!tenantId,
      select: (data) => data.data,
    },
  });

  const { data: invites } = useGetApiTenantsTenantIdInvites(tenantId ?? '', {
    query: {
      enabled: !!tenantId,
      select: (data) => data.data,
    },
  });

  const { mutateAsync: deleteUser, isPending: isDeletingUser } = useDeleteApiTenantsTenantIdUsersUserId();

  const { mutateAsync: deleteInvite, isPending: isDeletingInvite } = useDeleteApiTenantsTenantIdInvitesInviteId();

  const rows = useMemo(
    () =>
      (users ?? [])
        .map((u) => ({ email: u.email, principalId: u.principalId, name: `${u.givenName} ${u.familyName}`.trim(), roleId: u.roleId, inviteId: null as string | null }))
        .concat((invites ?? []).map((i) => ({ email: i.email, principalId: i.inviteId, name: '', roleId: i.roleId, inviteId: i.inviteId })))
        .filter((u) => !IsUserHidden(u.email, u.roleId))
        ?.sort((a, b) => a.email.localeCompare(b.email))
        .map((user) => {
          const isDeleteDisabled =
            !currentUser ||
            user.email === currentUser?.email ||
            (currentUser.roleId !== 'TenantAdmin' && currentUser.roleId !== 'GlobalAdmin') ||
            IsUserHidden(user.email, user.roleId);

          return (
            <tr key={user.principalId} style={{ position: 'relative' }}>
              <td>{user.email}</td>
              {user.inviteId ? (
                <td>
                  <Badge color="green">INVITED</Badge>
                </td>
              ) : (
                <td>{user.name}</td>
              )}
              <td>{user.roleId}</td>
              <td style={{ textAlign: 'center' }}>
                <Tooltip label="Insufficient permissions" disabled={!isDeleteDisabled}>
                  <BNButton
                    size="xs"
                    variant="outline"
                    color="red"
                    disabled={isDeleteDisabled}
                    onClick={() => {
                      if (user.inviteId) {
                        openConfirmModal({
                          title: 'Delete Invitation',
                          children: <Text size="sm">Are you sure you want to delete the invitation to {user.email}?</Text>,
                          labels: { confirm: 'Confirm', cancel: 'Cancel' },
                          confirmProps: { className: 'confirmButton', variant: 'filled', color: 'red', size: 'sm', loading: isDeletingUser },
                          cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm', disabled: isDeletingUser },
                          closeButtonProps: { size: 'md' },
                          onConfirm: async () => {
                            await deleteInvite({ tenantId: tenantId ?? '', inviteId: user.inviteId ?? '' });
                            await queryClient.invalidateQueries({ queryKey: getGetApiTenantsTenantIdInvitesQueryKey(tenantId ?? '') });
                          },
                        });
                      } else {
                        openConfirmModal({
                          title: 'Delete User',
                          children: <Text size="sm">Are you sure you want to delete the user: {user.email}?</Text>,
                          labels: { confirm: 'Confirm', cancel: 'Cancel' },
                          confirmProps: { className: 'confirmButton', variant: 'filled', color: 'red', size: 'sm', loading: isDeletingUser },
                          cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm', disabled: isDeletingUser },
                          closeButtonProps: { size: 'md' },
                          onConfirm: async () => {
                            await deleteUser({ tenantId: tenantId ?? '', userId: user.principalId });
                            await queryClient.invalidateQueries({ queryKey: getGetApiTenantsTenantIdUsersQueryKey(tenantId ?? '') });
                          },
                        });
                      }
                    }}
                  >
                    Delete
                  </BNButton>
                </Tooltip>
              </td>
            </tr>
          );
        }),
    [users, currentUser, isDeletingUser, deleteUser, tenantId, queryClient, invites, deleteInvite],
  );

  const [opened, handlers] = useDisclosure(false);

  if (!users || !tenantId || !currentUser) {
    return null;
  }

  return (
    <>
      <AdminContentHeader
        sticky
        title="Users"
        rightSection={
          <Tooltip label="Insufficient permissions" disabled={currentUser.roleId === 'TenantAdmin' || currentUser.roleId === 'GlobalAdmin'}>
            <BNButton size="xs" variant="default" onClick={handlers.open} disabled={currentUser.roleId !== 'TenantAdmin' && currentUser.roleId !== 'GlobalAdmin'}>
              Add New
            </BNButton>
          </Tooltip>
        }
      />
      <Table verticalSpacing="md" className={classes.adminTable} width="100%">
        <thead>
          <tr>
            <th style={{ paddingLeft: 10 }}>Email</th>
            <th style={{ paddingLeft: 10 }}>Name</th>
            <th style={{ paddingLeft: 10 }}>Role</th>
            <th style={{ textAlign: 'center' }}>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <InviteUserModal opened={opened} onClose={handlers.close} tenantId={tenantId} />
    </>
  );
}

function InviteUserModal({ opened, onClose, tenantId }: { opened: boolean; onClose: () => void; tenantId: string }) {
  const { mutateAsync: inviteUser, isPending: isInviting } = usePostApiTenantsTenantIdInvites();
  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      email: '',
      givenName: '',
      familyName: '',
      roleId: BarkerCoreEnumsUserRole.User,
    },
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.email) {
        errors.email = 'Email is required';
      }

      if (!values.email.includes('@')) {
        errors.email = 'Invalid email';
      }

      if (!values.givenName) {
        errors.givenName = 'First name is required';
      }

      if (!values.familyName) {
        errors.familyName = 'Last name is required';
      }

      return errors;
    },
  });

  function handleFormClose() {
    form.reset();
    onClose();
  }

  return (
    <Modal opened={opened} onClose={handleFormClose} title="Invite User" withCloseButton>
      <form
        onSubmit={form.onSubmit(async (values) => {
          await inviteUser({ tenantId, data: values });
          await queryClient.invalidateQueries({ queryKey: getGetApiTenantsTenantIdInvitesQueryKey(tenantId) });
          handleFormClose();
        })}
      >
        <Stack>
          <BNTextInput label="Email" {...form.getInputProps('email')} />
          <Group>
            <BNTextInput label="First Name" {...form.getInputProps('givenName')} />
            <BNTextInput label="Last Name" {...form.getInputProps('familyName')} />
          </Group>
          <BNSelect label="Role" {...form.getInputProps('roleId')} withinPortal data={['TenantAdmin', 'User', 'ReadOnlyUser'] as BarkerCoreEnumsUserRole[]} />
          <Group style={{ justifyContent: 'right' }}>
            <BNButton onClick={handleFormClose} disabled={isInviting}>
              Cancel
            </BNButton>
            <BNButton variant="filled" color="green" type="submit" loading={isInviting}>
              Invite
            </BNButton>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
