import { Box, Button, Divider, Group, SimpleGrid, Text, Tooltip } from '@mantine/core';
import { useMemo } from 'react';
import ChevronForwardIcon from '../../components/icons/ChevronForward';
import FilterListIcon from '../../components/icons/FilterList';
import ResetIcon from '../../components/icons/Reset';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { useSeatingChart } from './SeatingChart.hooks';
import { BNSwitch } from '../../components/Switch/Switch';
import { OutliersPopoverSelect } from './Filters/Filters.Outliers';
import classes from './SeatingChart.module.css';
import { BNExclusionsMultiSelect } from '../../components/Select/ExclusionsMultiSelect';
import { BarkerCoreEnumsDeliveryMethod, BarkerCoreEnumsListingDisclosure } from '../../api';
import { SplitsButtonGroup } from './Filters/Filters.SplitsButtonGroup';

type AdvancedFiltersProps = {
  showAdvanced: boolean;
  setShowAdvanced: (value: boolean) => void;
};

export function AdvancedFilters({ showAdvanced, setShowAdvanced }: AdvancedFiltersProps) {
  const { resetAll, rule, isSeasonView, form } = useSeatingChart('resetAll', 'rule', 'isSeasonView', 'form');

  const areAdvancedApplied = useMemo(
    () =>
      (rule.filters.exclusions && rule.filters.exclusions.length > 0) ||
      (rule.filters.minPrice && rule.filters.minPrice > 0) ||
      (rule.filters.maxPrice && rule.filters.maxPrice > 0 && isFinite(rule.filters.maxPrice)),
    [rule.filters.exclusions, rule.filters.maxPrice, rule.filters.minPrice],
  );

  return (
    <Box pos="relative" className={classes.otherFilters}>
      <SimpleGrid cols={2} className={classes.filterGrid}>
        <Box>
          <BNTextInput
            size="xs"
            placeholder="&nbsp;"
            label="Rows"
            value={form.getInputProps('rows').value}
            onChange={form.getInputProps('rows').onChange}
            clearable
            clearableOnClick={() => {
              form.setFieldValue('rows', '');
            }}
          />
        </Box>
        <Box>
          <Box pos="relative">
            <Text size="xs" c="var(--colors-gray-5)" component="label" lh="1.25rem">
              Splits
            </Text>
            <Tooltip withArrow label="Automatically Set Splits">
              <Box pos="absolute" top={6} right={0}>
                <BNSwitch
                  // disabled={!!selectedTenantListingId}
                  checked={!!form.values.autoAdjustSplits}
                  onChange={(event) => {
                    form.setFieldValue('autoAdjustSplits', event.currentTarget.checked);
                  }}
                  size="xxs"
                  label="AUTO"
                />
              </Box>
            </Tooltip>
            <SplitsButtonGroup
              size="xs"
              onChange={(value) => {
                form.setFieldValue('splits', value);
              }}
              splits={form.values.splits}
              disabled={!!form.values.autoAdjustSplits}
            />
          </Box>
        </Box>
      </SimpleGrid>

      <SimpleGrid className={!showAdvanced ? `${classes.advancedFilterWrapper}` : `${classes.advancedFilterWrapper} ${classes.showAdvanced}`} cols={2}>
        {showAdvanced || isSeasonView ? (
          <>
            <BNExclusionsMultiSelect
              label="Exclusions"
              removeSelected
              value={((form.values.exclusions ?? []) as (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod)[]).concat(form.values.deliveryExclusions ?? [])}
              placeholder="None"
              position="top"
              onChange={(value: (BarkerCoreEnumsListingDisclosure | BarkerCoreEnumsDeliveryMethod | string)[]) => {
                const deliveryExclusions = value.filter((v) =>
                  Object.values(BarkerCoreEnumsDeliveryMethod).includes(v as unknown as BarkerCoreEnumsDeliveryMethod),
                ) as BarkerCoreEnumsDeliveryMethod[];
                const disclosureExclusions = value.filter((v) =>
                  Object.values(BarkerCoreEnumsListingDisclosure).includes(v as unknown as BarkerCoreEnumsListingDisclosure),
                ) as BarkerCoreEnumsListingDisclosure[];

                // setRule({ ...rule, filters: { ...rule.filters, exclusions: disclosureExclusions, deliveryExclusions } });
                form.setFieldValue('exclusions', disclosureExclusions);
                form.setFieldValue('deliveryExclusions', deliveryExclusions);
              }}
            />
            <OutliersPopoverSelect key={rule.ruleId} />
          </>
        ) : null}
      </SimpleGrid>

      <Box my={8} className={classes.filtersResetWrapper}>
        <Box className={classes.filterBar} onClick={() => setShowAdvanced(!showAdvanced)}>
          <FilterListIcon color="var(--colors-gray-5)" />
        </Box>
        <Button
          className={classes.filtersResetButton}
          size="xs"
          onClick={() => resetAll()}
          variant="white"
          pl={4}
          pr={8}
          leftSection={<ResetIcon size={20} color="var(--colors-gray-5)" />}
        >
          Reset All
        </Button>
        <Divider color="var(--colors-divider)" my="sm" className={classes.resetDivider} />
        <Button
          className={
            areAdvancedApplied && !showAdvanced ? `${classes.filtersResetButton} ${classes.advancedButton} active` : `${classes.filtersResetButton} ${classes.advancedButton}`
          }
          onClick={() => {
            setShowAdvanced(!showAdvanced);
          }}
          size="xs"
          variant="white"
          pl={8}
          pr={4}
          leftSection={
            <Box style={{ transform: showAdvanced ? 'rotate(-90deg)' : 'rotate(90deg)' }}>
              <ChevronForwardIcon size={14} color="var(--colors-gray-5)" />
            </Box>
          }
          data-pendo-name="Seating Chart Advanced Filters Button"
        >
          Advanced
        </Button>
      </Box>
    </Box>
  );
}
