import { useSeatingChart } from '../SeatingChart.hooks';
import { BNButton } from '../../../components/Button/Button';
import { Box, Divider, Flex, Group, Modal, Popover, SimpleGrid, Slider, Stack, Text, UnstyledButton } from '@mantine/core';
import { BNNumberInput } from '../../../components/NumberInput/NumberInput';
import { BNSelect } from '../../../components/Select/Select';
import { useDisclosure } from '@mantine/hooks';
import SelectArrowsIcon from '../../../components/icons/SelectArrows';
import ScatterPlotIcon from '../../../components/icons/ScatterPlot';
import { useAtomValue } from 'jotai';
import { marketplaceOutlierThresholdAtom } from '../../../data/atoms';
import { formatCurrency } from '../../../utils/formatters';
import { useForm } from '@mantine/form';
import { useRuleState } from '../../../data/RuleState';
import { useGlobalState } from '../../../data/GlobalState';
import mobileClasses from '../../../pages/MarketFilters.Mobile.module.css';
import classes from './Filters.Outliers.module.css';
import { useCallback } from 'react';

function PercentageUnderSlider({
  isMobile,
  size,
  value,
  onPercentageUnderChange,
}: {
  size: string;
  isMobile: boolean;
  value: number | undefined;
  onPercentageUnderChange: (value: number | '') => void;
}) {
  return (
    <Stack gap={0} justify="end" key="stack">
      <Text component="label" c="var(--colors-gray-5)" size={size} lh="1.25rem">
        Percentage Under
      </Text>
      <Group gap="xs" wrap="nowrap" pos="relative">
        <Slider
          size={isMobile ? 'lg' : 'sm'}
          min={0}
          max={20}
          step={1}
          label={null}
          w={isMobile ? '100%' : 220}
          className={classes.pctSlider}
          value={value}
          onChange={onPercentageUnderChange}
        />
        <BNNumberInput
          label=""
          size={size}
          min={0}
          max={20}
          hideControls
          fixedDecimalScale
          decimalScale={0}
          className={isMobile ? classes.numInputMobile : classes.numInput}
          selectOnFocus
          rightSection={
            <Text size={size} c="var(--colors-gray-4)">
              %
            </Text>
          }
          rightSectionWidth={isMobile ? 36 : 24}
          value={value}
          onChange={onPercentageUnderChange}
          readOnly={isMobile}
        />
      </Group>
    </Stack>
  );
}

export function OutliersPopoverSelect() {
  const { rule } = useSeatingChart('rule');
  const { form: ruleForm } = useRuleState('form');
  const { isMobile } = useGlobalState('isMobile');
  const [opened, { close: closeComponent, open: openComponent }] = useDisclosure(false);
  const outlierThreshold = useAtomValue(marketplaceOutlierThresholdAtom);
  const form = useForm({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      comparableIndex: rule.filters.outlierCriteria?.comparableIndex ? (rule.filters.outlierCriteria.comparableIndex + 1).toString() : '2',
      percentUnder: rule.filters.outlierCriteria?.percentUnder ? rule.filters.outlierCriteria.percentUnder * 100 : undefined,
    },
    validate: {
      comparableIndex: (value, values) => {
        if (values.percentUnder && values.percentUnder > 0 && value === '0') {
          return 'You must select a position to compare to.';
        }
        return null;
      },
      percentUnder: (value) => {
        if (value === undefined) {
          return 'You must enter a percentage.';
        }
        return null;
      },
    },
    transformValues: (values) => ({
      comparableIndex: !values.comparableIndex ? 1 : parseInt(values.comparableIndex) - 1,
      percentUnder: values.percentUnder ? values.percentUnder / 100 : undefined,
    }),
  });

  const { setFieldValue: setRuleFormFieldValue } = ruleForm;

  const onClosingPopover = () => {
    if (form.values.percentUnder === undefined) {
      // @ts-ignore - This  definitely needs to be null not undefined or dirty check will fail.
      ruleForm.setFieldValue('outlierCriteria', null);
    }
    closeComponent();
  };

  const onPercentageUnderChange = useCallback(
    (value: number | '') => {
      form.getInputProps('percentUnder').onChange(value);
      if (form.values.comparableIndex !== '0') {
        setRuleFormFieldValue(
          'outlierCriteria',
          form.values.comparableIndex
            ? {
                comparableIndex: parseInt(form.values.comparableIndex) - 1,
                percentUnder: (value || 0) / 100,
              }
            : undefined,
        );
      }
    },
    [form, setRuleFormFieldValue],
  );

  const onComparableIndexChange = (value: string | null) => {
    if (!value) {
      form.setFieldValue('percentUnder', 0);
    }
    form.setFieldValue('comparableIndex', value ?? '');
    if (form.values.percentUnder !== undefined) {
      ruleForm.setFieldValue(
        'outlierCriteria',
        form.values.comparableIndex
          ? {
              comparableIndex: value ? parseInt(value) - 1 : 0,
              percentUnder: (form.values.percentUnder || 0) / 100,
            }
          : undefined,
      );
    }
  };

  const onResetClick = () => {
    form.setValues({
      comparableIndex: '2',
      percentUnder: undefined,
    });
    ruleForm.setFieldValue('outlierCriteria', undefined);
    closeComponent();
  };

  const submit = form.onSubmit((values) => {
    ruleForm.setFieldValue(
      'outlierCriteria',
      values.comparableIndex
        ? {
            percentUnder: values.percentUnder ?? 0,
            comparableIndex: values.comparableIndex,
          }
        : undefined,
    );
    closeComponent();
  });

  const buttonLabelText = !rule.filters.outlierCriteria
    ? 'None'
    : `>${(rule.filters.outlierCriteria.percentUnder * 100).toFixed(0)}% under ${rule.filters.outlierCriteria.comparableIndex + 1}${
        rule.filters.outlierCriteria.comparableIndex === 0
          ? 'st'
          : rule.filters.outlierCriteria.comparableIndex === 1
            ? 'nd'
            : rule.filters.outlierCriteria.comparableIndex === 2
              ? 'rd'
              : 'th'
      } position`;

  const descriptiveText = !rule.filters.outlierCriteria ? (
    <Text>No results will be marked as outliers. Select a listing position & percentage to get started.</Text>
  ) : (
    <Text>
      Results under <strong>{`${formatCurrency(outlierThreshold)}`}</strong> will be marked as outliers. (based on the current market)
    </Text>
  );

  const size = isMobile ? 'sm' : 'xs';

  function PositionSelect() {
    return (
      <BNSelect
        data={[
          { value: '2', label: '2nd Position' },
          { value: '3', label: '3rd Position' },
          { value: '4', label: '4th Position' },
          { value: '5', label: '5th Position' },
          { value: '6', label: '6th Position' },
        ]}
        label="Compare to"
        size={size}
        placeholder="Select a position"
        value={form.values.comparableIndex}
        onChange={onComparableIndexChange}
        className={classes.positionSelect}
      />
    );
  }

  if (isMobile) {
    return (
      <Box>
        <Text component="label" c="var(--colors-gray-5)" fz={14}>
          Outliers
        </Text>
        <UnstyledButton
          onClick={() => openComponent()}
          fz="sm"
          c="var(--colors-paperReverse)"
          fw={500}
          variant="default"
          w="100%"
          h={36}
          style={{ border: '1px solid var(--colors-gray-3)', borderRadius: 6 }}
        >
          <Flex w="100%" align="center" justify="space-between" pl="sm" pr={8}>
            <Flex gap={4} style={{ overflow: 'hidden' }}>
              <Text fz="sm">{buttonLabelText}</Text>
            </Flex>
            <SelectArrowsIcon color="var(--colors-gray-4)" size={18} />
          </Flex>
        </UnstyledButton>
        <Modal
          opened={opened}
          onClose={closeComponent}
          closeButtonProps={{ size: 'md' }}
          withCloseButton={false}
          className={mobileClasses.bottomSheetModal}
          title="Outliers"
          transitionProps={{ transition: 'slide-up' }}
          withinPortal
        >
          <>
            <Flex justify="space-between" align="center" p={16} style={{ borderBottom: '1px dotted var(--colors-divider)', zIndex: 1 }}>
              <BNButton size="compact-sm" px={0} py={0} h={28} w={64} fz="md" fw={500} variant="subtle" onClick={onResetClick}>
                Reset
              </BNButton>
              <Text fw={600} fz="md">
                Outliers
              </Text>
              <BNButton
                variant="subtle"
                c="var(--colors-green-7)"
                fz="md"
                size="compact-sm"
                px={0}
                py={0}
                h={28}
                w={64}
                onClick={() => {
                  submit();
                }}
              >
                Done
              </BNButton>
            </Flex>
            <Box p="md" pb="xl">
              <Stack gap="xl">
                <SimpleGrid>
                  <PositionSelect />
                  <PercentageUnderSlider size={size} isMobile={isMobile} value={form.values.percentUnder} onPercentageUnderChange={onPercentageUnderChange} />
                </SimpleGrid>
                <Flex p="xs" bg="var(--colors-paperHover)" wrap="nowrap" align="center" gap={8} style={{ borderRadius: 3 }}>
                  <Flex align="center" style={{ flexShrink: 0 }}>
                    <ScatterPlotIcon size={24} color="var(--colors-gray-5)" />
                  </Flex>
                  <Text fz={12} component="div" c="var(--colors-gray-6)">
                    {descriptiveText}
                  </Text>
                </Flex>
              </Stack>
            </Box>
          </>
        </Modal>
      </Box>
    );
  }

  return (
    <Stack gap="0" h="100%" display="block" style={{ justifyContent: 'center' }}>
      <Text component="label" size={size} c="gray.5" lh="1.25rem">
        Outliers
      </Text>
      <Popover withArrow opened={opened} onClose={onClosingPopover} closeOnClickOutside width={320}>
        <Popover.Target>
          <BNButton
            variant="default"
            fullWidth
            size={size}
            rightSection={<SelectArrowsIcon color="var(--colors-gray-4)" />}
            onClick={openComponent}
            fw={500}
            c="var(--colors-gray-9)"
            px="xs"
            className={classes.outliersButton}
            data-pendo-name="Outliers Filter Button"
          >
            {buttonLabelText}
          </BNButton>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack gap="sm">
            <SimpleGrid cols={2}>
              <PositionSelect />
              <PercentageUnderSlider size={size} isMobile={isMobile} value={form.values.percentUnder} onPercentageUnderChange={onPercentageUnderChange} />
            </SimpleGrid>
            <Flex p={6} bg="var(--colors-paperHover)" wrap="nowrap" align="center" gap={8} style={{ borderRadius: 3 }}>
              <Flex align="center" style={{ flexShrink: 0 }}>
                <ScatterPlotIcon size={18} color="var(--colors-gray-5)" />
              </Flex>
              <Text fz={11} c="var(--colors-gray-6)">
                {descriptiveText}
              </Text>
            </Flex>
            <Divider color="var(--colors-divider)" />
            <SimpleGrid cols={2} spacing="sm">
              <BNButton variant="default" size="xs" fullWidth onClick={onResetClick}>
                Reset
              </BNButton>
              <BNButton variant="filled" color="gray" size="xs" fullWidth onClick={() => submit()}>
                Done
              </BNButton>
            </SimpleGrid>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </Stack>
  );
}
