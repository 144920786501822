import classes from './SupportForm.tsx.module.css';
import { Box, Center, Container, Divider, Group, Text, Textarea } from '@mantine/core';
import {
  BarkerCoreEnumsMarketplace,
  BarkerCoreEnumsPointOfSale,
  BarkerCoreModelsInventoryEvent,
  BarkerCoreModelsMarketplacesEvent,
  BarkerCoreModelsMarketplacesListing,
  useGetApiSupportContactsSelf,
  usePostApiSupport,
  usePostApiSupportDTI,
  usePostApiSupportInventoryEvents,
  usePostApiSupportInventoryListings,
  usePostApiSupportMarketplacesMarketplaceIdEvents,
  usePostApiSupportMarketplacesMarketplaceIdListings,
  usePutApiSupportContactsSelfSendActivation,
} from '../../api';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { BNButton } from '../../components/Button/Button';
import { useForm } from '@mantine/form';
import { BNSelect } from '../../components/Select/Select';
import { useAtomValue } from 'jotai/index';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import { useState } from 'react';
import { BarkerEventListing, TicketmasterMetadata } from '../../types';
import OpenNewIcon from '../../components/icons/OpenNew';
import { formatDate } from '../../utils/formatters';
import { DateFormats } from '../../utils/globals';
import dayjs from 'dayjs';
import { useFlag } from '@unleash/proxy-client-react';
import { generateMarketplaceEventUrl } from '../../utils/urls';
import { auth } from '../../data/atoms.auth';
import { datadogRum } from '@datadog/browser-rum';
import { isDtiHosted } from '../../utils/whitelabel-consts';

export type SupportFormType = 'inventoryEvent' | 'inventoryListing' | 'marketplaceEvent' | 'marketplaceListing' | 'marketplaceMissingEvent' | 'other';

export type SupportFormPropsBase = {
  type: SupportFormType;
  onClose: () => void;
};

export type SupportFormPropsInventoryEvent = {
  type: 'inventoryEvent';
  pointOfSaleId: BarkerCoreEnumsPointOfSale;
  event: BarkerCoreModelsInventoryEvent;
} & SupportFormPropsBase;

export type SupportFormPropsInventoryListing = {
  type: 'inventoryListing';
  pointOfSaleId: BarkerCoreEnumsPointOfSale;
  listing: BarkerEventListing;
} & SupportFormPropsBase;

export type SupportFormPropsMarketplaceEvent = {
  type: 'marketplaceEvent';
  marketplaceId: BarkerCoreEnumsMarketplace;
  event: BarkerCoreModelsMarketplacesEvent;
} & SupportFormPropsBase;

export type SupportFormPropsMarketplaceListing = {
  type: 'marketplaceListing';
  marketplaceId: BarkerCoreEnumsMarketplace;
  event: BarkerCoreModelsMarketplacesEvent;
  listing: BarkerCoreModelsMarketplacesListing;
} & SupportFormPropsBase;

export type SupportFormPropsMarketplaceMissingEvent = {
  type: 'marketplaceMissingEvent';
  marketplaceId: BarkerCoreEnumsMarketplace;
  event: BarkerCoreModelsInventoryEvent;
  pointOfSaleId: BarkerCoreEnumsPointOfSale;
} & SupportFormPropsBase;

export type SupportFormPropsOther = {
  type: 'other';
} & SupportFormPropsBase;

export type SupportFormProps =
  | SupportFormPropsInventoryEvent
  | SupportFormPropsInventoryListing
  | SupportFormPropsMarketplaceEvent
  | SupportFormPropsMarketplaceListing
  | SupportFormPropsMarketplaceMissingEvent
  | SupportFormPropsOther;

const INVENTORY_EVENT_OPTIONS = ['Inventory missing', 'Event details incorrect', 'Event missing from marketplace', 'Other'];
const INVENTORY_LISTING_OPTIONS = ['Listing details incorrect', "Auto-pricer isn't repricing", 'Other'];
const MARKETPLACE_EVENT_OPTIONS = ['Event details incorrect', 'Seating chart is incorrect', 'Wrong event mapping', 'Listings missing', 'Other'];
const MARKETPLACE_LISTING_OPTIONS = ["Listing doesn't appear on retail site", 'Incorrect price', 'Other'];
const MARKETPLACE_MISSING_EVENT_OPTIONS = ['Event Missing From Marketplace'];
const SECONDARY_MARKETPLACE_OPTIONS = ['SeatGeek', 'Ticket Evolution', 'Ticketmaster'];

export function SupportForm(props: SupportFormProps) {
  const sessionId = datadogRum.getInternalContext()?.session_id;
  const apiToken = useAtomValue(auth.apiTokenAtom);

  let secondaryMarketplaceEventUrl = '';

  if (props.type === 'marketplaceEvent' || props.type === 'marketplaceListing') {
    const marketplaceEventProps = props as SupportFormPropsMarketplaceEvent;

    if (marketplaceEventProps.marketplaceId === 'Ticketmaster') {
      const metadata = marketplaceEventProps.event?.metadata ? (JSON.parse(marketplaceEventProps.event.metadata) as TicketmasterMetadata) : null;
      secondaryMarketplaceEventUrl = metadata?.TicketmasterId ? generateMarketplaceEventUrl(marketplaceEventProps.marketplaceId, metadata.TicketmasterId) : 'Unavailable';
    } else {
      secondaryMarketplaceEventUrl = generateMarketplaceEventUrl(marketplaceEventProps.marketplaceId, marketplaceEventProps.event?.eventId);
    }
  }

  const { data: contact } = useGetApiSupportContactsSelf({
    query: {
      enabled: !!apiToken,
      select: (data) => data.data,
    },
  });

  const form = useForm<{
    subject?: string;
    comments?: string;
    additionalInfo?: string;
    secondaryMarketplace?: string;
  }>({
    initialValues: {
      subject: props.type === 'marketplaceMissingEvent' ? MARKETPLACE_MISSING_EVENT_OPTIONS[0] : '',
      comments: '',
      additionalInfo: '',
      secondaryMarketplace: props.type === 'marketplaceMissingEvent' ? (props.marketplaceId === 'TicketEvolution' ? 'Ticket Evolution' : props.marketplaceId) : '',
    },
    validate: {
      subject: (value) => {
        if (!value) {
          return 'Subject is required';
        }
        return null;
      },
      comments: (value) => {
        if (!value) {
          return 'Comments are required';
        }
        return null;
      },
      additionalInfo: (value, values) => {
        if (!value && (values.subject === 'Seating chart is incorrect' || values.subject === 'Event Missing From Marketplace')) {
          return 'Primary Event Link is required';
        }
        return null;
      },
      secondaryMarketplace: (value, values) => {
        if (!value && values.subject === 'Event Missing From Marketplace') {
          return 'Secondary marketplace is required';
        }
        return null;
      },
    },
  });

  const { mutateAsync: submitSupport, isPending: isSubmitSupportLoading } = usePostApiSupport();
  const { mutateAsync: submitSupportInventoryEvent, isPending: isSubmitSupportInventoryEventLoading } = usePostApiSupportInventoryEvents();
  const { mutateAsync: submitSupportInventoryListing, isPending: isSubmitSupportInventoryListingsLoading } = usePostApiSupportInventoryListings();
  const { mutateAsync: submitSupportMarketplacesEvent, isPending: isSubmitSupportMarketplacesEventLoading } = usePostApiSupportMarketplacesMarketplaceIdEvents();
  const { mutateAsync: submitSupportMarketplacesListing, isPending: isSubmitSupportMarketplacesListingLoading } = usePostApiSupportMarketplacesMarketplaceIdListings();
  const { mutateAsync: submitSupportDti, isPending: isSubmitSupportDtiLoading } = usePostApiSupportDTI();

  const isLoading =
    isSubmitSupportLoading ||
    isSubmitSupportInventoryEventLoading ||
    isSubmitSupportInventoryListingsLoading ||
    isSubmitSupportMarketplacesEventLoading ||
    isSubmitSupportMarketplacesListingLoading ||
    isSubmitSupportDtiLoading;

  const isVerified = contact !== undefined && contact.active;

  const intentionalError = useFlag('intentional-error');

  if (intentionalError) {
    throw new Error('Intentional error');
  }

  // DTI users using the "other" support form don't need to be verified
  if (!isVerified && !(props.type === 'other' && isDtiHosted)) {
    return (
      <Container w={280} mih={240} style={{ alignItems: 'center', display: 'flex' }}>
        <NotActivatedState />
      </Container>
    );
  }

  return (
    <Container p={0}>
      <FormHeader {...props} />
      <form
        onReset={() => {
          form.setValues({});
          props.onClose();
        }}
        onSubmit={form.onSubmit((values) => {
          if (props.type === 'other' && !isDtiHosted) {
            submitSupport({
              data: {
                subject: values.subject ?? '',
                body: values.comments ?? '',
                replaySessionId: sessionId,
              },
            }).then(() => props.onClose());
            return;
          }

          if (props.type === 'other' && isDtiHosted) {
            submitSupportDti({
              data: {
                subject: values.subject ?? '',
                body: values.comments ?? '',
              },
            }).then(() => props.onClose());
            return;
          }

          if (props.type === 'inventoryEvent') {
            submitSupportInventoryEvent({
              data: {
                subject: values.subject ?? '',
                body: values.additionalInfo ? `Primary Event Link: ${values.additionalInfo}<br />${values.comments ?? ''}` : values.comments ?? '',
                replaySessionId: sessionId,
                pointOfSaleId: props.pointOfSaleId,
                event: {
                  ...props.event,
                  // @ts-ignore
                  localDateTime: dayjs(props.event?.localDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                },
              },
            }).then(() => props.onClose());
            return;
          }

          if (props.type === 'inventoryListing') {
            submitSupportInventoryListing({
              data: {
                subject: values.subject ?? '',
                body: values.comments ?? '',
                replaySessionId: sessionId,
                pointOfSaleId: props.pointOfSaleId,
                event: {
                  ...props.listing.event,
                  // @ts-ignore
                  localDateTime: dayjs(props.listing?.event?.localDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                },
                listing: props.listing,
              },
            }).then(() => props.onClose());
            return;
          }

          if (props.type === 'marketplaceEvent') {
            submitSupportMarketplacesEvent({
              marketplaceId: props.marketplaceId,
              data: {
                subject: values.subject ?? '',
                body: values.additionalInfo
                  ? `Primary Event Link: ${values.additionalInfo}<br />Secondary Event Link: ${secondaryMarketplaceEventUrl}<br />${values.comments ?? ''}`
                  : `Secondary Event Link: ${secondaryMarketplaceEventUrl}<br />${values.comments ?? ''}`,
                replaySessionId: sessionId,
                event: {
                  ...props.event,
                  // @ts-ignore
                  dateTimeLocal: dayjs(props.event?.dateTimeLocal).format('YYYY-MM-DDTHH:mm:ss'),
                },
              },
            }).then(() => props.onClose());
            return;
          }

          if (props.type === 'marketplaceMissingEvent') {
            submitSupportInventoryEvent({
              data: {
                subject: values.subject ?? '',
                body: values.additionalInfo
                  ? `Primary Event Link: ${values.additionalInfo ?? ''}<br />Secondary Marketplace: ${values.secondaryMarketplace}<br />${values.comments ?? ''}`
                  : '',
                replaySessionId: sessionId,
                pointOfSaleId: props.pointOfSaleId,
                event: {
                  ...props.event,
                  // @ts-ignore
                  localDateTime: dayjs(props.event?.localDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                },
              },
            }).then(() => props.onClose());
            return;
          }

          if (props.type === 'marketplaceListing') {
            submitSupportMarketplacesListing({
              marketplaceId: props.marketplaceId,
              data: {
                subject: values.subject ?? '',
                body: `Secondary Event Link: ${secondaryMarketplaceEventUrl}<br />${values.comments ?? ''}`,
                replaySessionId: sessionId,
                event: {
                  ...props.event,
                  // @ts-ignore
                  dateTimeLocal: dayjs(props.event?.dateTimeLocal).format('YYYY-MM-DDTHH:mm:ss'),
                },
                listing: props.listing,
              },
            }).then(() => props.onClose());
          }
        })}
      >
        <Box m={-16} p="md">
          {props.type === 'other' && <BNTextInput size="xs" label="Subject *" placeholder="I need help with..." {...form.getInputProps('subject')} />}
          {props.type !== 'other' && (
            <BNSelect
              label="Subject *"
              size="xs"
              aria-label="Support Subject"
              placeholder="I need help with..."
              data={
                props.type === 'inventoryEvent'
                  ? INVENTORY_EVENT_OPTIONS
                  : props.type === 'inventoryListing'
                    ? INVENTORY_LISTING_OPTIONS
                    : props.type === 'marketplaceEvent'
                      ? MARKETPLACE_EVENT_OPTIONS
                      : props.type === 'marketplaceListing'
                        ? MARKETPLACE_LISTING_OPTIONS
                        : props.type === 'marketplaceMissingEvent'
                          ? MARKETPLACE_MISSING_EVENT_OPTIONS
                          : []
              }
              {...form.getInputProps('subject')}
            />
          )}
          {props.type === 'marketplaceMissingEvent' && (
            <BNSelect
              label="Secondary Marketplace *"
              size="xs"
              mt="xs"
              aria-label="Support Secondary Marketplace"
              placeholder="Secondary Marketplace"
              data={SECONDARY_MARKETPLACE_OPTIONS}
              {...form.getInputProps('secondaryMarketplace')}
            />
          )}

          {(props.type === 'marketplaceEvent' || props.type === 'inventoryEvent' || props.type === 'marketplaceMissingEvent') &&
            (form.values.subject === 'Seating chart is incorrect' || form.values.subject === 'Event Missing From Marketplace') && (
              <BNTextInput size="xs" label="Primary Event Link *" mt="xs" placeholder="https://ticketmaster.com/..." {...form.getInputProps('additionalInfo')} />
            )}
          <Textarea
            size="xs"
            label="Comments *"
            aria-label="Support comments"
            mt="xs"
            styles={{
              input: {
                minHeight: 80,
              },
            }}
            placeholder="Please describe your issue..."
            minRows={4}
            {...form.getInputProps('comments')}
          />
          <Group mt="md" gap={12} wrap="nowrap" justify="right">
            <BNButton size="xs" fullWidth variant="default" type="reset" px="xs" maw={90}>
              Cancel
            </BNButton>
            <BNButton size="xs" variant="filled" color="green" fullWidth type="submit" loading={isLoading} maw={120} px="xs" disabled={!form.isValid()}>
              Submit Request
            </BNButton>
          </Group>
        </Box>
      </form>
    </Container>
  );
}

export function FormHeader(props: SupportFormProps): JSX.Element {
  if (props.type === 'other') {
    return (
      <>
        <Group gap="xs" justify="space-between" h={40} mt={-10}>
          <Text fw={600} size="xs">
            Support Request
          </Text>
          {!isDtiHosted && (
            <Group c="var(--colors-brandcolor-5)" gap={4}>
              <Text component="a" href="https://support.brokernerds.com" rel="noreferrer" target="_blank" fw={500} c="var(--colors-brandcolor-5)" size="xs" td="underline">
                Knowledge Base
              </Text>
              <OpenNewIcon color="var(--colors-gray-5)" size={16} />
            </Group>
          )}
        </Group>
        <Divider mx={-16} mb="xs" color="var(--colors-darkPaper)" />
      </>
    );
  }

  if (props.type === 'inventoryEvent') {
    return (
      <>
        <Box bg="var(--colors-darkPaper)" m={-16} mb="xs" p="xs">
          <Box bg="var(--colors-paper)" style={{ border: '1px solid var(--colors-selectedBorder)', borderRadius: 4 }}>
            <Box py="sm" px="md">
              <Text fw={700} size="xs" tt="uppercase" c="var(--colors-brandcolor-5)">
                {formatDate(props.event.localDateTime, DateFormats.Standard)}
              </Text>
              <Text fw={600} size="sm" lh={1.3}>
                {props.event.name}
              </Text>
              <Text size="xs" c="var(--colors-gray-5)">
                {`${props.event.venue?.name} · ${props.event.venue?.city}, ${props.event.venue?.state}`}
              </Text>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  if (props.type === 'inventoryListing') {
    return (
      <>
        <Box bg="var(--colors-darkPaper)" m={-16} mb="xs" p="xs">
          <Box bg="var(--colors-paper)" style={{ border: '1px solid var(--colors-selectedBorder)', borderRadius: 4 }}>
            <Box py="sm" px="md">
              <Text fw={700} size="xs" tt="uppercase" c="var(--colors-brandcolor-5)">
                {formatDate(props.listing.event.localDateTime, DateFormats.Standard)}
              </Text>
              <Text fw={600} size="sm" lh={1.3}>
                {props.listing.event.name}
              </Text>
              <Text size="xs" c="var(--colors-gray-5)">
                {`${props.listing.event.venue?.name} · ${props.listing.event.venue?.city}, ${props.listing.event.venue?.state}`}
              </Text>
            </Box>
            <Divider color="var(--colors-divider)" variant="dotted" ml={16} />
            <Group py="xs" px="md" wrap="nowrap">
              <Box w="50%">
                <Text size="xs" c="var(--colors-gray-5)">
                  Section
                </Text>
                <Text size="sm" truncate mt={-2} tt="capitalize">
                  {props.listing.section}
                </Text>
              </Box>
              <Box w="25%">
                <Text size="xs" c="var(--colors-gray-5)">
                  Row
                </Text>
                <Text size="sm" truncate mt={-2}>
                  {props.listing.row}
                </Text>
              </Box>
              <Box w="25%">
                <Text size="xs" c="var(--colors-gray-5)">
                  Qty
                </Text>
                <Text size="sm" truncate mt={-2}>
                  {props.listing.quantityRemaining}
                </Text>
              </Box>
            </Group>
          </Box>
        </Box>
      </>
    );
  }

  if (props.type === 'marketplaceEvent') {
    return (
      <>
        <Box bg="var(--colors-darkPaper)" m={-16} mb="xs" p="xs">
          <Box bg="var(--colors-paper)" style={{ border: '1px solid var(--colors-selectedBorder)', borderRadius: 4 }}>
            <Box py="sm" px="md">
              <Text fw={700} size="xs" tt="uppercase" c="var(--colors-brandcolor-5)">
                {formatDate(props.event.dateTimeLocal, DateFormats.Standard)}
              </Text>
              <Text fw={600} size="sm" lh={1.3}>
                {props.event.name}
              </Text>
              <Text size="xs" c="var(--colors-gray-5)">
                {`${props.event.venueName} · ${props.event.city}, ${props.event.region}`}
              </Text>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  if (props.type === 'marketplaceListing') {
    return (
      <>
        <Box bg="var(--colors-darkPaper)" m={-16} mb="xs" p="xs">
          <Box bg="var(--colors-paper)" style={{ border: '1px solid var(--colors-selectedBorder)', borderRadius: 4 }}>
            <Box py="sm" px="md">
              <Text fw={700} size="xs" tt="uppercase" c="var(--colors-brandcolor-5)">
                {formatDate(props.event.dateTimeLocal, DateFormats.Standard)}
              </Text>
              <Text fw={600} size="sm" lh={1.3}>
                {props.event.name}
              </Text>
              <Text size="xs" c="var(--colors-gray-5)">
                {`${props.event.venueName} · ${props.event.city}, ${props.event.region}`}
              </Text>
            </Box>
            <Divider color="var(--colors-divider)" variant="dotted" ml={16} />
            <Group py="xs" px="md" wrap="nowrap">
              <Box w="50%">
                <Text size="xs" c="var(--colors-gray-5)">
                  Section
                </Text>
                <Text size="sm" truncate mt={-2} tt="capitalize">
                  {props.listing.section}
                </Text>
              </Box>
              <Box w="25%">
                <Text size="xs" c="var(--colors-gray-5)">
                  Row
                </Text>
                <Text size="sm" truncate mt={-2}>
                  {props.listing.row}
                </Text>
              </Box>
              <Box w="25%">
                <Text size="xs" c="var(--colors-gray-5)">
                  Qty
                </Text>
                <Text size="sm" truncate mt={-2}>
                  {props.listing.quantity}
                </Text>
              </Box>
            </Group>
          </Box>
        </Box>
      </>
    );
  }

  if (props.type === 'marketplaceMissingEvent') {
    return (
      <>
        <Box bg="var(--colors-darkPaper)" m={-16} mb="xs" p="xs">
          <Box bg="var(--colors-paper)" style={{ border: '1px solid var(--colors-selectedBorder)', borderRadius: 4 }}>
            <Box py="sm" px="md">
              <Text fw={700} size="xs" tt="uppercase" c="var(--colors-brandcolor-5)">
                {formatDate(props.event.localDateTime, DateFormats.Standard)}
              </Text>
              <Text fw={600} size="sm" lh={1.3}>
                {props.event.name}
              </Text>
              <Text size="xs" c="var(--colors-gray-5)">
                {`${props.event.venue.name} · ${props.event.venue.city}, ${props.event.venue.state}`}
              </Text>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  return <></>;
}

export function NotActivatedState() {
  const { mutateAsync: resendActivation } = usePutApiSupportContactsSelfSendActivation();
  const [isActivationSent, setActivationSent] = useState(false);

  return (
    <Container w="100%" h="100%">
      <Center className={classes.center} mt={-10}>
        <Box className={classes.iconWrapper}>
          <LiveHelpIcon color="var(--colors-iconFill)" size={28} />
        </Box>
        <Text size="md" fw={600} lh={1.3} mb="xs">
          Help Desk Account Not Activated
        </Text>
        <Group gap={0}>
          {isActivationSent ? (
            <Text size="xs" c="var(--colors-gray-5)">
              We&apos;ve sent you an email with a link to activate your account.
            </Text>
          ) : (
            <Text size="xs" c="var(--colors-gray-5)">
              <BNButton size="xs" variant="default" onClick={() => resendActivation().then(() => setActivationSent(true))}>
                Re-send Activation Email
              </BNButton>
            </Text>
          )}
        </Group>
      </Center>
    </Container>
  );
}
