import { ActionIcon, Avatar, Box, Flex, Skeleton, Text, Tooltip } from '@mantine/core';
import ChevronBackIcon from '../../../components/icons/ChevronBack';
import MenuOpenIcon from '../../../components/icons/MenuOpen';
import { ReactComponent as SeatGeekLogo } from '../../../img/sg.svg';
import tevo from '../../../img/tevo.png';
import tnet from '../../../img/tnet.jpeg';
import tm from '../../../img/tm.png';
import classes from './FiltersPreview.module.css';
import { useState } from 'react';

export function FiltersPreview() {
  // Dummy States
  const selectedMarketplace = 'seatgeek';
  const isLoading = true;
  const isMapped = true;

  const [showCollapsedFilters, setShowCollapsedFilters] = useState(false);
  function handleFiltersClick() {
    document.body.classList.toggle('showMarketFilters');
    setShowCollapsedFilters(!showCollapsedFilters);
  }

  return (
    <Box className={classes.filtersPreview} pos="relative">
      <Flex p="xs" pos="relative" className={classes.filtersPreviewDisplay}>
        <Flex h={40} align="center" c="var(--colors-gray-3)" className={classes.filtersChevron}>
          <ChevronBackIcon size={12} />
        </Flex>
        <Box maw="100%" className={classes.filtersContent}>
          <Flex gap={8} pr="xs" align="center">
            {selectedMarketplace === 'seatgeek' ? (
              <Avatar key="seatgeek" m={0} h={40} w={40} radius="xl" variant="outline" className={classes.avatar} color="var(--colors-darkPaper)">
                <ActionIcon>
                  <MenuOpenIcon color="var(--colors-gray-5)" size={24} />
                </ActionIcon>
                <SeatGeekLogo />
              </Avatar>
            ) : (
              <Avatar key="other" m={0} h={40} w={40} radius="xl" variant="outline" className={classes.avatar} color="var(--colors-darkPaper)">
                <ActionIcon>
                  <MenuOpenIcon color="var(--colors-gray-5)" size={24} />
                </ActionIcon>
                <img
                  className="mantine-Avatar-image"
                  height={36}
                  width={36}
                  src={selectedMarketplace === 'tevo' ? tevo : selectedMarketplace === 'tnet' ? tnet : tm}
                  alt="Selected Marketplace"
                />
              </Avatar>
            )}
            {isMapped ? (
              <Box flex={1} miw={0}>
                {isLoading ? (
                  <>
                    <Skeleton height={8} w={200} my={7} />
                    <Skeleton height={8} w={160} width="100%" my={7} />
                  </>
                ) : (
                  <>
                    <Text truncate lh={1.4} fw={600} fz={13}>
                      Event Name Will Appear Here
                    </Text>
                    <Text lh={1.4} c="var(--colors-gray-5)" truncate>
                      <Text component="span" c="var(--colors-brandcolor-6)">
                        Fri Mth 0, 2024 at 0:00pm
                      </Text>{' '}
                      · Venue Name · City, ST
                    </Text>
                  </>
                )}
              </Box>
            ) : (
              <Box flex={1} miw={0}>
                <>
                  <Text truncate lh={1.4} fw={600} fz={13}>
                    Marketplace data not found
                  </Text>
                  <Text lh={1.4} c="var(--colors-gray-5)" truncate>
                    Check back later or update the event mapping
                  </Text>
                </>
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
      <Tooltip label={showCollapsedFilters ? 'Hide Filters Column' : 'Show Filters Column'} position="top-start" offset={{ mainAxis: -4, crossAxis: -10 }} arrowOffset={40}>
        <Box pos="absolute" top={0} left={0} bottom={0} right={0} onClick={handleFiltersClick} />
      </Tooltip>
    </Box>
  );
}
