import { Text, useComputedColorScheme } from '@mantine/core';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BarkerCoreEnumsMarketplace,
  BarkerCoreModelsAdministrativeTenantPricerSettings,
  getGetApiInventoryEventsEventIdMappingsQueryKey,
  getGetApiMarketplacesMarketplaceIdBoostsEnabledQueryKey,
  getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryKey,
  getGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartQueryKey,
  getGetApiPricingSuggestionsQueryKey,
  postApiMarketplacesMarketplaceIdEventsEventIdBoost,
  useGetApiInventoryEventsEventIdMappings,
  useGetApiMarketplacesMarketplaceIdBoostsEnabled,
  useGetApiMarketplacesMarketplaceIdEventsEventId,
  useGetApiMarketplacesMarketplaceIdEventsEventIdListings,
  useGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChart,
  useGetApiMarketplacesMarketplaceIdEventsEventIdSectionAliases,
  useGetApiPricingSuggestions,
} from '../../api';
import {
  eventMarketplaceDefaultsAtom,
  initialPageLoadAtom,
  ruleStateAtom,
  selectedEventAtom,
  selectedListingRuleIdAtom,
  selectedMarketplaceEventAtom,
  selectedMarketplaceIdAtom,
  selectedSectionAtom,
  selectedTenantListingIdAtom,
  showEventMappingAtom,
} from '../../data/atoms';
import * as R from 'remeda';
import { useDidUpdate, useDisclosure, useToggle } from '@mantine/hooks';
import yasml from '@thirtytech/yasml';
import { queryClient } from '../../data/api-config';
import { useRuleState } from '../../data/RuleState';
import { matchesRowFilter } from '../MarketListings/marketListingsFilter';
import { RecordSectionCounts, SectionCounts } from '../../types';
import { openConfirmModal } from '@mantine/modals';
import { useGlobalState } from '../../data/GlobalState';
import { useEffectDeep } from '../../utils/use-memo-weak';
import { useFlag } from '@unleash/proxy-client-react';

function SeatingChartState({ isSeasonView }: { isSeasonView?: boolean }) {
  const computedColorScheme = useComputedColorScheme();
  const isDark = computedColorScheme === 'dark';
  const selectedSection = useAtomValue(selectedSectionAtom);
  const selectedEvent = useAtomValue(selectedEventAtom);
  const selectedTenantId = selectedEvent?.tenantId;
  const selectedTenantListingId = useAtomValue(selectedTenantListingIdAtom);
  const selectedListingRuleId = useAtomValue(selectedListingRuleIdAtom);
  const setSelectedMarketplaceId = useSetAtom(selectedMarketplaceIdAtom);
  const setSelectedMarketplaceEvent = useSetAtom(selectedMarketplaceEventAtom);
  const setEventMarketplaceDefaults = useSetAtom(eventMarketplaceDefaultsAtom);
  const [rule, setRule] = useAtom(ruleStateAtom);
  const [showEventMapping, setShowEventMapping] = useAtom(showEventMappingAtom);
  const { isLoading: isRuleLoading, resetForm, form, submit, isLoadingLive: isRuleLoadingLive } = useRuleState('isLoading', 'resetForm', 'form', 'submit', 'isLoadingLive');
  const [isEditMarketplaceLinksDialogOpen, _editMarketplaceLinksDialogHandler] = useDisclosure(false);
  // Disclsure handler is not stable object
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editMarketplaceLinksDialogHandler = useMemo(() => _editMarketplaceLinksDialogHandler, []);
  const [bypassCache, setBypassCache] = useState(false);
  const isInitialPageLoad = useAtomValue(initialPageLoadAtom);
  const { tenants, isMobile } = useGlobalState('tenants', 'isMobile');
  const hideSectionSuggestionsFlag = useFlag('hide-section-suggestions');
  const selectedTenant = tenants?.find((t) => t.tenantId === selectedTenantId);
  const settings = selectedTenant?.settings?.pricerSettings ?? ({} as BarkerCoreModelsAdministrativeTenantPricerSettings);

  const {
    data: eventMappings,
    isFetching: isMappingsLoading,
    refetch: refetchEventMappings,
  } = useGetApiInventoryEventsEventIdMappings(selectedEvent?.eventId!, {
    query: {
      enabled: !!selectedEvent?.eventId,
      staleTime: 5 * 60 * 1000, // 5 Mins
      queryKey: [...getGetApiInventoryEventsEventIdMappingsQueryKey(selectedEvent?.eventId!), selectedEvent?.tenantId],
      select(data) {
        return data.data;
      },
    },
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  const selectedMarketplaceId = rule.marketplaceId;

  const mapping = useMemo(() => eventMappings?.find((m) => m.marketplaceId === selectedMarketplaceId), [eventMappings, selectedMarketplaceId]);

  const fetchUpdatedSeatingChart = useCallback(() => {
    setBypassCache(true);
  }, []);

  const {
    data: seatingChartSvgDetails,
    isFetching: isSeatingChartFetching,
    isError: isSeatingChartError,
  } = useGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChart(
    selectedMarketplaceId,
    mapping?.marketplaceEventId!,
    {
      darkMode: isDark,
      bypassCache,
    },
    {
      query: {
        ...(isMobile && { staleTime: 5 * 60 * 1000 }), // 5 Mins
        enabled: !!(selectedEvent?.tenantId && selectedMarketplaceId && mapping?.marketplaceEventId),
      },
      axios: {
        headers: {
          'x-tenant-id': selectedEvent?.tenantId,
        },
      },
    },
  );

  useEffect(() => {
    if (seatingChartSvgDetails) {
      if (bypassCache) {
        // eslint-disable-next-line no-param-reassign
        seatingChartSvgDetails.data.chartSvgUrl = `${seatingChartSvgDetails.data.chartSvgUrl}?${Math.random().toString(36).substring(7)}`;

        queryClient.setQueryData(
          [
            ...getGetApiMarketplacesMarketplaceIdEventsEventIdSeatingChartQueryKey(selectedMarketplaceId, mapping?.marketplaceEventId!, {
              darkMode: isDark,
              bypassCache: false,
            }),
          ],
          seatingChartSvgDetails,
        );

        setBypassCache(false);
      }
    }
  }, [seatingChartSvgDetails, bypassCache, isDark, mapping?.marketplaceEventId, selectedMarketplaceId]);

  const { data: marketplaceEventDetails, isFetching: isEventLoading } = useGetApiMarketplacesMarketplaceIdEventsEventId(selectedMarketplaceId, mapping?.marketplaceEventId!, {
    query: {
      enabled: !isMappingsLoading && !!mapping?.marketplaceEventId,
      staleTime: 5 * 60 * 1000, // 5 Mins
      select(data) {
        return data.data;
      },
    },
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
  });

  useDidUpdate(() => {
    setIsBoosted(marketplaceEventDetails?.isAutoPriceBoosted ?? false);
  }, [marketplaceEventDetails?.isAutoPriceBoosted]);

  const filterSectionsToUniqueGroupCount = useMemo(() => {
    const seatingChartSections = seatingChartSvgDetails?.data.sections || [];
    if (selectedMarketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster && seatingChartSections.length > 0) {
      const uniqueSections =
        form.values.sectionIds?.map((x) => seatingChartSections.find((y) => y.sections?.map((z) => z.toLowerCase()).includes(x.toLowerCase()))?.name).filter((x) => x) || [];
      return R.uniq(uniqueSections).length;
    }

    return form.values.sectionIds?.length || 0;
  }, [form.values.sectionIds, seatingChartSvgDetails?.data.sections, selectedMarketplaceId]);

  const selectedMarketplaceEvent = useMemo(
    () => ({
      marketplaceId: selectedMarketplaceId,
      marketplaceEventId: mapping?.marketplaceEventId,
      marketplaceEvent: marketplaceEventDetails,
      svgDetails: seatingChartSvgDetails?.data,
      isUserMapped: mapping?.isUserMapped || false,
      sections: seatingChartSvgDetails?.data.sections || [],
    }),
    [selectedMarketplaceId, mapping?.marketplaceEventId, mapping?.isUserMapped, marketplaceEventDetails, seatingChartSvgDetails],
  );

  useEffectDeep(() => {
    setSelectedMarketplaceEvent(selectedMarketplaceEvent);
  }, [selectedMarketplaceEvent, setSelectedMarketplaceEvent]);

  const { data: marketplaceListingResult, isFetching: isMarketplaceListingsLoading } = useGetApiMarketplacesMarketplaceIdEventsEventIdListings(
    selectedMarketplaceId,
    mapping?.marketplaceEventId!,
    {
      bypassCache: false,
    },
    {
      query: {
        ...(isMobile && { staleTime: 5 * 60 * 1000 }), // 5 Mins
        enabled: !!(selectedEvent?.tenantId && selectedMarketplaceId && mapping?.marketplaceEventId),
        queryKey: [
          ...getGetApiMarketplacesMarketplaceIdEventsEventIdListingsQueryKey(selectedMarketplaceId, mapping?.marketplaceEventId!, { bypassCache: false }),
          selectedEvent?.tenantId,
        ],
      },
      axios: {
        headers: {
          'x-tenant-id': selectedEvent?.tenantId,
        },
      },
    },
  );

  const { data: sectionAliases } = useGetApiMarketplacesMarketplaceIdEventsEventIdSectionAliases(
    selectedMarketplaceId,
    mapping?.marketplaceEventId!,
    {
      section: selectedSection,
    },
    {
      query: {
        // Used for local testing. Remove when feature is done
        // ****************************
        // enabled: false,
        // initialData: {
        //   data: ['A'],
        // } as AxiosResponse,
        // ****************************
        ...(isMobile && { staleTime: 5 * 60 * 1000 }), // 5 Mins
        enabled: !isMarketplaceListingsLoading && !!mapping?.marketplaceEventId && !!selectedSection,
        select(data) {
          return data.data;
        },
      },
      axios: {
        headers: {
          'x-tenant-id': selectedEvent?.tenantId,
        },
      },
    },
  );

  const { data: suggestionData } = useGetApiPricingSuggestions(
    {
      marketplaceId: rule?.marketplaceId,
      section: selectedSection,
      venueId: selectedEvent?.venue?.venueId,
    },
    {
      query: {
        queryKey: [
          ...getGetApiPricingSuggestionsQueryKey({
            marketplaceId: rule?.marketplaceId,
            section: selectedSection,
            venueId: selectedEvent?.venue?.venueId,
          }),
          selectedEvent?.tenantId,
        ],
        enabled:
          !hideSectionSuggestionsFlag &&
          !isRuleLoadingLive &&
          !isRuleLoading &&
          !!selectedSection &&
          !!selectedEvent?.venue?.venueId &&
          rule?.filters?.sectionIds?.length === 0 &&
          !(settings?.disableSuggestions ?? false),
      },
      axios: {
        headers: {
          'x-tenant-id': selectedEvent?.tenantId,
        },
      },
    },
  );

  // Hack: State sync issue with suggestions. Need to clear them till rule is loaded and next render gets the value.
  const [suggestions, setSuggestions] = useState<{ sectionIds: string[] }>({ sectionIds: [] });
  useEffect(() => {
    if (isRuleLoading || isRuleLoadingLive) {
      setSuggestions({ sectionIds: [] });
    } else {
      setSuggestions({ sectionIds: suggestionData?.data?.sectionIds || [] });
    }
  }, [suggestionData?.data, isRuleLoading, isRuleLoadingLive]);

  const [allSectionsInSeatingChart, updateAllSectionsInSeatingChart] = useState<string[]>([]);

  const marketSectionsWithCounts = useMemo(
    () =>
      R.pipe(
        marketplaceListingResult?.data?.listings || [],
        R.reduce((acc, { section, quantity }) => {
          // If the section is not in acc, initialize it with default values.
          if (!acc[section]) {
            acc[section] = { sectionId: section.toLowerCase(), section, count: 0, totalQuantity: 0 };
          }
          // Increment the count and totalQuantity for the section.
          acc[section].count += 1;
          acc[section].totalQuantity += quantity;

          return acc;
        }, {} as RecordSectionCounts),
        R.values, // Convert the object to an array of values.
        R.uniqBy((x) => x.section), // Remove duplicates.
        R.sort((a, b) => a.section.localeCompare(b.section, undefined, { numeric: true, sensitivity: 'base' })),
      ),
    [marketplaceListingResult?.data?.listings],
  );

  const sectionsWithListings = useMemo(() => marketSectionsWithCounts.map((x) => x.sectionId), [marketSectionsWithCounts]);

  const allSectionsInSeatingChartWithCounts = useMemo(
    () =>
      allSectionsInSeatingChart
        .flatMap((x) => {
          if (selectedMarketplaceId === BarkerCoreEnumsMarketplace.Ticketmaster) {
            return x.split(',') ?? [];
          }
          return x;
        })
        .map((x) => {
          const found = marketSectionsWithCounts.find((y) => y.section.toLowerCase() === x.toLowerCase());
          const count = found ? found.count : 0;
          const totalQuantity = found ? found.totalQuantity : 0;
          return {
            sectionId: x.toLowerCase(),
            section: x,
            count,
            totalQuantity,
          } as SectionCounts;
        })
        .sort((a, b) => a.section.localeCompare(b.section, undefined, { numeric: true, sensitivity: 'base' })),
    [allSectionsInSeatingChart, marketSectionsWithCounts, selectedMarketplaceId],
  );

  const ruleSectionsMissingCount = useMemo(() => {
    const knownSeatingChartSections = new Set(allSectionsInSeatingChart.map((x) => x.toLowerCase()));
    return (rule.filters?.sectionIds?.filter((x) => !knownSeatingChartSections.has(x.toLowerCase())) ?? []).length;
  }, [allSectionsInSeatingChart, rule.filters?.sectionIds]);

  const allMissingSections = useMemo(() => {
    const sections = new Set(allSectionsInSeatingChart.map((x) => x.toLowerCase()));
    const missingMarketSections = marketSectionsWithCounts.filter((x) => !sections.has(x.section.toLowerCase()));
    const missingRuleSections = rule.filters?.sectionIds?.filter((x) => !sections.has(x.toLowerCase())) ?? [];
    return R.uniqBy(
      [
        ...missingMarketSections,
        ...missingRuleSections.map((x) => ({
          sectionId: x.toLowerCase(),
          section: x,
          count: 0,
          totalQuantity: 0,
        })),
      ] satisfies SectionCounts[],
      (x) => x.section,
    ).sort((a, b) => a.section.localeCompare(b.section, undefined, { numeric: true, sensitivity: 'base' }));
  }, [allSectionsInSeatingChart, marketSectionsWithCounts, rule.filters?.sectionIds]);

  const allSectionsWithCounts = useMemo(
    () =>
      R.uniqBy([...allSectionsInSeatingChartWithCounts, ...allMissingSections], (x) => x.section).sort((a, b) =>
        a.section.localeCompare(b.section, undefined, { numeric: true, sensitivity: 'base' }),
      ),
    [allMissingSections, allSectionsInSeatingChartWithCounts],
  );

  const [showUnmapped, setShowUnmapped] = useState(false);
  const [filter, setFilter] = useState<string | undefined>();
  const [filterQuery, setFilterQuery] = useState('');

  useDidUpdate(() => {
    setFilter('');
    setFilterQuery('');
    setShowUnmapped(false);
    updateAllSectionsInSeatingChart([]);
    if (showEventMapping && !isSeasonView) {
      setShowEventMapping(false);
    }
  }, [selectedEvent?.eventId]);

  const filteredSections = useMemo(() => {
    let matchingSections = [...allSectionsWithCounts];
    if (!filterQuery) {
      return matchingSections.sort((a, b) =>
        a.section.localeCompare(b.section, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      );
    }

    const andParts = filterQuery.split(/\s+/);

    for (const andPart of andParts) {
      const andSections: SectionCounts[] = [];
      const orParts = andPart.split(',');

      for (const orPart of orParts) {
        const rangeParts = orPart.split('-');

        if (
          rangeParts.length === 2 &&
          ((!isNaN(Number(rangeParts[0])) && !isNaN(Number(rangeParts[1]))) ||
            ([...rangeParts[0]].every((char) => char === rangeParts[0][0]) && [...rangeParts[1]].every((char) => char === rangeParts[1][0])))
        ) {
          // Number range
          andSections.push(...matchingSections.filter((section) => matchesRowFilter(section.section, [orPart])));
        } else if (orPart.length <= 3 && (!isNaN(Number(orPart)) || [...orPart].every((char) => char === orPart[0]))) {
          andSections.push(...matchingSections.filter((section) => matchesRowFilter(section.section, [orPart])));
        } else {
          // Text match
          andSections.push(...matchingSections.filter((section) => section.section.toLowerCase().includes(orPart.toLowerCase())));
        }
      }

      matchingSections = andSections;
    }

    return matchingSections.sort((a, b) =>
      a.section.localeCompare(b.section, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
  }, [allSectionsWithCounts, filterQuery]);

  const resetAll = useCallback(() => {
    resetForm();
  }, [resetForm]);

  const suggestedSectionsInSeatingChart = useMemo(() => {
    // Solves race condition when selected listing ruleId and rule hasn't been set yet from server.
    if ((selectedListingRuleId && selectedListingRuleId !== rule.ruleId) || (rule.filters?.sectionIds?.length ?? 0) > 0) {
      return { sectionIds: [] };
    }
    const _suggestions = structuredClone(suggestions) || { sectionIds: [], ruleId: rule.ruleId };
    if (allSectionsInSeatingChart.length === 0 || !_suggestions.sectionIds) {
      return suggestions || { sectionIds: [], ruleId: rule.ruleId };
    }
    _suggestions.sectionIds = _suggestions.sectionIds?.filter((x) => allSectionsInSeatingChart.includes(x)) || [];
    return _suggestions;
  }, [allSectionsInSeatingChart, rule.filters?.sectionIds?.length, rule.ruleId, selectedListingRuleId, suggestions]);

  const selectAllSections = useCallback(() => {
    setRule((x) => ({ ...x, filters: { ...x.filters, sectionIds: allSectionsInSeatingChart } }));
  }, [allSectionsInSeatingChart, setRule]);

  const isLoading = useMemo(
    () => isSeatingChartFetching || isMarketplaceListingsLoading || isMappingsLoading || isEventLoading || isRuleLoading || isInitialPageLoad,
    [isSeatingChartFetching, isMarketplaceListingsLoading, isMappingsLoading, isEventLoading, isRuleLoading, isInitialPageLoad],
  );

  const [forceSectionCheckList, toggleForceSectionChecklist] = useToggle();

  const [isBoosted, setIsBoosted] = useState(marketplaceEventDetails?.isAutoPriceBoosted ?? false);
  const { isSuccess: isBoostingAllowed } = useGetApiMarketplacesMarketplaceIdBoostsEnabled(selectedMarketplaceId, {
    axios: {
      headers: {
        'x-tenant-id': selectedEvent?.tenantId,
      },
    },
    query: {
      ...(isMobile && { staleTime: 5 * 60 * 1000 }), // 5 Mins
      queryKey: [...getGetApiMarketplacesMarketplaceIdBoostsEnabledQueryKey(selectedMarketplaceId), selectedEvent?.tenantId],
      enabled: !!selectedMarketplaceId && !!selectedEvent?.tenantId && selectedMarketplaceId !== BarkerCoreEnumsMarketplace.Ticketmaster,
    },
  });
  const boostConfirm = useCallback(() => {
    openConfirmModal({
      title: 'Confirm Event Boost',
      children: <Text size="sm">You can only boost a single event. This will replace any other events that are currently boosted.</Text>,
      labels: { confirm: 'Continue', cancel: 'Cancel' },
      confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
      cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
      closeButtonProps: { size: 'md' },
      async onConfirm() {
        await postApiMarketplacesMarketplaceIdEventsEventIdBoost(selectedMarketplaceId, mapping?.marketplaceEventId!, {
          headers: {
            'x-tenant-id': selectedEvent?.tenantId,
          },
        });
        setIsBoosted(true);
      },
    });
  }, [mapping?.marketplaceEventId, selectedEvent?.tenantId, selectedMarketplaceId]);

  const removeBoost = useCallback(() => {
    openConfirmModal({
      title: 'Stop Event Boost',
      children: <Text size="sm">This will stop boosting this event. Note you can only have one event boosted at a time.</Text>,
      labels: { confirm: 'Continue', cancel: 'Cancel' },
      confirmProps: { className: 'confirmButton', variant: 'filled', color: 'gray', size: 'sm' },
      cancelProps: { className: 'cancelButton', variant: 'default', size: 'sm' },
      closeButtonProps: { size: 'md' },
      onConfirm() {
        setIsBoosted(false);
      },
    });
  }, []);

  const availableMarketplaces = useMemo(() => {
    if (!selectedEvent) {
      return [];
    }

    if (!tenants) {
      return [];
    }

    const tenant = tenants.find((t) => t.tenantId === selectedEvent.tenantId);
    if (!tenant) {
      return [];
    }

    return tenant.marketplaceIds;
  }, [selectedEvent, tenants]);

  return {
    isLoading,
    selectAllSections,
    isSeatingChartError,
    resetAll,
    selectedMarketplaceEvent,
    sectionsWithListings,
    rule,
    eventMappings,
    showEventMapping,
    setShowEventMapping,
    refetchEventMappings,
    selectedMarketplaceId,
    selectedListingRuleId,
    setSelectedMarketplaceId,
    fetchUpdatedSeatingChart,
    isEditMarketplaceLinksDialogOpen,
    editMarketplaceLinksDialogHandler,
    forceSectionCheckList,
    toggleForceSectionChecklist,
    filterSectionsToUniqueGroupCount,
    updateAllSectionsInSeatingChart,
    allSectionsInSeatingChart,
    suggestions: suggestedSectionsInSeatingChart,
    setEventMarketplaceDefaults,
    setRule,
    marketSectionsWithCounts,
    filter,
    setFilter,
    filterQuery,
    setFilterQuery,
    filteredSections,
    allMissingSections,
    allSectionsInSeatingChartWithCounts,
    allSectionsWithCounts,
    ruleSectionsMissingCount,
    boostConfirm,
    removeBoost,
    isBoosted,
    isBoostingAllowed,
    showUnmapped,
    setShowUnmapped,
    selectedTenantId,
    selectedSection,
    selectedEvent,
    sectionAliases,
    isSeasonView,
    form,
    submit,
    availableMarketplaces,
    selectedTenantListingId,
  };
}

export const { Provider: SeatingChartProvider, useSelector: useSeatingChart } = yasml(SeatingChartState);
