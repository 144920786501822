import { useForm } from '@mantine/form';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { filtersDirtyAtom, initiallyAutoPricedAtom, ruleDirtyAtom, ruleStateAtom, selectedMarketplaceIdAtom } from '../../data/atoms';
import { BarkerCoreModelsPricingRule, BarkerCoreModelsPricingRuleBase, BarkerCoreModelsPricingSchedulePricerSettings, getApiPricingRulesRuleId } from '../../api';
import { useDidUpdate, useDisclosure, usePrevious } from '@mantine/hooks';
import yasml from '@thirtytech/yasml';
import { useRuleState } from '../../data/RuleState';
import { useDidUpdateDeep } from '../../utils/use-memo-weak';
import { seasons } from '../../data/atoms.seasons';
import { useSeasonPricer } from '../../data/SeasonsPricerState';
import { onNextInventoryItem, onPrevInventoryItem } from '../SeasonInventory/Inventory.hooks';

function NowPricingState() {
  const selectedLocation = useAtomValue(seasons.selectedLocationAtom);
  const initiallyAutoPriced = useAtomValue(initiallyAutoPricedAtom);
  const filtersDirty = useAtomValue(filtersDirtyAtom);
  const ruleDirty = useAtomValue(ruleDirtyAtom);
  const [rule, setRule] = useAtom(ruleStateAtom);
  const [isChangeConflict, setIsChangeConflict] = useState<BarkerCoreModelsPricingRule | null>();
  const selectedMarketplaceId = useAtomValue(selectedMarketplaceIdAtom);
  const { saveRules, isAnyRuleEnabled } = useSeasonPricer('saveRules', 'isAnyRuleEnabled');
  const { isLoading: ruleLoading, isLoadingLive: isRuleLoadingLive, resetForm, form: ruleForm, submit } = useRuleState('isLoading', 'isLoadingLive', 'resetForm', 'form', 'submit');

  const form = useForm<
    Pick<BarkerCoreModelsPricingRuleBase, 'numActive' | 'adjustmentValue' | 'adjustmentTypeId' | 'staggerByTypeId' | 'staggerByValue' | 'numComparables'> &
      Partial<BarkerCoreModelsPricingSchedulePricerSettings>
  >({
    validateInputOnChange: false,
    validateInputOnBlur: true,
    initialValues: {
      numActive: rule.numActive ?? 1,
      adjustmentValue: Number((rule.adjustmentValue || 0).toFixed(4)),
      adjustmentTypeId: rule.adjustmentTypeId || 'Amount',
      staggerByTypeId: rule.staggerByTypeId || 'Amount',
      staggerByValue: rule.staggerByValue || 0,
      numComparables: rule.numComparables || 1,
    },
    validate: {
      numComparables: (value) => (!value || value < 1 ? 'Number of comparables must be greater than 0' : null),
      numActive: (value) => (typeof value === 'number' && value < 1 ? 'Number of active tickets must be greater than 0' : null),
    },
  });
  const { values: formValues, isDirty: formIsDirty, reset: formReset } = form;
  const [manualLoading, setManualLoading] = useState(false);
  const isLoading = isRuleLoadingLive || manualLoading;
  const isFormReady = !!(selectedLocation && !ruleLoading);
  const isGroupedListing = (selectedLocation?.ruleCount || 0) > 1;
  const formWasDirty = usePrevious(formIsDirty());

  useDidUpdateDeep(
    () => {
      setRule((prev) => ({ ...prev, ...formValues }));
    },
    [formValues],
    [setRule],
  );

  const [saving, setSaving] = useState(false);

  useDidUpdate(() => {
    if (!formWasDirty) {
      formReset();
    }
  }, [formWasDirty]);

  const saveRuleAndPrice = form.onSubmit(() => _saveRuleAndPrice());

  const _saveRuleAndPrice = useCallback(async () => {
    setManualLoading(true);
    if (selectedLocation) {
      setSaving(true);

      try {
        const result = await saveRules();
        if (result) {
          resetForm();
        }
      } catch (error) {
        setSaving(false);
      }

      setManualLoading(false);
      setSaving(false);
    }
  }, [resetForm, saveRules, selectedLocation]);

  const [isAutoPriced, { toggle: toggleIsAutoPriced, close: disableIsAutoPriced }] = useDisclosure(false);
  const { toggleRemaining, resetAllRules } = useSeasonPricer('toggleRemaining', 'resetAllRules');

  const cancel = useCallback(() => {
    resetAllRules();
    toggleRemaining(false);
    disableIsAutoPriced();
    formReset();
    resetForm();
  }, [disableIsAutoPriced, formReset, resetAllRules, resetForm, toggleRemaining]);

  const nextListing = useCallback(async () => {
    document.dispatchEvent(onNextInventoryItem);
  }, []);
  const prevListing = useCallback(async () => {
    document.dispatchEvent(onPrevInventoryItem);
  }, []);

  const isNoSectionRule = useMemo(() => rule.isAutoPriced && rule?.filters?.sectionIds?.length === 0, [rule?.filters?.sectionIds?.length, rule.isAutoPriced]);

  const refreshRule = useCallback(async () => {
    const { data: updatedRule } = await getApiPricingRulesRuleId(rule.ruleId!, {
      headers: {
        'x-tenant-id': selectedLocation?.tenantId,
      },
    });
    setRule(updatedRule, { forceUpdate: true });
    setIsChangeConflict(null);
    resetForm();
  }, [resetForm, rule.ruleId, selectedLocation?.tenantId, setRule]);

  const [isDrasticChangeDialog, { close: closeDrasticChangeDialog }] = useDisclosure();
  const [isNoSectionDialog, { open: openNoSectionDialog, close: closeNoSectionDialog }] = useDisclosure(false);
  const [isFiltersNotApplied, { open: openApplyFiltersDialog, close: closeApplyFiltersDialog }] = useDisclosure(false);
  const onSave = form.onSubmit(() => {
    if (ruleForm.isDirty()) {
      openApplyFiltersDialog();
    } else if (isNoSectionRule) {
      openNoSectionDialog();
    } else {
      saveRuleAndPrice();
    }
  });

  return {
    form,
    selectedLocation,
    saveRuleAndPrice,
    isLoading,
    isGroupedListing,
    isFormReady,
    nextListing,
    prevListing,
    cancel,
    ruleDirty,
    initiallyAutoPriced,
    isChangeConflict,
    refreshRule,
    selectedMarketplaceId,
    isNoSectionRule,
    isDirty: ruleDirty || filtersDirty,
    filtersDirty,
    closeDrasticChangeDialog,
    closeNoSectionDialog,
    onSave,
    isNoSectionDialog,
    isDrasticChangeDialog,
    saving,
    ruleForm,
    submit,
    openApplyFiltersDialog,
    closeApplyFiltersDialog,
    isFiltersNotApplied,
    toggleIsAutoPriced,
    isAutoPriced,
    isAnyRuleEnabled,
  };
}

export const { Provider: NowPricingProvider, useSelector: useNowPricing } = yasml(NowPricingState);
