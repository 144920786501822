import { Accordion, Button, Divider, Flex, ScrollArea, SimpleGrid, Stack, Text, Tooltip } from '@mantine/core';
import { BNDatePicker } from '../../components/DatePicker/DatePicker';
import DateRangeIcon from '../../components/icons/DateRange';
import classes from './Search.StackedFilters.module.css';
import { BNTextInput } from '../../components/TextInput/TextInput';
import { BNSelect } from '../../components/Select/Select';
import ListIcon from '../../components/icons/List';
import { BNButton } from '../../components/Button/Button';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import VerticalAlignBottomIcon from '../../components/icons/VerticalAlignBottom';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import AutoPriceOffIcon from '../../components/icons/AutoPriceOff';
import { BNSwitch } from '../../components/Switch/Switch';
import { TagsFilter } from './Search.TagsFilter';
import { AntiTagsFilter } from './Search.AntiTagsFilter';
import dayjs from 'dayjs';
import { useGlobalState } from '../../data/GlobalState';
import { useMemo, useState } from 'react';
import { useFocusTrap } from '@mantine/hooks';
import { useSearchResults } from './SearchResults.hooks';
import { InventorySearchParams } from '../../types';
import { useFlag } from '@unleash/proxy-client-react';

export function StackedFilters() {
  const { form, search } = useSearchResults('form', 'search');
  // const [advancedFilters, setAdvancedFilters] = useState(
  //   form.values.listingId !== '' ||
  //     form.values.eventId !== '' ||
  //     (form.values.pricerStatuses && form.values.pricerStatuses.length > 0) ||
  //     form.values.daysSinceRepriced !== undefined ||
  //     form.values.daysSinceViewed !== undefined ||
  //     form.values.daysSinceLastSale !== undefined ||
  //     form.values.onlyNotBroadcasting !== undefined ||
  //     form.values.purchaseDateFrom !== undefined ||
  //     form.values.purchaseDateTo !== undefined ||
  //     form.values.tags?.length > 0 ||
  //     form.values.antiTags?.length > 0,
  // );

  const [showCustomPurchasedFrom, setShowCustomPurchasedFrom] = useState(
    form.values.purchaseDateFrom !== undefined &&
      form.values.purchaseDateTo !== undefined &&
      form.values.purchaseDateFrom.getTime() !== dayjs().startOf('day').toDate().getTime() &&
      form.values.purchaseDateFrom.getTime() !== dayjs().subtract(1, 'day').startOf('day').toDate().getTime() &&
      form.values.purchaseDateFrom.getTime() !== dayjs().subtract(7, 'day').startOf('day').toDate().getTime(),
  );

  const [showCustomPricedSince, setShowCustomPricedSince] = useState(
    form.values.daysSinceRepriced !== undefined &&
      form.values.daysSinceRepriced !== 1 &&
      form.values.daysSinceRepriced !== 7 &&
      form.values.daysSinceRepriced !== 30 &&
      form.values.daysSinceRepriced !== 9999,
  );

  const [showCustomSoldSince, setShowCustomSoldSince] = useState(
    form.values.daysSinceLastSale !== undefined &&
      form.values.daysSinceLastSale !== 1 &&
      form.values.daysSinceLastSale !== 7 &&
      form.values.daysSinceLastSale !== 30 &&
      form.values.daysSinceLastSale !== 9999,
  );

  const focusTrapRef = useFocusTrap();

  const { tenants, selectedTenantIds } = useGlobalState('tenants', 'selectedTenantIds');
  const singleSkyBoxTenantSelected = useMemo(
    () => selectedTenantIds?.length === 1 && tenants?.find((t) => t.tenantId === selectedTenantIds[0] && t.pointOfSaleId === 'SkyBox'),
    [selectedTenantIds, tenants],
  );

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      search(form.values);
    }
  }

  const hasFirstSeenFilterFlag =
    useFlag('filter-by-first-seen') || selectedTenantIds?.some((id) => id === 'deac90fd-337e-4ef5-9f11-954437fd9298' || id === '0e1461d2-6e23-4f29-8ebc-e100cdacb2ae');

  return (
    <>
      <Divider color="var(--colors-divider)" />
      <Flex direction="column" h="100%" flex={1} style={{ overflow: 'hidden' }}>
        <Flex h={32} align="center" px="sm" justify="space-between" style={{ borderBottom: '0.0625rem solid var(--colors-paperHover)' }}>
          <Text tt="uppercase" lts={0.5} c="var(--colors-gray-5)" fz={11}>
            Advanced Search
          </Text>
        </Flex>
        <Flex h="100%" flex={1} style={{ overflow: 'hidden' }} pos="relative">
          <ScrollArea.Autosize h="100%" w="100%" pos="absolute" inset={0}>
            <Accordion className={classes.accordion} multiple defaultValue={['Events', 'Listings', 'Invoices']}>
              {/* <Accordion.Item key="events" value="Events">
                <Accordion.Control icon={<StadiumIcon />}>
                  <Text fw={600}>Events</Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack gap={4} mt={-4}>
                    <SimpleGrid cols={2} spacing="sm">
                      <BNDatePicker leftSection={<DateRangeIcon />} valueFormat="MM/DD/YY" clearable rightSectionWidth={24} label="Date From" size="xs" />
                      <BNDatePicker
                        leftSection={<DateRangeIcon />}
                        valueFormat="MM/DD/YY"
                        clearable
                        rightSectionWidth={24}
                        label="Date To"
                        size="xs"
                        data-pendo-name="Sales - Event Date To Input"
                      />
                    </SimpleGrid>
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item> */}
              <Accordion.Item key="invoices" value="Invoices">
                <Accordion.Control icon={<ListIcon />}>
                  <Text fw={600}>Listings</Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack gap={8} mt={-4}>
                    <Button.Group className={classes.btnGroup}>
                      <Tooltip label="Auto-Priced" withArrow>
                        <BNButton
                          variant="default"
                          size="xs"
                          data-pendo-name="Auto-Priced Filter Button"
                          className={form.values.pricerStatuses?.some((s) => s === 'AutoPriced') ? `${classes.groupBtn} ${classes.groupBtnSelected}` : `${classes.groupBtn}`}
                          fullWidth
                          onClick={() =>
                            form.setFieldValue(
                              'pricerStatuses',
                              form.values.pricerStatuses?.some((s) => s === 'AutoPriced')
                                ? [...form.values.pricerStatuses.filter((s) => s !== 'AutoPriced')]
                                : [...(form.values.pricerStatuses ?? []).concat(['AutoPriced'])],
                            )
                          }
                        >
                          <AutoPriceIcon color={form.values.pricerStatuses?.some((s) => s === 'AutoPriced') ? 'var(--colors-selectedBorder)' : undefined} />
                        </BNButton>
                      </Tooltip>
                      <Tooltip label="At Floor" withArrow>
                        <BNButton
                          variant="default"
                          size="xs"
                          className={form.values.pricerStatuses?.some((s) => s === 'AtFloor') ? `${classes.groupBtn} ${classes.groupBtnSelected}` : `${classes.groupBtn}`}
                          fullWidth
                          data-pendo-name="At Floor Filter Button"
                          onClick={() =>
                            form.setFieldValue(
                              'pricerStatuses',
                              form.values.pricerStatuses?.some((s) => s === 'AtFloor')
                                ? [...form.values.pricerStatuses.filter((s) => s !== 'AtFloor')]
                                : [...(form.values.pricerStatuses ?? []).concat(['AtFloor'])],
                            )
                          }
                        >
                          <VerticalAlignBottomIcon color={form.values.pricerStatuses?.some((s) => s === 'AtFloor') ? 'var(--colors-selectedBorder)' : undefined} />
                        </BNButton>
                      </Tooltip>
                      <Tooltip label="No Comparables" withArrow>
                        <BNButton
                          variant="default"
                          size="xs"
                          data-pendo-name="No Comparables Filter Button"
                          className={form.values.pricerStatuses?.some((s) => s === 'NoComparables') ? `${classes.groupBtn} ${classes.groupBtnSelected}` : `${classes.groupBtn}`}
                          fullWidth
                          onClick={() =>
                            form.setFieldValue(
                              'pricerStatuses',
                              form.values.pricerStatuses?.some((s) => s === 'NoComparables')
                                ? [...form.values.pricerStatuses.filter((s) => s !== 'NoComparables')]
                                : [...(form.values.pricerStatuses ?? []).concat(['NoComparables'])],
                            )
                          }
                        >
                          <MobileDataOffIcon color={form.values.pricerStatuses?.some((s) => s === 'NoComparables') ? 'var(--colors-selectedBorder)' : undefined} />
                        </BNButton>
                      </Tooltip>
                      <Tooltip label="Not Auto-Priced" withArrow>
                        <BNButton
                          variant="default"
                          size="xs"
                          data-pendo-name="Not Auto-Priced Filter Button"
                          className={form.values.pricerStatuses?.some((s) => s === 'None') ? `${classes.groupBtn} ${classes.groupBtnSelected}` : `${classes.groupBtn}`}
                          fullWidth
                          onClick={() =>
                            form.setFieldValue(
                              'pricerStatuses',
                              form.values.pricerStatuses?.some((s) => s === 'None')
                                ? [...form.values.pricerStatuses.filter((s) => s !== 'None')]
                                : [...(form.values.pricerStatuses ?? []).concat(['None'])],
                            )
                          }
                        >
                          <AutoPriceOffIcon color={form.values.pricerStatuses?.some((s) => s === 'None') ? 'var(--colors-selectedBorder)' : undefined} />
                        </BNButton>
                      </Tooltip>
                      {/*<Tooltip label="Errored" withArrow>*/}
                      {/*  <BNButton*/}
                      {/*    variant="default"*/}
                      {/*    size="xs"*/}
                      {/*    className={form.values.pricerStatuses?.some((s) => s === 'Errored') ? `${classes.groupBtn} ${classes.groupBtnSelected}` : `${classes.groupBtn}`}*/}
                      {/*    fullWidth*/}
                      {/*    onClick={() =>*/}
                      {/*      form.setFieldValue(*/}
                      {/*        'pricerStatuses',*/}
                      {/*        form.values.pricerStatuses?.some((s) => s === 'Errored')*/}
                      {/*          ? [...form.values.pricerStatuses.filter((s) => s !== 'Errored')]*/}
                      {/*          : [...(form.values.pricerStatuses ?? []).concat(['Errored'])],*/}
                      {/*      )*/}
                      {/*    }*/}
                      {/*  >*/}
                      {/*    <ErrorIcon color={form.values.pricerStatuses?.some((s) => s === 'Errored') ? 'var(--colors-selectedBorder)' : undefined} />*/}
                      {/*  </BNButton>*/}
                      {/*</Tooltip>*/}
                    </Button.Group>
                    {/* Commented out until we develop a more elegant way of handling many different advanced filters */}
                    <SimpleGrid cols={2} spacing={12} style={{ flexGrow: 1 }}>
                      <BNTextInput
                        size="xs"
                        variant="default"
                        label="Listing ID"
                        {...form.getInputProps('listingId')}
                        clearable
                        clearableOnClick={() => form.setFieldValue('listingId', '')}
                        onKeyUp={handleKeyUp}
                        data-pendo-name="Listing ID Filter Input"
                      />
                      <BNTextInput
                        size="xs"
                        variant="default"
                        label="Event ID"
                        {...form.getInputProps('eventId')}
                        clearable
                        clearableOnClick={() => form.setFieldValue('eventId', '')}
                        onKeyUp={handleKeyUp}
                        data-pendo-name="Event ID Filter Input"
                      />
                    </SimpleGrid>
                    <SimpleGrid cols={2} spacing={12} style={{ flexGrow: 1 }}>
                      {showCustomPricedSince ? (
                        <div ref={focusTrapRef}>
                          <BNDatePicker
                            size="xs"
                            variant="default"
                            valueFormat="MM/DD/YY"
                            label="Not Priced Since"
                            clearable
                            maxDate={dayjs().startOf('day').toDate()}
                            value={form.values.daysSinceRepriced === undefined ? null : dayjs().subtract(form.values.daysSinceRepriced, 'day').toDate()}
                            onChange={(e) => {
                              const days = Math.abs(dayjs(e).diff(dayjs(), 'day'));
                              form.setFieldValue('daysSinceRepriced', days);
                            }}
                            clearButtonProps={{
                              onClick: () => {
                                form.setFieldValue('daysSinceRepriced', undefined);
                                setShowCustomPricedSince(false);
                              },
                            }}
                            leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                            onKeyUp={handleKeyUp}
                            className={classes.dateField}
                          />
                        </div>
                      ) : (
                        <BNSelect
                          label="Not Priced In"
                          size="xs"
                          clearable
                          value={form.values.daysSinceRepriced === undefined ? null : form.values.daysSinceRepriced.toString()}
                          onChange={(e) => {
                            if (e === 'custom') {
                              setShowCustomPricedSince(true);
                            } else if (e === null) {
                              form.setFieldValue('daysSinceRepriced', undefined);
                            } else {
                              form.setFieldValue('daysSinceRepriced', parseInt(e));
                            }
                          }}
                          data={[
                            { value: '1', label: '1 Day' },
                            { value: '7', label: '7 Days' },
                            { value: '30', label: '30 Days' },
                            { value: 'custom', label: 'Custom Date' },
                            { value: '9999', label: 'Ever' },
                          ]}
                          data-pendo-name="Not Priced In Filter Select"
                        />
                      )}
                      {showCustomPurchasedFrom ? (
                        <div ref={focusTrapRef}>
                          <BNDatePicker
                            leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                            valueFormat="MM/DD/YY"
                            size="xs"
                            variant="default"
                            label="Purchased Since"
                            clearable
                            maxDate={dayjs().startOf('day').toDate()}
                            clearButtonProps={{
                              onClick: () => {
                                form.setFieldValue('purchaseDateFrom', undefined);
                                form.setFieldValue('purchaseDateTo', undefined);
                                setShowCustomPurchasedFrom(false);
                              },
                            }}
                            value={form.values.purchaseDateFrom}
                            onChange={(e) => {
                              if (e === null) {
                                form.setFieldValue('purchaseDateFrom', undefined);
                                form.setFieldValue('purchaseDateTo', undefined);
                              } else {
                                form.setFieldValue('purchaseDateFrom', e);
                                form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                              }
                            }}
                            className={classes.dateField}
                          />
                        </div>
                      ) : (
                        <BNSelect
                          label="Purchased"
                          size="xs"
                          clearable
                          value={
                            !form.values.purchaseDateFrom || !form.values.purchaseDateTo
                              ? ''
                              : form.values.purchaseDateFrom.getTime() === dayjs().startOf('day').toDate().getTime() &&
                                  form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                                ? '0'
                                : form.values.purchaseDateFrom.getTime() === dayjs().subtract(1, 'day').startOf('day').toDate().getTime() &&
                                    form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                                  ? '1'
                                  : form.values.purchaseDateFrom.getTime() === dayjs().startOf('week').toDate().getTime() &&
                                      form.values.purchaseDateTo.getTime() === dayjs().endOf('day').toDate().getTime()
                                    ? 'week'
                                    : form.values.purchaseDateFrom && form.values.purchaseDateTo
                                      ? 'custom'
                                      : undefined
                          }
                          onChange={(e) => {
                            if (e === 'custom') {
                              setShowCustomPurchasedFrom(true);
                            } else if (e === null) {
                              form.setFieldValue('purchaseDateFrom', undefined);
                              form.setFieldValue('purchaseDateTo', undefined);
                            } else if (e === 'week') {
                              form.setFieldValue('purchaseDateFrom', dayjs().startOf('week').startOf('day').toDate());
                              form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                            } else {
                              form.setFieldValue('purchaseDateFrom', dayjs().subtract(parseInt(e), 'day').startOf('day').toDate());
                              form.setFieldValue('purchaseDateTo', dayjs().endOf('day').toDate());
                            }
                          }}
                          data={[
                            { value: '0', label: 'Today' },
                            { value: '1', label: 'Yesterday' },
                            { value: 'week', label: 'This Week' },
                            { value: 'custom', label: 'Custom Date' },
                          ]}
                          data-pendo-name="Purchased Filter Select"
                        />
                      )}
                      {showCustomSoldSince ? (
                        <div ref={focusTrapRef}>
                          <BNDatePicker
                            size="xs"
                            variant="default"
                            valueFormat="MM/DD/YY"
                            label="No Sales Since"
                            clearable
                            maxDate={dayjs().startOf('day').toDate()}
                            value={form.values.daysSinceLastSale === undefined ? null : dayjs().subtract(form.values.daysSinceLastSale, 'day').toDate()}
                            onChange={(e) => {
                              const days = Math.abs(dayjs(e).diff(dayjs(), 'day'));
                              form.setFieldValue('daysSinceLastSale', days);
                            }}
                            clearButtonProps={{
                              onClick: () => {
                                form.setFieldValue('daysSinceLastSale', undefined);
                                setShowCustomSoldSince(false);
                              },
                            }}
                            leftSection={<DateRangeIcon size={20} color="var(--colors-gray-4)" />}
                            onKeyUp={handleKeyUp}
                            className={classes.dateField}
                          />
                        </div>
                      ) : (
                        <BNSelect
                          label="No Sales In"
                          size="xs"
                          clearable
                          value={form.values.daysSinceLastSale === undefined ? null : form.values.daysSinceLastSale.toString()}
                          onChange={(e) => {
                            if (e === 'custom') {
                              setShowCustomSoldSince(true);
                            } else if (e === null) {
                              form.setFieldValue('daysSinceLastSale', undefined);
                            } else {
                              form.setFieldValue('daysSinceLastSale', parseInt(e));
                            }
                          }}
                          data={[
                            { value: '1', label: '1 Day' },
                            { value: '7', label: '7 Days' },
                            { value: '30', label: '30 Days' },
                            { value: 'custom', label: 'Custom Date' },
                            { value: '9999', label: 'Ever' },
                          ]}
                          data-pendo-name="No Sales In Filter Select"
                        />
                      )}
                      {hasFirstSeenFilterFlag ? (
                        <BNSelect
                          label="First Seen"
                          size="xs"
                          clearable
                          value={form.values.daysSinceFirstSeen === undefined ? null : form.values.daysSinceFirstSeen.toString()}
                          onChange={(e) => {
                            if (e === 'custom') {
                              setShowCustomSoldSince(true);
                            } else if (e === null) {
                              form.setFieldValue('daysSinceFirstSeen', undefined);
                            } else {
                              form.setFieldValue('daysSinceFirstSeen', parseInt(e));
                            }
                          }}
                          data={[
                            { value: '1', label: '1 Day' },
                            { value: '7', label: '7 Days' },
                            { value: '30', label: '30 Days' },
                            { value: 'custom', label: 'Custom Date' },
                            { value: '9999', label: 'Ever' },
                          ]}
                          data-pendo-name="First Seen Filter Select"
                        />
                      ) : (
                        <BNSwitch
                          label="Not Broadcasting"
                          size="md"
                          checked={form.values.onlyNotBroadcasting === true}
                          onChange={(event) => form.setFieldValue('onlyNotBroadcasting', event.currentTarget.checked)}
                          className={classes.switchLabelTop}
                          data-pendo-name="Not Broadcasting Filter Switch"
                        />
                      )}
                    </SimpleGrid>
                    <BNSelect
                      data={[
                        { value: 'open', label: 'Only With Inventory' },
                        { value: 'mine', label: 'Including Sold Out' },
                        { value: 'all', label: 'All Events' },
                      ]}
                      size="xs"
                      label="Show Events"
                      value={form.values.showEvents ?? 'open'}
                      onChange={(e) => form.setFieldValue('showEvents', (e as InventorySearchParams['showEvents']) ?? 'open')}
                      data-pendo-name="Show Events Filter Select"
                    />
                    <TagsFilter />
                    {singleSkyBoxTenantSelected && <AntiTagsFilter />}
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </ScrollArea.Autosize>
        </Flex>
      </Flex>
    </>
  );
}
