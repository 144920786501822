import yasml from '@thirtytech/yasml';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { pendingListingUpdatesAtom, rejectAllPendingUpdatesAtom, selectedMergedListingAtom, updateListingsAtom } from './atoms';
import { TenantIdEventId, TenantIdListingId } from '../models/tenantIdListingId';
import {
  BarkerCoreEnumsPricingMode,
  BarkerCoreModelsInventoryListing,
  BarkerCoreModelsInventoryMergeInventoryRequest,
  BarkerCoreModelsInventorySplitInventoryRequest,
  GetApiInventoryEventsParams,
  getApiInventoryListingsListingId,
  patchApiInventoryListingsListingId,
  postApiInventoryListingsMerge,
  putApiInventoryListingsListingIdSplit,
  SystemTextJsonPatchJsonPatchDocument,
  usePostApiPricingRulesRuleIdSplit,
} from '../api';
import { Mutable } from '../ts-utils';
import { BarkerEventListing, ListingUpdate } from '../types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { notifications } from '@mantine/notifications';
import { Text } from '@mantine/core';
import { useFlag } from '@unleash/proxy-client-react';
import { useDidUpdate } from 'rooks';
import { BNButton } from '../components/Button/Button';
import WarningIcon from '../components/icons/Warning';
import { useGlobalState } from './GlobalState';

export const onProcessPendingUpdatesEvent = new Event('ProcessPendingUpdates');

function ListingState() {
  const { pricerMode } = useGlobalState('pricerMode');
  const [lastClickedActiveEventId, setLastClickedActiveEventId] = useState<TenantIdEventId | null>(null);
  const {
    mutateAsync: patchListing,
    isPending: patchListingLoading,
    isError: isPatchListingError,
    error: patchListingError,
  } = useMutation<
    AxiosResponse<BarkerCoreModelsInventoryListing>,
    AxiosError<void>,
    {
      tenantId: string;
      listingId: string;
      data: SystemTextJsonPatchJsonPatchDocument;
    }
  >({
    mutationFn: ({ tenantId, listingId, data }) =>
      patchApiInventoryListingsListingId(listingId, data, {
        headers: {
          'x-tenant-id': tenantId,
        },
      }),
  });

  const patchListing404HandlerFlag = useFlag('patch-listing-404-handler');

  const selectedListing = useAtomValue(selectedMergedListingAtom);
  const [pendingListingUpdates, setPendingListingUpdates] = useAtom(pendingListingUpdatesAtom);
  const updateInventoryListing = useSetAtom(updateListingsAtom);

  const showListingError = useCallback(() => {
    const tenantIdEventId = selectedListing?.eventId ? `${selectedListing?.tenantId}|${selectedListing?.eventId}` : null;
    notifications.show({
      id: 'patch-listing-removed',
      autoClose: false,
      title: 'Listing no longer exists',
      message: (
        <>
          <Text>Listing was sold or was removed. Please contact support if you believe this is an error.</Text>
          <BNButton
            size="xs"
            mt={14}
            onClick={() => {
              setLastClickedActiveEventId(TenantIdEventId.fromString(tenantIdEventId));
              notifications.hide('patch-listing-removed');
            }}
          >
            Refresh Event Listings
          </BNButton>
        </>
      ),
      icon: <WarningIcon color="var(--colors-yellow-warning)" size={28} />,
      color: 'yellow',
    });
  }, [selectedListing?.eventId, selectedListing?.tenantId]);

  useDidUpdate(() => {
    if (patchListing404HandlerFlag && isPatchListingError && patchListingError?.response?.status === 404) {
      showListingError();
    }
  }, [isPatchListingError, showListingError]);

  useDidUpdate(() => {
    if (selectedListing?.listingId && patchListing404HandlerFlag) {
      getApiInventoryListingsListingId(selectedListing?.listingId, {
        headers: {
          'x-tenant-id': selectedListing?.tenantId,
        },
      }).catch(() => {
        showListingError();
      });
    }
  }, [selectedListing?.tenantIdListingId]);

  const updatePrice = useCallback(
    async (tenantId: string, listingId: string, price: number, previousPrice: number, forceUpdate: boolean = false) => {
      if (pricerMode === BarkerCoreEnumsPricingMode.Staged && !forceUpdate) {
        setPendingListingUpdates((prev) => [
          ...prev,
          {
            listingId,
            tenantId,
            property: 'unitPrice',
            value: price,
            previousValue: previousPrice,
            inlinePriceChange: true,
          },
        ]);
        return;
      }
      const { data: listing } = await patchListing({
        tenantId,
        listingId,
        data: [
          { op: 'Replace', path: '/unitPrice', value: price },
          { op: 'Replace', path: '/previousPrice', value: previousPrice },
        ],
      });
      setPendingListingUpdates((prev) => prev.filter((x) => x.listingId !== listingId));
      updateInventoryListing(listing);
    },
    [patchListing, pricerMode, setPendingListingUpdates, updateInventoryListing],
  );

  const { mutateAsync: splitListingRule } = usePostApiPricingRulesRuleIdSplit({
    axios: {
      headers: {
        'x-tenant-id': selectedListing?.tenantId,
      },
    },
  });

  const pendingListingUpdatesByProperty = useCallback(
    ({ tenantId, listingId, property }: { tenantId: string; listingId: string; property: keyof BarkerCoreModelsInventoryListing }) => {
      const filtered = pendingListingUpdates.filter((x) => x.tenantId === tenantId && x.listingId === listingId && x.property === property);
      return filtered.length > 0 ? filtered[filtered.length - 1] : null;
    },
    [pendingListingUpdates],
  );

  const processPendingUpdates = useCallback(
    async (pendingUpdates: typeof pendingListingUpdates, ignoreSplits: boolean = false) => {
      const groupedUpdates = pendingUpdates.reduce(
        (acc, update) => {
          const key = TenantIdListingId.fromString(`${update.tenantId}|${update.listingId}`);
          if (acc[key.toStringTyped()]) {
            acc[key.toStringTyped()].push(update);
          } else {
            acc[key.toStringTyped()] = [update];
          }
          return acc;
        },
        {} as { [key: string]: ListingUpdate[] },
      );

      const bulkUpdateQueue = [];
      for (const key of Object.keys(groupedUpdates)) {
        const { tenantId, listingId } = TenantIdListingId.fromString(key);
        const updates = groupedUpdates[key];
        const lastUpdateByProperty = updates.reduce(
          (acc, update) => {
            acc[update.property] = update;
            return acc;
          },
          {} as { [key: string]: ListingUpdate },
        );

        const listingUpdates: Mutable<SystemTextJsonPatchJsonPatchDocument> = [];
        const handleSplitListings: ListingUpdate[] = [];
        Object.entries(lastUpdateByProperty).forEach(([, update]) => {
          if (update.property === 'unitPrice') {
            listingUpdates.push({ op: 'Replace', path: `/${update.property}`, value: update.value });
            listingUpdates.push({ op: 'Replace', path: '/previousPrice', value: update.previousValue });
          } else if (update.property === 'ruleId' && update.value === null && !ignoreSplits) {
            handleSplitListings.push(update);
          } else {
            listingUpdates.push({ op: 'Replace', path: `/${update.property}`, value: update.value });
          }
        });

        bulkUpdateQueue.push(
          patchListing({ tenantId, listingId, data: listingUpdates }).then(async (result) => {
            const _result = result;
            if (handleSplitListings.length > 0) {
              const splitRuleResult = await splitListingRule({
                ruleId: handleSplitListings.find((x) => x.property === 'ruleId')!.previousValue as string,
                data: {
                  listingIds: handleSplitListings.map((x) => x.listingId),
                },
              });

              _result.data.ruleId = splitRuleResult.data.find((x) => x.listingId === listingId)!.ruleId;
            }
            return _result.data;
          }),
        );
      }
      const updatedListings = await Promise.all(bulkUpdateQueue);
      // TODO: We need error handling around this. If it fails for any of them then nothing will get updated.
      updateInventoryListing(updatedListings);

      setPendingListingUpdates((x) => x.filter((y) => !pendingUpdates.map((z) => z.listingId).includes(y.listingId)));
    },
    [patchListing, setPendingListingUpdates, splitListingRule, updateInventoryListing],
  );

  const onHandleProcessPendingUpdatesEvent = useCallback(async () => {
    await processPendingUpdates(pendingListingUpdates);
  }, [pendingListingUpdates, processPendingUpdates]);

  useEffect(() => {
    document.addEventListener(onProcessPendingUpdatesEvent.type, onHandleProcessPendingUpdatesEvent);
    return () => {
      document.removeEventListener(onProcessPendingUpdatesEvent.type, onHandleProcessPendingUpdatesEvent);
    };
  }, [onHandleProcessPendingUpdatesEvent]);

  const rejectPendingListingUpdates = useSetAtom(rejectAllPendingUpdatesAtom);
  const _rejectPendingUpdates = useCallback(
    (listingIds?: string[]) => {
      rejectPendingListingUpdates(listingIds);
      // Hack: Needed to update drag cell handle when on selected listing when disbanding a group. Need a better way
      const anchorNode = (window as any)?.gridRef?.api?.getRowNode(selectedListing?.tenantIdListingId);
      if (anchorNode) {
        setTimeout(() => {
          (window as any).gridRef.api.redrawRows({ rowNodes: [anchorNode] });
        }, 250);
      }
    },
    [rejectPendingListingUpdates, selectedListing],
  );

  const cancelPendingChanges = useCallback(
    (listingIds?: string[]) => {
      _rejectPendingUpdates(listingIds);
    },
    [_rejectPendingUpdates],
  );

  const {
    mutateAsync: splitListing,
    isPending: splitListingLoading,
    isError: isSplitListingError,
    error: splitListingError,
  } = useMutation<
    AxiosResponse<BarkerCoreModelsInventoryListing[]>,
    AxiosError<void>,
    {
      tenantId: string;
      listingId: string;
      data: BarkerCoreModelsInventorySplitInventoryRequest;
      queryKey: readonly ['/api/Inventory/Events', ...GetApiInventoryEventsParams[]];
    }
  >({
    mutationFn: ({ tenantId, listingId, data }) =>
      putApiInventoryListingsListingIdSplit(listingId, data, {
        headers: {
          'x-tenant-id': tenantId,
        },
      }),
  });

  const splitGivenListing = useCallback(
    async (listing: BarkerEventListing, splitOffQuantity: number, newPrice: number) => {
      const queryKey: readonly ['/api/Inventory/Events', ...GetApiInventoryEventsParams[]] = ['/api/Inventory/Events', { fromDate: new Date(), toDate: new Date() }] as const;

      const { data: splitResponse } = await splitListing({
        tenantId: listing.tenantId,
        listingId: listing.listingId,
        data: { splitOffQuantity, newPrice },
        queryKey,
      });

      return splitResponse ?? [];
    },
    [splitListing],
  );

  useDidUpdate(() => {
    if (isSplitListingError && splitListingError?.response?.status === 404) {
      notifications.show({
        id: 'split-listing-removed',
        autoClose: false,
        title: 'Listing no longer exists. 2',
        message: (
          <>
            <Text>Listing was sold or was removed. Please contact support if you believe this is an error.</Text>
            <BNButton
              size="xs"
              style={{ marginTop: 14 }}
              onClick={() => {
                notifications.hide('split-listing-removed');
              }}
            >
              Refresh Event Listings
            </BNButton>
          </>
        ),
        icon: <WarningIcon color="var(--colors-yellow-warning)" size={28} />,
        color: 'yellow',
      });
    }
  }, [isSplitListingError]);

  const {
    mutateAsync: mergeListings,
    isPending: mergeListingsLoading,
    isError: isMergeListingsError,
    error: mergeListingsError,
  } = useMutation<
    AxiosResponse<BarkerCoreModelsInventoryListing>,
    AxiosError<void>,
    {
      tenantId: string;
      data: BarkerCoreModelsInventoryMergeInventoryRequest;
    }
  >({
    mutationFn: ({ tenantId, data }) =>
      postApiInventoryListingsMerge(data, {
        headers: {
          'x-tenant-id': tenantId,
        },
      }),
  });

  useDidUpdate(() => {
    if (isMergeListingsError && mergeListingsError?.response?.status === 404) {
      const tenantIdEventId = selectedListing?.eventId ? `${selectedListing?.tenantId}|${selectedListing?.eventId}` : null;
      notifications.show({
        id: 'merge-listing-removed',
        autoClose: false,
        title: 'One of the listings no longer exists',
        message: (
          <>
            <Text>One of the listings to merge was sold or was removed. Please contact support if you believe this is an error.</Text>
            <BNButton
              size="xs"
              style={{ marginTop: 14 }}
              onClick={() => {
                setLastClickedActiveEventId(TenantIdEventId.fromString(tenantIdEventId));
                notifications.hide('merge-listing-removed');
              }}
            >
              Refresh Event Listings
            </BNButton>
          </>
        ),
        icon: <WarningIcon color="var(--colors-yellow-warning)" size={28} />,
        color: 'yellow',
      });
    }
  }, [isMergeListingsError]);

  return {
    pendingListingUpdates,
    setPendingListingUpdates,
    pendingListingUpdatesByProperty,
    processPendingUpdates,
    splitListingRule,
    patchListing,
    patchListingLoading,
    setLastClickedActiveEventId,
    lastClickedActiveEventId,
    rejectPendingUpdates: _rejectPendingUpdates,
    rejectPendingListingUpdates,
    cancelPendingChanges,
    updatePrice,
    splitGivenListing,
    splitListingLoading,
    mergeListings,
    mergeListingsLoading,
  };
}

export const { Provider: ListingStateProvider, useSelector: useListingState } = yasml(ListingState);
