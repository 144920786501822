import {
  BarkerCoreModelsAdministrativePrincipal,
  BarkerCoreModelsInventoryEvent,
  BarkerCoreModelsInventoryEventComment,
  getGetApiInventoryEventsEventIdCommentsQueryKey,
  useDeleteApiInventoryEventsEventIdCommentsCommentId,
  useGetApiInventoryEventsEventIdComments,
  usePostApiInventoryEventsEventIdComments,
  usePutApiInventoryEventsEventIdCommentsCommentId,
} from '../../api';
import yasml from '@thirtytech/yasml';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSetAtom } from 'jotai';
import { updateEventAtom } from '../../data/atoms';

interface EventCommentsStateProps {
  event: BarkerCoreModelsInventoryEvent;
  principal: BarkerCoreModelsAdministrativePrincipal | undefined;
  numComments: number;
}

function EventCommentsState({ event, principal, numComments }: EventCommentsStateProps) {
  const uniqueQueryKey = [...getGetApiInventoryEventsEventIdCommentsQueryKey(event.eventId), event.tenantId];
  const queryClient = useQueryClient();
  const updateEvent = useSetAtom(updateEventAtom);

  const { data: comments, isLoading: isCommentsLoading } = useGetApiInventoryEventsEventIdComments(event.eventId, {
    query: {
      enabled: !!event.eventId,
      queryKey: uniqueQueryKey,
    },
    axios: {
      headers: {
        'X-Tenant-Id': event.tenantId,
      },
    },
  });

  const editMutation = usePutApiInventoryEventsEventIdCommentsCommentId({
    mutation: {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: uniqueQueryKey }),
    },
    axios: {
      headers: {
        'X-Tenant-Id': event.tenantId,
      },
    },
  });

  const addMutation = usePostApiInventoryEventsEventIdComments({
    mutation: {
      onSuccess: (data) => {
        queryClient.setQueryData<AxiosResponse<BarkerCoreModelsInventoryEventComment[]>>(uniqueQueryKey, (old) => {
          if (!old || !old.data) {
            return old;
          }

          return { ...old, data: [...old.data, data.data] };
        });
      },
    },
    axios: {
      headers: {
        'X-Tenant-Id': event.tenantId,
      },
    },
  });

  const deleteMutation = useDeleteApiInventoryEventsEventIdCommentsCommentId({
    axios: {
      headers: {
        'X-Tenant-Id': event.tenantId,
      },
    },
  });

  async function addComment(commentToAdd: string) {
    try {
      if (!commentToAdd) {
        return;
      }

      await addMutation.mutateAsync({
        eventId: event.eventId,
        params: {
          comment: commentToAdd,
        },
      });

      updateEvent({
        ...event,
        numComments: numComments + 1,
      });
    } catch (error) {
      throw new Error('Failed to add comment:', { cause: error });
    }
  }

  async function editComment(commentToEdit: BarkerCoreModelsInventoryEventComment, revisedComment: string) {
    try {
      if (!revisedComment) {
        return;
      }

      await editMutation.mutateAsync({
        eventId: event.eventId,
        commentId: commentToEdit.commentId,
        params: {
          editedComment: revisedComment,
        },
      });
    } catch (error) {
      throw new Error('Failed to edit comment:', { cause: error });
    }
  }

  async function deleteComment(commentId: number) {
    try {
      await deleteMutation.mutateAsync(
        {
          eventId: event.eventId,
          commentId,
        },
        {
          onSuccess: () => {
            queryClient.setQueryData<AxiosResponse<BarkerCoreModelsInventoryEventComment[]>>(uniqueQueryKey, (old) => {
              if (!old || !old.data) {
                return old;
              }

              return { ...old, data: old?.data.filter((c) => c.commentId !== commentId) };
            });
          },
        },
      );

      updateEvent({
        ...event,
        numComments: numComments - 1,
      });
    } catch (error) {
      throw new Error('Failed to delete comment:', { cause: error });
    }
  }

  return {
    numComments,
    comments: comments?.data ?? [],
    addComment,
    editComment,
    deleteComment,
    principal,
    isCommentsLoading,
  };
}

export const { Provider: EventCommentsProvider, useSelector: useEventComments } = yasml(EventCommentsState);
