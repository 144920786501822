import { Box, Center, Divider, Flex, Group, Menu, Text, Tooltip, UnstyledButton } from '@mantine/core';
import AutoPriceIcon from '../../components/icons/AutoPrice';
import { BNNumberInput } from '../../components/NumberInput/NumberInput';
import { BNSwitch } from '../../components/Switch/Switch';
import { useNowPricing } from './NowPricing.hooks';
import ScheduledPriceIcon from '../../components/icons/ScheduledPrice';
import SelectArrowsIcon from '../../components/icons/SelectArrows';
import classes from './NowPricing.styles.tsx.module.css';
import { useFlag } from '@unleash/proxy-client-react';
import { BNButton } from '../../components/Button/Button';
import ChevronForwardIcon from '../../components/icons/ChevronForward';
import AutoPriceOffIcon from '../../components/icons/AutoPriceOff';
import ScheduledPriceOffIcon from '../../components/icons/ScheduledPriceOff';
import { useGlobalState } from '../../data/GlobalState';
import AutoPauseIcon from '../../components/icons/AutoPause';
import WarningPauseIcon from '../../components/icons/WarningPause';

export const PriceForm = () => {
  const { selectedMarketplaceEvent, isFormReady, onAutoPricerToggle, form, isDrasticChange, onSchedulePricerToggle } = useNowPricing(
    'selectedMarketplaceEvent',
    'isFormReady',
    'onAutoPricerToggle',
    'form',
    'isDrasticChange',
    'onSchedulePricerToggle',
    'onSave',
  );

  const { principal } = useGlobalState('principal');

  const pauseAutoPricingFlag = useFlag('pause-autopricing');
  const isPaused = !!form.values.pausedAt;
  const showWarningNote = !!form.values.warningNotes;

  if (!principal) {
    return null;
  }

  return (
    <Group gap="md">
      <Box maw={151} style={{ flex: 1 }}>
        <BNNumberInput
          size="lg"
          disabled={(!!form.values.isAutoPriced && !isPaused) || !isFormReady}
          {...form.getInputProps('listPrice')}
          value={form.values.listPrice}
          leftSection={
            <Text size="xl" ml={-8}>
              $
            </Text>
          }
          error={form.getInputProps('listPrice').error ?? (isDrasticChange ? 'Large price change' : undefined)}
          errorVariant={form.getInputProps('listPrice').error ? 'error' : 'warning'}
          decimalScale={2}
          fixedDecimalScale
          min={0}
          label="List Price"
          labelProps={{ fz: 'xs' }}
          className={form.values.isAutoPriced ? classes.numInputAutoPriced : classes.numInput}
          selectOnFocus
        />
      </Box>
      {!pauseAutoPricingFlag && !isPaused ? (
        <Box className={classes.autoPriceWrapper}>
          <Text c="var(--colors-gray-5)" size="xs" mb={8} mt={-8}>
            {form.values.isScheduled ? 'Automate' : 'Auto-Price'}
          </Text>
          <Group gap={4}>
            <BNSwitch
              disabled={!isFormReady || (!form.values.isAutoPriced && !form.values.isScheduled && !selectedMarketplaceEvent.marketplaceEvent)}
              size="lg"
              {...form.getInputProps('isAutoPriced', { type: 'checkbox' })}
              checked={form.values.isAutoPriced || form.values.isScheduled}
              onChange={onAutoPricerToggle}
            />
            <Menu position="bottom">
              <Menu.Target>
                <UnstyledButton
                  className={classes.toggleButton}
                  pos="relative"
                  pr={0}
                  pl={form.values.isScheduled ? 2 : 3}
                  py={4}
                  w={38}
                  disabled={!isFormReady}
                  data-pendo-name="Switch to Scheduled Pricer"
                >
                  <Tooltip label="Switch between auto-pricer and scheduled pricer." withArrow>
                    <Center>
                      <Center w={24}>
                        {form.values.isScheduled && <ScheduledPriceIcon size={24} color="var(--colors-orange)" />}
                        {!form.values.isScheduled && <AutoPriceIcon size={24} color={form.values.isAutoPriced ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)'} />}
                      </Center>
                      <Center w={24} pos="absolute" right={-4}>
                        <SelectArrowsIcon color="var(--colors-gray-5)" />
                      </Center>
                    </Center>
                  </Tooltip>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                {!form.values.isScheduled && (
                  <Menu.Item
                    fz="xs"
                    leftSection={<ScheduledPriceIcon color="var(--colors-orange)" />}
                    onClick={() => onSchedulePricerToggle()}
                    data-pendo-name="Scheduled Pricer Option"
                  >
                    Change to Scheduled Pricer
                  </Menu.Item>
                )}
                {form.values.isScheduled && (
                  <Tooltip label="Unable to enable Auto-Pricer when event is not mapped" hidden={!!selectedMarketplaceEvent.marketplaceEvent} withArrow>
                    <div>
                      <Menu.Item
                        fz="xs"
                        leftSection={<AutoPriceIcon color="var(--colors-selectedBorder)" />}
                        disabled={!selectedMarketplaceEvent.marketplaceEvent}
                        onClick={() => onAutoPricerToggle(true)}
                      >
                        Change to Auto-Pricer
                      </Menu.Item>
                    </div>
                  </Tooltip>
                )}
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Box>
      ) : (
        <Box className={classes.autoPriceWrapper} display="block">
          <Tooltip disabled={!isPaused} label={showWarningNote ? 'Paused - See Warning Note' : 'Paused'}>
            <Box component="label" h="20" display="inline-block">
              <Group justify="flex-start" c={showWarningNote ? 'var(--colors-yellow-warning)' : 'var(--colors-gray-5)'} gap={4}>
                <Text c={showWarningNote ? 'var(--colors-yellow-warning)' : 'var(--colors-gray-5)'} size="xs" pos="relative">
                  {form.values.isScheduled ? 'Automate' : 'Auto-Price'}
                </Text>
                {isPaused ? showWarningNote ? <WarningPauseIcon /> : <AutoPauseIcon /> : undefined}
              </Group>
            </Box>
          </Tooltip>
          {isPaused ? (
            <Flex
              h={50}
              w="100%"
              miw={103}
              align="center"
              justify="center"
              bg="var(--colors-gray-1)"
              style={{ border: showWarningNote ? '1px solid var(--colors-yellow-5)' : '1px solid var(--colors-gray-3)', borderRadius: 3 }}
            >
              <Flex gap="md" justify="center" w="100%">
                <Group gap={0} style={{ borderRadius: 3 }}>
                  <Tooltip label={showWarningNote ? 'Cannot resume until warning dismissed' : form.values.isScheduled ? 'Resume Scheduled Pricing' : 'Resume Auto-Pricing'}>
                    <BNButton
                      color="gray"
                      disabled={showWarningNote}
                      onClick={() => {
                        form.setFieldValue('pausedAt', null);
                        form.setFieldValue('pausedBy', null);
                      }}
                      size="xs"
                      variant="filled"
                      style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                      flex={1}
                      px={6}
                    >
                      Resume
                    </BNButton>
                  </Tooltip>
                  <Divider orientation="vertical" h={30} ml={-1} color="rgba(0,0,0,.25)" opacity={showWarningNote ? 0.1 : 0.9} />
                  <Menu
                    withArrow
                    styles={{
                      arrow: { borderColor: 'var(--colors-paper)', borderTopColor: 'var(--colors-gray-3)', borderLeftColor: 'var(--colors-gray-3)' },
                    }}
                  >
                    <Menu.Target>
                      <BNButton size="xs" disabled={showWarningNote} variant="filled" px={0} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} w={20}>
                        <Center style={{ transform: 'rotate(90deg)' }}>
                          <ChevronForwardIcon size={12} />
                        </Center>
                      </BNButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {form.values.isScheduled ? (
                        <Menu.Item
                          style={{ borderBottom: 0 }}
                          leftSection={<ScheduledPriceOffIcon />}
                          onClick={() => {
                            onAutoPricerToggle(false); // This will clear the paused at and by fields
                          }}
                        >
                          Turn Off Scheduled Pricing
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          style={{ borderBottom: 0 }}
                          leftSection={<AutoPriceOffIcon />}
                          onClick={() => {
                            onAutoPricerToggle(false); // This will clear the paused at and by fields
                          }}
                        >
                          Turn Off Auto-Pricing
                        </Menu.Item>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                </Group>
              </Flex>
            </Flex>
          ) : (
            <Group gap={4} h={50} align="center">
              <BNSwitch
                disabled={!isFormReady || (!form.values.isAutoPriced && !form.values.isScheduled && !selectedMarketplaceEvent.marketplaceEvent)}
                size="lg"
                {...form.getInputProps('isAutoPriced', { type: 'checkbox' })}
                checked={form.values.isAutoPriced || form.values.isScheduled}
                onChange={onAutoPricerToggle}
                mt={-2}
              />
              <Menu position="bottom">
                <Menu.Target>
                  <UnstyledButton
                    className={classes.toggleButton}
                    pos="relative"
                    pr={0}
                    pl={form.values.isScheduled ? 2 : 3}
                    py={4}
                    mt={-2}
                    w={38}
                    disabled={!isFormReady}
                    data-pendo-name="Switch to Scheduled Pricer"
                  >
                    <Tooltip label="Switch between auto-pricer and scheduled pricer." withArrow>
                      <Center>
                        <Center w={24}>
                          {form.values.isScheduled && <ScheduledPriceIcon size={24} color="var(--colors-orange)" />}
                          {!form.values.isScheduled && <AutoPriceIcon size={24} color={form.values.isAutoPriced ? 'var(--colors-selectedBorder)' : 'var(--colors-gray-5)'} />}
                        </Center>
                        <Center w={24} pos="absolute" right={-4}>
                          <SelectArrowsIcon color="var(--colors-gray-5)" />
                        </Center>
                      </Center>
                    </Tooltip>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  {!form.values.isScheduled && (
                    <Menu.Item
                      fz="xs"
                      leftSection={<ScheduledPriceIcon color="var(--colors-orange)" />}
                      onClick={() => onSchedulePricerToggle()}
                      data-pendo-name="Scheduled Pricer Option"
                    >
                      Change to Scheduled Pricer
                    </Menu.Item>
                  )}
                  {form.values.isScheduled && (
                    <Tooltip label="Unable to enable Auto-Pricer when event is not mapped" hidden={!!selectedMarketplaceEvent.marketplaceEvent} withArrow>
                      <div>
                        <Menu.Item
                          fz="xs"
                          leftSection={<AutoPriceIcon color="var(--colors-selectedBorder)" />}
                          disabled={!selectedMarketplaceEvent.marketplaceEvent}
                          onClick={() => onAutoPricerToggle(true)}
                        >
                          Change to Auto-Pricer
                        </Menu.Item>
                      </div>
                    </Tooltip>
                  )}
                  {form.values.isAutoPriced && (
                    <Menu.Item
                      fz="xs"
                      leftSection={<AutoPauseIcon />}
                      onClick={() => {
                        form.setFieldValue('pausedAt', new Date());
                        form.setFieldValue('pausedBy', principal.principalId);
                      }}
                      data-pendo-name="Pause Auto-Pricing Option"
                    >
                      Pause Auto-Pricing
                    </Menu.Item>
                  )}
                  {form.values.isScheduled && (
                    <Menu.Item
                      fz="xs"
                      leftSection={<AutoPauseIcon />}
                      onClick={() => {
                        form.setFieldValue('pausedAt', new Date());
                        form.setFieldValue('pausedBy', principal.principalId);
                      }}
                      data-pendo-name="Pause Scheduled Pricing Option"
                    >
                      Pause Scheduled Pricing
                    </Menu.Item>
                  )}
                </Menu.Dropdown>
              </Menu>
            </Group>
          )}
        </Box>
      )}
    </Group>
  );
};
