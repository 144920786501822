/* eslint-disable react-compiler/react-compiler */
import { Box, Center, Group, Loader, Modal, Stack, Text } from '@mantine/core';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useRef, useState } from 'react';
import { useMarketListings } from './MarketListings.hooks';
import { marketplaceListingsOutliersAtom, seatingChartFiltersAtom, selectedMarketplaceEventAtom, targetComparablesAtom } from '../../data/atoms';
import { formatCurrency } from '../../utils/formatters';
import MobileDataOffIcon from '../../components/icons/MobileDataOff';
import ErrorFilledIcon from '../../components/icons/ErrorFilled';
import { SupportForm } from '../Support/SupportForm';
import { BarkerCoreModelsAdministrativePrincipal, BarkerCoreModelsAdministrativeTenantDetails, BarkerCoreModelsMarketplacesListing } from '../../api';
import LiveHelpIcon from '../../components/icons/LiveHelp';
import { useGlobalState } from '../../data/GlobalState';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import BNPullToRefresh from '../../components/PullToRefresh/PullToRefresh';
import classes from './MarketListings.styles.tsx.module.css';
import { ListingCardDelivery } from './MarketListings.ListCardDelivery';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { MarketplacesListingWithAlias } from '../../types';

export function MarketListingsList() {
  'use no memo';

  // Hack: ^^^ Required for the virtualizer to work properly. Otherwise items are memo'd and don't load after scrolling
  const { isMobile, principal, tenants } = useGlobalState('isMobile', 'principal', 'tenants');
  const { rowData, isFetching, isAutoPriced, showListingAge, fetchUpdatedListings, isFetchingUpdatedListings } = useMarketListings(
    'rowData',
    'isFetching',
    'isAutoPriced',
    'showListingAge',
    'fetchUpdatedListings',
    'isFetchingUpdatedListings',
  );
  const targetComparables = useAtomValue(targetComparablesAtom);
  const filters = useAtomValue(seatingChartFiltersAtom);
  const selectedMarketplaceEvent = useAtomValue(selectedMarketplaceEventAtom);
  const outliers = useAtomValue(marketplaceListingsOutliersAtom);
  const parentRef = useRef<HTMLDivElement>(null);
  const [supportListing, setSupportListing] = useState<BarkerCoreModelsMarketplacesListing>();

  const rowVirtualizer = useVirtualizer({
    count: rowData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan: 25,
  });

  return (
    <Box className={classes.outerWrapper}>
      <Stack gap={0} className={classes.innerWrapper}>
        {!isFetching &&
          rowData.length > 0 &&
          isAutoPriced &&
          !((filters?.sectionIds && filters?.sectionIds?.length > 0) || filters?.rows !== '' || (filters?.splits && filters?.splits?.length > 0)) && (
            <Group
              wrap="nowrap"
              px="sm"
              gap="xs"
              fz="xs"
              p="xs"
              py={6.5}
              bg="var(--colors-paper)"
              c="var(--colors-red-error)"
              style={{ borderBottom: '1px solid var(--colors-divider)' }}
            >
              <Center mah={20} maw={20}>
                <ErrorFilledIcon />
              </Center>
              You must set a filter to compare to the market.
            </Group>
          )}
        <Box className="market-results-wrapper" pos="relative" ref={parentRef} style={{ height: '100%', overflow: 'auto' }}>
          {isFetching && CustomLoadingOverlay()}
          {!isFetching && isMobile && (
            <BNPullToRefresh wrapperRef={parentRef} listClass="market-results" refreshAction={fetchUpdatedListings} isRefreshing={isFetchingUpdatedListings} />
          )}
          {!isFetching && rowData.length === 0 && CustomNoRowsOverlay()}
          {!isFetching && rowData.length > 0 && (
            <div
              className="market-results"
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <RowItem
                  key={virtualItem.index}
                  listing={rowData[virtualItem.index]}
                  outliers={outliers}
                  principal={principal}
                  setSupportListing={setSupportListing}
                  showListingAge={showListingAge}
                  targetComparables={targetComparables}
                  tenants={tenants}
                  virtualItem={virtualItem}
                />
              ))}
            </div>
          )}
        </Box>
      </Stack>
      {supportListing && selectedMarketplaceEvent && (
        <Modal
          opened
          onClose={() => setSupportListing(undefined)}
          closeOnClickOutside={false}
          title={
            <Group gap="xs">
              <LiveHelpIcon color="var(--colors-iconFill)" />
              <Text size="sm">Support Request - Marketplace</Text>
            </Group>
          }
          centered
          size="md"
        >
          <SupportForm
            type="marketplaceListing"
            marketplaceId={selectedMarketplaceEvent!.marketplaceId!}
            event={selectedMarketplaceEvent!.marketplaceEvent!}
            listing={supportListing}
            onClose={() => setSupportListing(undefined)}
          />
        </Modal>
      )}
    </Box>
  );
}

function CustomNoRowsOverlay() {
  return (
    <BNEmptyState
      h="90%"
      border={false}
      icon={<MobileDataOffIcon color="var(--colors-iconFill)" size={28} />}
      iconBg="var(--colors-opacity-hover)"
      title="No Results Found"
      description="Try adjusting the filter options"
    />
  );
}

function CustomLoadingOverlay() {
  return (
    <div className={`${classes.customLoadingOverlay} custom-loading-overlay`}>
      <Loader color="var(--colors-gray-5)" type="dots" />
    </div>
  );
}

type RowItemProps = {
  virtualItem: VirtualItem;
  showListingAge: boolean;
  listing: MarketplacesListingWithAlias;
  targetComparables: BarkerCoreModelsMarketplacesListing[] | null;
  tenants: BarkerCoreModelsAdministrativeTenantDetails[] | undefined;
  principal: BarkerCoreModelsAdministrativePrincipal | undefined;
  outliers: BarkerCoreModelsMarketplacesListing[];
  // eslint-disable-next-line no-unused-vars
  setSupportListing: (listing: BarkerCoreModelsMarketplacesListing) => void;
};

function RowItem({ virtualItem, listing, setSupportListing, showListingAge, principal, targetComparables, tenants, outliers }: RowItemProps) {
  const [filters, setFilters] = useAtom(seatingChartFiltersAtom);

  const isTarget = targetComparables?.some((c) => c.listingId === listing?.listingId);
  const ignore = filters.ignoredListingIds?.includes(listing.listingId);
  const tenantId = listing?.tenantId;
  const tenantName = tenantId ? tenants?.find((t) => t.tenantId === listing?.tenantId)?.name : undefined;
  const tenantColor = tenantId ? principal?.settings?.tenantColors[tenantId] ?? 'var(--colors-brandcolor-5)' : undefined;
  const isOutlier = outliers?.some((o) => o.listingId === listing.listingId);

  const ignoreListing = useCallback(
    (listingId: string) => {
      setFilters((prev) => ({
        ...prev,
        ignoredListingIds: prev.ignoredListingIds ? [...prev.ignoredListingIds, listingId] : [listingId],
      }));
    },
    [setFilters],
  );

  const unignoreListing = useCallback(
    (listingId: string) => {
      setFilters((prev) => ({
        ...prev,
        ignoredListingIds: prev.ignoredListingIds?.filter((id) => id !== listingId),
      }));
    },
    [setFilters],
  );
  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${virtualItem.size}px`,
        transform: `translateY(${virtualItem.start}px)`,
      }}
      className={classes.listingCardDeliveryWrapper}
    >
      <ListingCardDelivery
        key={listing.listingId}
        listingId={listing.listingId}
        firstSeenAt={listing.firstSeenAt}
        showListingAge={showListingAge}
        section={listing.sectionAlias || listing.section}
        row={listing.row}
        qty={listing.quantity}
        price={formatCurrency(listing.adjustedPrice.value)}
        own={listing.isOwnListing}
        target={isTarget}
        ignore={ignore}
        onIgnore={ignoreListing}
        onUnignore={unignoreListing}
        disclosures={listing.disclosures}
        openSupport={() => setSupportListing(listing)}
        tenantName={tenantName}
        tenantColor={tenantColor}
        outlier={isOutlier}
        deliveryMethod={listing.deliveryMethod}
      />
    </Box>
  );
}
