/* eslint-disable react-compiler/react-compiler */
import { Box, Group, Text } from '@mantine/core';
import React, { useRef } from 'react';
import SearchIcon from '../../components/icons/Search';
import BNOrderSelect from '../../components/Select/OrderSelect';
import { useSearchResults } from './SearchResults.hooks';
import pluralize from 'pluralize';
import { useGlobalState } from '../../data/GlobalState';
import { BNEmptyState } from '../../components/EmptyState/EmptyState';
import classes from './Search.styles.tsx.module.css';
import { EventCardColors } from './SearchResults.EventCardColors';
import { EventCardSkeleton } from './SearchResults.EventCardSkeleton';
import { useVirtualizer } from '@tanstack/react-virtual';
import { BarkerCoreModelsInventoryEvent } from '../../api';

export function SearchResults() {
  'use no memo';

  // Hack: ^^^ Required for the virtualizer to work properly. Otherwise items are memo'd and don't load after scrolling
  const {
    sortedData: data,
    isLoading,
    sort,
    sortOptions,
    setSort,
    selectedEvents,
    sortOrder,
    setSortOrder,
  } = useSearchResults('sortedData', 'isLoading', 'sort', 'sortOptions', 'setSort', 'selectedEvents', 'sortOrder', 'setSortOrder');
  const parentRef = useRef<HTMLDivElement>(null);
  const [isSortOptionsOpen, setIsSortOptionsOpen] = React.useState(false);
  const { principal, tenants } = useGlobalState('principal', 'tenants');

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 128,
    overscan: 10,
  });

  return (
    <>
      <Group className={`resultsArea ${classes.wrapper}`} justify="space-between" px={16} py={0}>
        <Group align="center" gap={2}>
          <Text className={classes.selectLabel} c="var(--colors-gray-5)" fz={11}>
            Sort:
          </Text>
          <BNOrderSelect
            data={sortOptions.map((x) => x)}
            dropdownWidth={140}
            selectedItem={sort}
            isOpen={isSortOptionsOpen}
            sortOrder={sortOrder}
            onClose={() => setIsSortOptionsOpen(false)}
            onClick={() => setIsSortOptionsOpen(!isSortOptionsOpen)}
            onSortOrderChange={(value: 'asc' | 'desc') => {
              setSortOrder(value);
              setIsSortOptionsOpen(false);
            }}
            onSelectionChange={(value) => {
              setSort(value as (typeof sortOptions)[number]);
              setIsSortOptionsOpen(false);
            }}
          />
        </Group>
        <Group>
          <Text fz={11} c="gray.5" tt="uppercase">
            {data ? data.length : 0} {pluralize('Event', data ? data.length : 0)}
          </Text>
        </Group>
      </Group>
      <div className={classes.searchResultsOuterWrap}>
        <Box className={classes.searchResultsInnerWrap} ref={parentRef} style={{ height: '100%', overflowY: 'auto' }}>
          {data && data.length > 0 ? (
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <Row
                  key={virtualItem.key}
                  event={data[virtualItem.index]}
                  size={virtualItem.size}
                  start={virtualItem.start}
                  isSelected={
                    data.length !== selectedEvents.length &&
                    selectedEvents.map((x) => x.tenantId + x.eventId).includes(data[virtualItem.index].tenantId + data[virtualItem.index].eventId)
                  }
                  tenantName={tenants?.find((t) => t.tenantId === data[virtualItem.index]?.tenantId)?.name || 'Unknown'}
                  tenantColor={principal?.settings?.tenantColors[data[virtualItem.index].tenantId] ?? 'var(--colors-brandcolor-5)'}
                  isMultiTenant={(tenants && tenants.length > 1) || false}
                />
              ))}
            </div>
          ) : isLoading ? (
            <SearchResults.EventCardSkeleton />
          ) : (
            <BNEmptyState
              h="90%"
              border={false}
              icon={<SearchIcon color="var(--colors-iconFill)" size={28} />}
              title="No Events Found"
              description="Try adjusting the search options"
            />
          )}
        </Box>
      </div>
    </>
  );
}

function Row({
  size,
  start,
  event,
  isSelected,
  tenantName,
  tenantColor,
  isMultiTenant,
}: {
  event: BarkerCoreModelsInventoryEvent;
  isSelected: boolean;
  size: number;
  start: number;
  tenantName: string;
  tenantColor: string;
  isMultiTenant: boolean;
}) {
  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
    >
      <EventCardColors event={event} isSelected={isSelected} tenantName={tenantName} tenantColor={tenantColor} isMultiTenant={isMultiTenant} />
    </Box>
  );
}

SearchResults.EventCardSkeleton = EventCardSkeleton;
