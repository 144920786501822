import {BarkerCoreEnumsPointOfSale, getApiInventoryStatus, getApiMarketplacesMarketplaceIdStatus} from '../../api';
import { useAtomValue } from 'jotai';
import { auth } from '../../data/atoms.auth';
import { Collapse, Group, Paper, Text } from '@mantine/core';
import WarningIcon from '../../components/icons/Warning';
import { useGlobalState } from '../../data/GlobalState';
import { useQueries } from '@tanstack/react-query';
import { supportEmail } from '../../utils/whitelabel-consts';

export function IntegrationProblemBanner() {
  const apiToken = useAtomValue(auth.apiTokenAtom);
  const { selectedTenantIds, tenants } = useGlobalState('selectedTenantIds', 'tenants');

  const pointOfSaleStatuses = useQueries({
    queries: selectedTenantIds.map((tenantId) => ({
      queryKey: ['inventoryStatus', tenantId],
      queryFn: () =>
        getApiInventoryStatus({
          headers: {
            'x-tenant-id': tenantId,
          },
        }),
      enabled: !!apiToken,
      refetchOnWindowFocus: true,
    })),
  });
  
  const marketplaceStatuses = useQueries({
    queries: selectedTenantIds.map((tenantId) => {
      const marketplaceIds = tenants?.find((tenant) => tenant.tenantId === tenantId)?.marketplaceIds ?? [];
      
      return marketplaceIds.map((marketplaceId) => ({
        queryKey: ['marketplaceStatus', tenantId, marketplaceId],
        queryFn: () =>
          getApiMarketplacesMarketplaceIdStatus(marketplaceId, {
            headers: {
              'x-tenant-id': tenantId,
            },
          }),
        enabled: !!apiToken,
        refetchOnWindowFocus: true,
      }));
    }
    ).flat(),
  });

  const tenantIdWithPosError = pointOfSaleStatuses.find((result) => result?.data?.data?.toUpperCase() === 'DOWN')?.data?.config?.headers.get('x-tenant-id');

  const tenantWithPosError = tenants?.find((tenant) => tenant.tenantId === tenantIdWithPosError);
  
  const tenantIdWithMarketplaceError = marketplaceStatuses.find((result) => result?.data?.data?.toUpperCase() === 'DOWN')?.data?.config?.headers.get('x-tenant-id');
  const marketplaceErrorUrl = marketplaceStatuses.find((result) => result?.data?.data?.toUpperCase() === 'DOWN' && result?.data?.config?.headers.get('x-tenant-id') === tenantIdWithMarketplaceError)?.data?.config?.url;
  
  const tenantWithMarketplaceError = tenants?.find((tenant) => tenant.tenantId === tenantIdWithMarketplaceError);
  const marketplaceWithError = marketplaceErrorUrl?.includes('SeatGeek') ? 'SeatGeek' : marketplaceErrorUrl?.includes('Ticketmaster') ? 'Ticketmaster' : marketplaceErrorUrl?.includes('TicketEvolution') ? 'Ticket Evolution' : 'Unknown';

  if (!tenantWithPosError && !tenantWithMarketplaceError) {
    return null;
  }

  // For DTI tenants, we want to show the proper support email members@dtimanagement.com, otherwise we want to forcibly list support@brokernerds.com because DTI doesn't handle support for non-DTI tenants
  const posSupportEmail = tenantWithPosError?.pointOfSaleId === BarkerCoreEnumsPointOfSale.DtiPortal ? supportEmail : 'support@brokernerds.com';

  let errorMessage;
  
  if (tenantWithPosError) {
    errorMessage =
      tenants && tenants.length > 1
        ? `We are experiencing issues communicating with the point of sale for ${tenantWithPosError.name}.`
        : 'We are experiencing issues communicating your point of sale.';
  }
  else if (tenantWithMarketplaceError)
  {
    errorMessage =
      tenants && tenants.length > 1
        ? `We are experiencing issues communicating with ${marketplaceWithError} for ${tenantWithMarketplaceError.name}.`
        : `We are experiencing issues communicating with ${marketplaceWithError} for your company`;
  }
  else {
    return null;
  }

  return (
    <Collapse in w="100%" ta="center">
      <Paper w="100%" bg="var(--colors-red-error)" c="rgba(255,255,255,1)">
        <Group mih={36} align="center" justify="center" gap="xs">
          <WarningIcon size={20} color="rgba(255,255,255,1)" />
          <Text size="xs" c="rgba(255,255,255,1)">
            {errorMessage} Please contact{' '}
            <Text component="a" href={`mailto:${posSupportEmail}`} td="underline">
              {posSupportEmail}
            </Text>{' '}
            for assistance.
          </Text>
        </Group>
      </Paper>
    </Collapse>
  );
}
